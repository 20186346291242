<template>
  <div class="main-content flex-1 relative h-screen bg-gray-100">
    <loading-overlay :visible="loading" :message="message" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 md:px-20"
      >
        <div class="flex justify-center items-center mt-6">
          <div
            class="
              inline-block
              bg-ssr-side_bar-button
              text-white
              px-8
              py-1
              rounded-full
              ml-4
              cursor-pointer
            "
            @click.prevent="store"
          >
            {{ $t("backup.now") }}
          </div>
        </div>
        <table class="mt-6">
          <thead
            class="
              bg-ssr-side_bar-settings
              text-white
              font-bold
              rounded-full
              border-b-2 border-black
            "
          >
            <th>{{ $t("backup.file_name") }}</th>
            <th>{{ $t("backup.action") }}</th>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b border-black"
              v-for="(file_name, index) in file_names"
              v-bind:key="index"
            >
              <td class="text-center">{{ file_name }}</td>
              <td class="text-center">
                <a
                  v-if="settings.features.backups"
                  :href="this.server_url + 'backup_files/' + file_name"
                  download=""
                  ><div
                    class="
                      inline-block
                      text-sm
                      bg-green-600
                      text-white
                      px-4
                      py-1
                      rounded-full
                      ml-4
                      cursor-pointer
                    "
                  >
                    {{ $t("backup.download") }}
                  </div></a
                >
                <div
                  @click.prevent="this.delete(file_name)"
                  class="
                    inline-block
                    text-sm
                    bg-red-600
                    text-white
                    px-4
                    py-1
                    rounded-full
                    ml-4
                    cursor-pointer
                  "
                >
                  {{ $t("backup.delete") }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <expired-window v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ExpiredWindow,
  },
  name: "Backup",
  computed: {
    ...mapGetters(["settings"]),
  },
  data() {
    return {
      dma: null,
      loading: false,
      message: "",
      sessions: false,
      file_names: [],
      server_url: localStorage.getItem("server"),
    };
  },
  async created() {
    const response = await axios.get("backups");
    this.file_names = response.data.files;
  },
  methods: {
    selectFile() {
      this.dma = this.$refs.dmaFile.files.item(0);
    },

    async store() {
      try {
        this.loading = true;
        this.message = this.$t("messages.backup.backuping");
        const response = await axios.post("backup");
        if (response.status != 200) {
          this.message = this.$t("errors.backup.backuping");
          return;
        }
        this.loading = false;
        const response2 = await axios.get("backups");
        this.file_names = response2.data.files;
        this.$router.push({ query: { success: "success" } });
        // this.$router.push({ path: "/dashboard", query: { status: "suceess" } });
      } catch (error) {
        this.message = this.$t("messages.restore.server");
      }
    },
    async delete(file_name) {
      const response = await axios.delete("backup/" + file_name);
      if (response.status == 200) {
        const response2 = await axios.get("backups");
        this.file_names = response2.data.files;
      }
    },
  },
};
</script>
