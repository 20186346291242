import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Accounts from "../views/Accounts.vue";
import Profiles from "../views/Profiles.vue";
import Cards from "../views/Cards.vue";
import AllCards from "../views/Cards.vue";
import PrintCards from "../views/PrintCards.vue";
import Batches from "../views/Batches.vue";
import Admins from "../views/Admins.vue";
import Resellers from "../views/Resellers.vue";
import Nases from "../views/Nases.vue";
import Settings from "../views/Settings.vue";
import AddAccount from "../views/AddAccount.vue";
import AddProfile from "../views/AddProfile.vue";
import AddCard from "../views/AddCard.vue";
import EditCard from "../views/EditCard.vue";
import AddAdmin from "../views/AddAdmin.vue";
import AddReseller from "../views/AddReseller.vue";
import AddNas from "../views/AddNas.vue";
import Dashboard from "../views/Dashboard.vue";
import EditAccount from "../views/EditAccount.vue";
import RenewAccount from "../views/RenewAccount.vue";
import Restore from "../views/Restore.vue";
import Sessions from "../views/Sessions.vue";
import OnlineSessions from "../views/OnlineSessions.vue";
import Mikrotik from "../views/Mikrotik.vue";
import SupportTickets from "../views/SupportTickets.vue";
import AddSupportTicket from "../views/AddSupportTicket.vue";
import Notifications from "../views/Notifications.vue";
import NotificationsConfig from "../views/NotificationsConfig.vue";
import NotificationsWa from "../views/NotificationsWa.vue";
import AdminsLog from "../views/AdminsLog.vue";
import Transactions from "../views/Transactions.vue";
import Bills from "../views/Bills.vue";
import UsageReport from "../views/UsageReport.vue";
import Search from "../views/Search.vue";
import Backup from "../views/Backup.vue";
import License from "../views/License.vue";
import IPTV from "../views/IPTV.vue";
import Bandwidth from "../views/Bandwidth.vue";
import Update from "../views/Update.vue";
import Reset from "../views/Reset.vue";
import Reports from "../views/Reports.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
  },
  {
    path: "/accounts/create",
    name: "AddAccount",
    component: AddAccount,
  },
  {
    path: "/accounts/:id/renew",
    name: "RenewAccount",
    component: RenewAccount,
  },
  {
    path: "/accounts/:id/edit",
    name: "EditAccount",
    component: AddAccount,
  },
  {
    path: "/profiles",
    name: "Profiles",
    component: Profiles,
  },
  {
    path: "/profiles/create",
    name: "AddProfile",
    component: AddProfile,
  },
  {
    path: "/profiles/:id/edit",
    name: "EditProfile",
    component: AddProfile,
  },
  {
    path: "/cards",
    name: "Cards",
    component: Batches,
  },
  {
    path: "/cards/cards",
    name: "AllCards",
    component: AllCards,
  },
  {
    path: "/cards/:id/cards",
    name: "BatchCards",
    component: Cards,
  },
  {
    path: "/cards/:id/print",
    name: "PrintCards",
    component: PrintCards,
  },
  {
    path: "/cards/create",
    name: "AddCard",
    component: AddCard,
  },
  {
    path: "/cards/edit/:id",
    name: "EditCard",
    component: EditCard,
  },
  {
    path: "/sessions/online",
    name: "OnlineSessions",
    component: OnlineSessions,
  },
  {
    path: "/sessions",
    name: "SessionsFull",
    component: Sessions,
  },
  {
    path: "/sessions/:username",
    name: "Sessions",
    component: Sessions,
  },
  {
    path: "/usage/:username",
    name: "UsageReport",
    component: UsageReport,
  },
  {
    path: "/admins",
    name: "Admins",
    component: Admins,
  },
  {
    path: "/admins/create",
    name: "AddAdmin",
    component: AddAdmin,
  },
  {
    path: "/resellers",
    name: "Resellers",
    component: Resellers,
  },
  {
    path: "/resellers/create",
    name: "AddReseller",
    component: AddReseller,
  },
  {
    path: "/admins/:id/edit",
    name: "EditAdmin",
    component: AddAdmin,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/services/mikrotik",
    name: "Mikrotik",
    component: Mikrotik,
  },
  {
    path: "/services/support",
    name: "SupportTickets",
    component: SupportTickets,
  },
  {
    path: "/services/support/create",
    name: "AddSupportTicket",
    component: AddSupportTicket,
  },
  {
    path: "/services/support/:id/edit",
    name: "EditSupportTicket",
    component: AddSupportTicket,
  },
  {
    path: "/services/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/services/notifications/config",
    name: "NotificationsConfig",
    component: NotificationsConfig,
  },
  {
    path: "/services/notifications/wa",
    name: "NotificationsWhatsapp",
    component: NotificationsWa,
  },
  {
    path: "/nases",
    name: "Nases",
    component: Nases,
  },
  {
    path: "/nases/create",
    name: "AddNas",
    component: AddNas,
  },
  {
    path: "/nases/:id/edit",
    name: "EditNas",
    component: AddNas,
  },
  {
    path: "/data/restore",
    name: "Restore",
    component: Restore,
  },
  {
    path: "/system/backup",
    name: "Backup",
    component: Backup,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/license",
    name: "License",
    component: License,
  },
  {
    path: "/admins/log",
    name: "AdminsLog",
    component: AdminsLog,
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/bills",
    name: "Bills",
    component: Bills,
  },
  {
    path: "/iptv",
    name: "IPTV",
    component: IPTV,
  },
  {
    path: "/bandwidth",
    name: "Bandwidth",
    component: Bandwidth,
  },
  {
    path: "/update",
    name: "Update",
    component: Update,
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
