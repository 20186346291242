<template>
  <div class="flex flex-col md:flex-row">
    <div class="flex flex-col flex-1 items-start pb-12 pl-12">
      <label class="font-bold mt-2">Connection Speed</label>
      <toggle-button
        :name="'M bit'"
        @click="setSpeedType(0)"
        :value="speedType.mega"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'G bit'"
        @click="setSpeedType(1)"
        :value="speedType.giga"
        class="w-full flex justify-start mt-2"
      />
      <numerical-spinner :value="0" class="mt-2" />
    </div>
    <div class="flex flex-col flex-1 items-start pb-12 pl-12">
      <label class="font-bold mt-2">Traffic</label>
      <toggle-button
        :name="'Unlimited'"
        @click="setTrafficType(0)"
        :value="trafficType.unlimited"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        :name="'Limited'"
        @click="setTrafficType(1)"
        :value="trafficType.limited"
        class="w-full flex justify-start mt-2"
      />
    </div>
    <div class="flex flex-col flex-1 items-start pb-12 pl-12">
      <label v-if="trafficType.limited" class="font-bold mt-2"
        >Traffic Limit</label
      >
      <toggle-button
        v-if="trafficType.limited"
        :name="'M bit'"
        @click="setLimitType(0)"
        :value="limitType.mega"
        class="w-full flex justify-start mt-2"
      />
      <toggle-button
        v-if="trafficType.limited"
        :name="'G bit'"
        @click="setLimitType(1)"
        :value="limitType.giga"
        class="w-full flex justify-start mt-2"
      />
      <numerical-spinner v-if="trafficType.limited" :value="0" class="mt-2" />
    </div>
  </div>
</template>

<script>
import InputField from "../formElements/InputField.vue";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: { InputField, NumericalSpinner, ToggleButton },
  name: "Page1",
  data() {
    return {
      speedType: {
        mega: false,
        giga: false,
      },
      limitType: {
        mega: false,
        giga: false,
      },
      trafficType: {
        unlimited: true,
        limited: false,
      },
    };
  },
  methods: {
    setSpeedType(val) {
      switch (val) {
        case 0:
          this.speedType.mega = true;
          this.speedType.giga = false;
          break;
        case 1:
          this.speedType.mega = false;
          this.speedType.giga = true;
          break;
        default:
          break;
      }
    },
    setLimitType(val) {
      switch (val) {
        case 0:
          this.limitType.mega = true;
          this.limitType.giga = false;
          break;
        case 1:
          this.limitType.mega = false;
          this.limitType.giga = true;
          break;
        default:
          break;
      }
    },
    setTrafficType(val) {
      switch (val) {
        case 0:
          this.trafficType.unlimited = true;
          this.trafficType.limited = false;
          break;
        case 1:
          this.trafficType.unlimited = false;
          this.trafficType.limited = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
