<template>
  <div class="main-content flex-1 relative">
    <loading-overlay :visible="loading" />
    <transition name="fade">
      <error-message
        :visible="isError"
        :message="error_message"
        @hide="isError = false"
      />
    </transition>
    <div class="flex justify-center w-full">
      <div
        class="
          bg-gray-300
          rounded-xl
          w-full
          m-6
          flex flex-col
          pb-12
          px-10
          md:px-20
          justify-center
          items-center
        "
      >
        <div class="flex flex-col lg:w-1/2 justify-center items-center">
          <div class="w-full items-start grid grid-cols-2 gap-6 mt-6">
            <div>
              <label class="font-bold">{{ $t("accounts.username") }}</label>
              <input
                v-model="card.username"
                @input="validate"
                disabled
                oninput="this.value=this.value.replace(/[^a-zA-Z0-9_.-\s]/g,'');"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  opacity-50
                "
                :class="{
                  'shadow-error': errors.username,
                }"
              />
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.username }"
              >
                *{{ errors.username }}
              </div>
            </div>
            <div>
              <label class="font-bold">{{ $t("accounts.password") }}</label>
              <input
                v-model="card.password"
                @input="validate"
                oninput="this.value=this.value.replace(/[^a-zA-Z0-9_.-\s]/g,'');"
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                "
                :class="{
                  'shadow-error': errors.password,
                }"
              />
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.password }"
              >
                *{{ errors.password }}
              </div>
            </div>
            <!-- <div class="col-span-2">
              <label class="font-bold">{{ $t("cards.profile") }}</label>
              <select
                v-model="account.profile_id"
                @change="
                  validate();
                  fix_date();
                  update_price();
                "
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
                :class="{ 'shadow-error': errors.profile_id }"
              >
                <option
                  v-for="profile in profiles"
                  v-bind:key="profile.id"
                  :value="profile.id"
                  >{{ profile.name }}</option
                >
              </select>
              <div
                class="text-gray-300 mt-0.5 text-xs font-light"
                :class="{ 'text-red-600': errors.profile_id }"
              >
                *{{ errors.profile_id }}
              </div>
            </div> -->
            <div class="col-span-2" v-if="this.user.data.type != 'reseller'">
              <label class="font-bold">{{
                $t("accounts.has_fixed_expiration")
              }}</label>
              <Datepicker
                class="w-full"
                v-model="card.fixed_expiration"
                :format="date_format"
                :startTime="startTime"
                autoApply
              ></Datepicker>
            </div>

            <div v-if="this.user.data.type != 'reseller'"></div>
            <div v-if="user.data.type != 'reseller'">
              <label class="font-bold">{{ $t("accounts.sessions") }}</label>
              <numerical-spinner
                :value="card.allowed_sessions"
                @increase="card.allowed_sessions = card.allowed_sessions + 1"
                @decrease="
                  card.allowed_sessions =
                    card.allowed_sessions > 1
                      ? card.allowed_sessions - 1
                      : (card.allowed_sessions = 1)
                "
              />
            </div>
            <div class="col-span-2 flex justify-center">
              <button
                :disabled="!validated"
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full py-1 px-3"
                :class="{
                  ' cursor-not-allowed opacity-50': !validated,
                }"
              >
                {{ this.card.id ? $t("accounts.save") : $t("accounts.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import Page1 from "../components/accountAdditionPages/Page1.vue";
import Page2 from "../components/accountAdditionPages/Page2.vue";
import Page3 from "../components/accountAdditionPages/Page3.vue";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import ErrorMessage from "../components/modals/ErrorMessage.vue";
import Datepicker from "vue3-date-time-picker";
import moment from "moment";
export default {
  components: {
    Page1,
    Page2,
    Page3,
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ErrorMessage,
    Datepicker,
  },
  name: "EditCard",
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      error: "",
      message: "",
      card: {
        username: "",
        password: "",
        profile_id: "",
        allowed_sessions: 1,
        fixed_expiration: null,
        expires_at: "",
      },
      startTime: {
        hours: 0,
        minutes: 0,
      },
      loading: false,
      profiles: null,
      isError: false,
      error_message: "",
      errors: {
        username: null,
        password: null,
        profile_id: null,
        ip_address: null,
      },
      validated: false,
      date_format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year} ${hour}:${minute}`;
      },
      price: 0,
    };
  },
  async created() {
    this.loading = true;
    if (this.$route.params.id) {
      await this.fetchCard(this.$route.params.id);
      this.validated = true;
    }
    this.loading = false;
  },
  methods: {
    async store() {
      this.card.fixed_expiration = moment(this.card.fixed_expiration).format(
        "yyyy-MM-DD HH:mm"
      );
      if (this.card.id) {
        const response = await axios.put(
          `/cards/edit/${this.card.id}`,
          this.card
        );
        if (response.status == 200) {
          this.$store.commit("changeKey");
          this.$router.push({ query: { success: "success" } });
          this.$router.go(-1);
        }
      }
    },
    async fetchProfiles() {
      const response = await axios.get(
        "/profiles?itr=1&type=subscribtion&rows=1000000"
      );
      if (response.status == 200) this.profiles = response.data.data;
    },
    update_price() {
      let profile = this.profiles.filter((profile) => {
        return profile.id == this.account.profile_id;
      });
      profile = profile[0];
      this.price = profile.price;
    },
    fix_date() {
      if (this.account.id) return;
      let profile = this.profiles.filter((profile) => {
        return profile.id == this.account.profile_id;
      });
      profile = profile[0];
      this.account.fixed_expiration = moment().add(
        profile.expiration_value,
        profile.expiration_unit
      );
      this.account.fixed_expiration.hours(0);
      this.account.fixed_expiration.minutes(0);
    },
    async fetchCard(id) {
      const response = await axios.get(`/cards/${id}/card`);
      if (response.status == 200) {
        this.card = response.data.data;
        this.card.fixed_expiration = moment(this.card.expires_at);
      }
    },
    changeProfile(profile_id) {
      this.account.profile_id = profile_id;
    },
    validate() {
      this.validated = true;
      this.errors.username = null;
      if (!this.card.username) {
        this.errors.username = this.$t("errors.accounts.username_empty");
        this.validated = false;
      }
      if (this.card.username.length < 3) {
        this.validated = false;
        this.errors.username = this.$t("errors.accounts.username_2");
      }
      if (this.card.username.includes("*")) {
        this.validated = false;
        this.errors.username = this.$t("errors.accounts.username_symbol");
      }
      this.errors.password = null;
      if (!this.card.password) {
        this.validated = false;
        this.errors.password = this.$t("errors.accounts.password_empty");
      }
      if (this.card.password.length < 3) {
        this.validated = false;
        this.errors.password = this.$t("errors.accounts.password_2");
      }
    },
  },
};
</script>
