<template>
  <div class="main-content flex-1 relative pb-24 md:pb-5">
    <div class="h-14">HighRadius</div>
    <div class="bg-gray-800 pt-3">
      <div
        class="flex flex-col md:flex-row justify-between rounded-tl-3xl bg-gradient-to-r from-red-900 to-gray-800 p-4 shadow text-2xl text-white"
      >
        <h3 class="font-bold pl-2">Add Account</h3>
      </div>
    </div>
    <div class="mt-12 flex justify-center w-full">
      <div class="bg-gray-300 rounded-xl w-5/6 flex flex-col pb-12">
        <!-- First 2 cols -->
        <div class="flex flex-col md:flex-row mt-6">
          <div class="flex flex-col flex-1 items-center p-12 md:p-0 md:pl-12">
            <div class="flex flex-col w-full md:w-1/2 mt-2">
              <label class="font-bold">First Name</label>
              <input
                v-model="account.first_name"
                type="text"
                class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex flex-col w-full md:w-1/2 mt-2">
              <label class="font-bold">Last Name</label>
              <input
                v-model="account.last_name"
                type="text"
                class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex flex-col w-full md:w-1/2  mt-2">
              <label class="font-bold">Email</label>
              <input
                v-model="account.email"
                type="text"
                class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex flex-col w-full md:w-1/2 mt-2">
              <label class="font-bold">Phone</label>
              <input
                v-model="account.phone"
                type="text"
                class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
          </div>
          <div
            class="flex flex-col flex-1 items-center md:items-start p-12 md:p-0"
          >
            <div class="flex flex-col w-full md:w-1/2 mt-2">
              <label class="font-bold">Username</label>
              <input
                v-model="account.username"
                type="text"
                class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex flex-col w-full md:w-1/2 mt-2">
              <label class="font-bold">Password</label>
              <input
                v-model="account.password"
                type="text"
                class="bg-white rounded-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
          </div>
        </div>

        <!-- Second 3 Cols -->
        <div class="flex flex-col md:flex-row mt-6">
          <div class="flex flex-col flex-1 items-start p-12 md:p-0 md:pl-12">
            <label class="font-bold mt-2">Account Type</label>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.accountType = 'prepaid'"
            >
              <img
                v-if="account.accountType == 'prepaid'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.accountType != 'prepaid'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Prepaid</label>
            </button>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.accountType = 'postpaid'"
            >
              <img
                v-if="account.accountType == 'postpaid'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.accountType != 'postpaid'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Postpaid</label>
            </button>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.accountType = 'monthly'"
            >
              <img
                v-if="account.accountType == 'monthly'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.accountType != 'monthly'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Monthly Subscription</label>
            </button>
          </div>
          <div
            class="flex flex-col flex-1 items-center md:items-start  p-12 md:p-0"
          >
            <label class="font-bold mt-2">Duration</label>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.durationType = 'weekly'"
            >
              <img
                v-if="account.durationType == 'weekly'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.durationType != 'weekly'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Weekly</label>
            </button>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.durationType = 'monthly'"
            >
              <img
                v-if="account.durationType == 'monthly'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.durationType != 'monthly'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Monthly</label>
            </button>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.durationType = 'hours'"
            >
              <img
                v-if="account.durationType == 'hours'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.durationType != 'hours'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Hours</label>
            </button>
          </div>
          <div
            class="flex flex-col flex-1 items-center md:items-start  p-12 md:p-0"
          >
            <div
              v-if="account.durationType == 'hours'"
              class="flex flex-col w-full md:w-1/2"
            >
              <label class="font-bold">Hours</label>
              <input
                v-model="account.hoursValue"
                type="text"
                class="bg-white rounded-full shadow-lg p-1  pl-3 focus:outline-none"
              />
            </div>
          </div>
        </div>

        <!-- THird 3 cols -->
        <div class="flex flex-col md:flex-row mt-6">
          <div class="flex flex-col flex-1 items-start pb-12 pl-12">
            <label class="font-bold mt-2">Connection Speed</label>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.speedType = 'M'"
            >
              <img
                v-if="account.speedType == 'M'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.speedType != 'M'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">M bit</label>
            </button>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.speedType = 'G'"
            >
              <img
                v-if="account.speedType == 'G'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.speedType != 'G'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">G bit</label>
            </button>
            <div class="bg-white rounded-full flex shadow-lg mt-2">
              <p class="mx-1 p-1">
                {{ (new Array(3).join("0") + account.speedValue).substr(-3) }}
              </p>
              <div
                class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
              >
                <button
                  @click.prevent="account.speedValue = account.speedValue + 1"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 15L12 9L18 15"
                      stroke="#84C2EA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <button
                  @click.prevent="
                    account.speedValue > 0
                      ? (account.speedValue = account.speedValue - 1)
                      : (account.speedValue = 0)
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 9L12 15L6 9"
                      stroke="#84C2EA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col flex-1 items-start pb-12 pl-12">
            <label class="font-bold mt-2">Traffic</label>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.trafficType = 'limited'"
            >
              <img
                v-if="account.trafficType == 'limited'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.trafficType != 'limited'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Limited</label>
            </button>
            <button
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.trafficType = 'unlimited'"
            >
              <img
                v-if="account.trafficType == 'unlimited'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.trafficType != 'unlimited'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">Unlimited</label>
            </button>
          </div>
          <div class="flex flex-col flex-1 items-start pb-12 pl-12">
            <label
              v-if="account.trafficType == 'limited'"
              class="font-bold mt-2"
              >Traffic Limit</label
            >
            <button
              v-if="account.trafficType == 'limited'"
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.limitType = 'M'"
            >
              <img
                v-if="account.limitType == 'M'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.limitType != 'M'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">M bit</label>
            </button>
            <button
              v-if="account.trafficType == 'limited'"
              class="flex w-full mt-2 justify-start items-center"
              @click.prevent="account.limitType = 'G'"
            >
              <img
                v-if="account.limitType == 'G'"
                class="inline-block"
                src="@/assets/toggle-on.png"
                alt="Toggle Button"
              />
              <img
                v-if="account.limitType != 'G'"
                class="inline-block"
                src="@/assets/toggle-off.png"
                alt="Toggle Button"
              />
              <label class="inline-block ml-1">G bit</label>
            </button>
            <div
              class="bg-white rounded-full flex shadow-lg mt-2"
              v-if="account.trafficType == 'limited'"
            >
              <p class="mx-1 p-1">
                {{ (new Array(3).join("0") + account.limitValue).substr(-3) }}
              </p>
              <div
                class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
              >
                <button
                  @click.prevent="account.limitValue = account.limitValue + 1"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 15L12 9L18 15"
                      stroke="#84C2EA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <button
                  @click.prevent="
                    account.limitValue > 0
                      ? (account.limitValue = account.limitValue - 1)
                      : (account.limitValue = 0)
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 9L12 15L6 9"
                      stroke="#84C2EA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center mt-6">
          <button
            @click.prevent="store"
            class="w-5/6 md:w-1/5 bg-green-500 text-white rounded-lg hover:bg-white hover:text-green-500 py-1 px-3"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import Page1 from "../components/accountAdditionPages/Page1.vue";
import Page2 from "../components/accountAdditionPages/Page2.vue";
import Page3 from "../components/accountAdditionPages/Page3.vue";
export default {
  components: { Page1, Page2, Page3 },
  name: "AddAccount",
  props: ["selectedAccount"],
  data() {
    return {
      error: "",
      message: "",
      account: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        accountType: "",
        durationType: "",
        hoursValue: 0,
        speedType: "",
        speedValue: 0,
        trafficType: "",
        limitType: "",
        limitValue: 0,
      },
    };
  },
  async created() {
    await this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      const response = await axios.get(
        `accounts/${this.$route.params.username}`
      );
      this.account = response.data.data;
    },
    async store() {
      const response = await axios.put(`accounts/${this.account.username}`, {
        first_name: this.account.first_name,
        last_name: this.account.last_name,
        username: this.account.username,
        email: this.account.email,
        phone: this.account.phone,
        password: this.account.password,
        account_type: this.account.accountType,
        duration_type: this.account.durationType,
        hours_value: this.account.hoursValue,
        speed_type: this.account.speedType,
        speed_value: this.account.speedValue,
        traffic_type: this.account.trafficType,
        limit_type: this.account.limitType,
        limit_value: this.account.limitValue,
      });
      if (response.status == 200) {
        this.$router.push({ path: "/accounts", query: { status: "suceess" } });
      }
    },
  },
};
</script>
