<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-if="!loading && expiring_soon"
        class="inline-block bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 align-middle max-w-5xl w-full"
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("profiles.renew.title") }}
            </div>

            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="flex flex-col w-full md:w-1/3 items-center justify-center bg-gray-300"
          >
            <label class="font-bold mt-6">{{ $t("accounts.username") }}</label>
            <input
              v-model="selectedAccount.username"
              type="text"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              :disabled="selectedAccount.id"
              :class="{
                'opacity-50 cursor-not-allowed': selectedAccount.id,
              }"
            />
            <label class="font-bold mt-6">{{ $t("profiles.profile") }}</label>
            <select
              v-model="selectedAccount.profile.id"
              @change="
                updateExpiration();
                updateProfile();
                validate();
              "
              :disabled="!hasPrivilege('accounts_edit_profile_accounts')"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
            >
              <option
                v-for="profile in profiles"
                v-bind:key="profile.id"
                :value="profile.id"
              >
                {{ profile.name }}
              </option>
            </select>
            <div
              class="flex w-full mt-6"
              v-if="this.user.data.type != 'reseller'"
            >
              <label class="font-bold flex-1 text-center">{{
                $t("profiles.next_profile")
              }}</label>
              <select
                v-model="selectedAccount.next_profile_id"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none flex-1"
                :disabled="!hasPrivilege('accounts_edit_profile_accounts')"
              >
                <option value="">N/A</option>
                <option
                  v-for="profile in profiles"
                  v-bind:key="profile.id"
                  :value="profile.id"
                >
                  {{ profile.name }}
                </option>
              </select>
            </div>
            <label
              class="font-bold mt-3"
              v-if="
                hasPrivilege('profiles_save_quota') &&
                  temp_profile.traffic_limited
              "
              >{{ $t("control.remaining_quota") }}</label
            >
            <toggle-button
              v-if="
                hasPrivilege('profiles_save_quota') &&
                  temp_profile.traffic_limited
              "
              :value="previous_quota"
              :enabled_name="$t('control.save')"
              :disabled_name="$t('control.clear')"
              @toggle="previous_quota = !previous_quota"
            />
            <label class="font-bold mt-6">{{
              $t("profiles.renew.amount")
            }}</label>
            <div class="bg-white rounded-full w-full inline-flex shadow-lg">
              <div
                class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
              >
                <button
                  @click.prevent="
                    renew_amount = renew_amount + 1;
                    updateExpiration();
                    validate();
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 15L12 9L18 15"
                      stroke="#84C2EA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>

                <button
                  @click.prevent="
                    renew_amount =
                      renew_amount > 1 ? renew_amount - 1 : (renew_amount = 1);
                    updateExpiration();
                    validate();
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 9L12 15L6 9"
                      stroke="#84C2EA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <input
                type="text"
                v-model="renew_amount"
                class="rounded-r-full text-center p-1 inline-block w-full"
                @input="
                  validate();
                  updateExpiration();
                "
              />
            </div>
            <label
              class="font-bold mt-6"
              v-if="hasPrivilege('accounting_pay_bill')"
              >{{ $t("profiles.renew.money_state") }}</label
            >
            <toggle-button
              v-if="hasPrivilege('accounting_pay_bill')"
              :value="in_debt"
              :enabled_name="$t('profiles.renew.paid')"
              :disabled_name="$t('profiles.renew.in_debt')"
              @toggle="in_debt = !in_debt"
              :class="{ 'ring-2 ring-yellow-300': !in_debt && confirmation }"
            />
            <div class="flex justify-around w-full mt-2">
              <p class="flex-1 text-center">{{ $t("profiles.price") }}</p>
              <input
                v-if="hasPrivilege('accounting_change_price')"
                @input="updateExpiration"
                v-model="profile_price"
                name="price"
                id="price"
                type="number"
                class="rounded-full text-center flex-1"
              />
              <div
                v-else
                class="rounded-full text-center bg-white flex-1 opacity-50"
              >
                {{ profile_price }}
              </div>
            </div>
            <div
              v-if="hasPrivilege('accounting_pay_bill')"
              class="flex justify-around w-full mt-2"
            >
              <label for="discount" class="flex-1 text-center">{{
                $t("transactions.discount") + ":"
              }}</label>
              <input
                v-model="discount"
                name="discount"
                id="discount"
                type="number"
                class="rounded-full text-center flex-1"
              />
            </div>
            <div
              v-if="in_debt && hasPrivilege('accounting_pay_bill')"
              class="flex justify-around w-full mt-2"
            >
              <label for="paid" class="flex-1 text-center">{{
                $t("transactions.paid_amount") + ":"
              }}</label>
              <input
                v-model="paid"
                name="paid"
                id="paid"
                type="number"
                class="rounded-full text-center flex-1"
              />
            </div>

            <VueDatepicker
              v-if="this.user.data.type != 'reseller'"
              class="mt-6 w-full"
              v-model="date"
              :format="format"
              :enableTimePicker="false"
              autoApply
              :disabled="
                this.user.data.type == 'reseller' ||
                  !hasPrivilege('accounts_edit_expiration_accounts')
              "
              :locale="$i18n.locale"
              :day-names="['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI']"
              :teleport="true"
            ></VueDatepicker>
            <div class="mt-6 w-full bg-white rounded-sm py-1 text-center">
              {{
                temp_profile.expiration_value * renew_amount +
                  " " +
                  $t(`profiles.unit.${temp_profile.expiration_unit}`)
              }}
            </div>
            <VueDatepicker
              class="mt-6 w-full"
              v-model="time"
              :startTime="startTime"
              :timePicker="true"
              autoApply
              :disabled="this.user.data.type == 'reseller'"
              :locale="$i18n.locale"
              :teleport="true"
            ></VueDatepicker>
            <div
              v-if="this.user.data.type == 'reseller'"
              class="mt-6 w-full font-bold text-md"
            >
              <div>
                {{
                  $t("control.cost", {
                    cost:
                      temp_profile.price * renew_amount +
                      " " +
                      settings.currency,
                  })
                }}
              </div>
              <div
                :class="{
                  'text-red-600':
                    user.data.money - temp_profile.price * renew_amount < 0,
                }"
              >
                {{
                  $t("control.money_left", {
                    original: user.data.money,
                    left:
                      user.data.money -
                      temp_profile.price * renew_amount +
                      " " +
                      settings.currency,
                  })
                }}
              </div>
            </div>
            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              class="bg-green-500 text-white rounded-full w-full py-1 px-3 my-6"
              :class="{ 'bg-opacity-50 cursor-default': !validated }"
              :disabled="!validated"
            >
              {{ $t("profiles.renew.renew") }}
            </button>
          </div>
          <div
            v-if="confirmation"
            class="grid grid-cols-2 my-2 gap-x-2 w-full md:w-1/3 items-center justify-center bg-gray-300 text-white"
          >
            <div class="col-span-2 flex justify-center text-black text-center">
              {{ $t("profiles.renew.confirm") }}
            </div>
            <div
              v-if="!in_debt && hasPrivilege('accounting_pay_bill')"
              class="col-span-2 flex justify-center text-black text-center text-xl"
            >
              {{ $t("profiles.renew.debt_confirm") }}
            </div>
            <div
              v-if="
                in_debt &&
                  profile.price * renew_amount > paid &&
                  hasPrivilege('accounting_pay_bill')
              "
              class="col-span-2 flex justify-center text-black text-center"
            >
              {{ $t("profiles.renew.paid_debt_confirm") }}
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-else-if="!expiring_soon && !loading"
        class="inline-block bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 align-middle max-w-5xl w-full"
      >
        <div class="flex flex-col items-center justify-center gap-4 my-8">
          <div class="text-3xl font-bold text-red-600">
            {{ $t("messages.accounts.warning") }}
          </div>
          <div class="text-2xl">
            {{ $t("messages.accounts.not_expiring_soon") }}
          </div>
          <button
            @click="expiring_soon = true"
            class="text-3xl font-bold rounded-full px-6 py-1 bg-green-500 flex"
          >
            {{ $t("control.ok") }}
          </button>
        </div>
      </div>
      <div
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle"
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
// import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: {
    NumericalSpinner,
    ExpiredWindow,
    ToggleButton,
    VueDatepicker,
  },
  name: "RenewProfile",
  props: ["visible", "selectedAccount"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      renew_amount: 1,
      profiles: null,
      date: {
        day: 0,
        month: 0,
        year: 0,
        hour: 0,
        minute: 0,
      },
      time: {
        hours: 0,
        minutes: 0,
      },
      startTime: {
        hours: 0,
        minutes: 0,
      },
      in_debt: true,
      paid: 0,
      discount: 0,
      profile_price: 0,
      previous_quota: false,
      confirmation: false,
      loading: true,
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year}`;
      },
      validated: true,
      profile: null,
      expiring_soon: true,
    };
  },
  async created() {
    await this.fetchProfiles();
    // console.log(VueDatepicker);
    this.loading = false;
    this.profile = this.selectedAccount.profile;
    this.updateExpiration();
    if (this.profile.expiration_unit != "hours")
      this.time.hours = this.settings.default_expiration_hour ?? 12;
    this.temp_profile = this.selectedAccount.profile;
    this.validate();
    this.renew_amount = 1;
    var days_to_expire = moment
      .duration(moment(this.selectedAccount.expires_at).diff(moment()))
      .asDays();
    if (days_to_expire > 10) {
      this.expiring_soon = false;
    }
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    updateExpiration() {
      var profile = this.profiles.find(
        (e) => e.id == this.selectedAccount.profile.id
      );
      if (moment(this.selectedAccount.expires_at) > moment()) {
        this.date = moment(this.selectedAccount.expires_at).add(
          profile.expiration_value * this.renew_amount,
          profile.expiration_unit
        );
        if (this.profile.expiration_unit != "hours") {
          this.date.hours(this.settings.default_expiration_hour ?? 12);
        } else {
          // this.date.hours(
          //   this.date.hour + profile.expiration_value * this.renew_amount
          // );
        }
        this.date.minutes(0);
      } else {
        this.date = moment().add(
          profile.expiration_value * this.renew_amount,
          profile.expiration_unit
        );
        if (this.profile.expiration_unit != "hours") {
          this.date.hours(this.settings.default_expiration_hour ?? 12);
        } else {
          // this.date.hours(
          //   this.date.hour + profile.expiration_value * this.renew_amount
          // );
        }
        this.date.minutes(0);
      }
      this.paid = this.hasPrivilege("accounting_change_price")
        ? profile.price
        : profile.price * this.renew_amount;
      this.profile = profile;
      this.profile_price = this.profile.price * this.renew_amount;
    },
    updateProfile() {
      this.temp_profile = this.profiles.find(
        (e) => e.id == this.selectedAccount.profile.id
      );
    },
    async store() {
      if (this.loading) return;
      this.loading = true;
      this.date = moment(this.date);
      if (this.profile.expiration_unit != "hours") {
        this.date.hours(this.time.hours);
        this.date.minutes(this.time.minutes);
      }
      // console.log(moment(this.date).format("yyyy-MM-DD HH:mm"));
      // return;
      const response = await axios.post(
        `/accounts/${this.selectedAccount.id}/renew`,
        {
          profile_id: this.selectedAccount.profile.id,
          expires_at: moment(this.date).format("yyyy-MM-DD HH:mm"),
          profile_price: this.profile_price,
          renew_amount: this.renew_amount,
          in_debt: !this.in_debt,
          paid_amount: this.paid,
          discount: this.discount,
          previous_quota: this.previous_quota,
          next_profile_id: this.selectedAccount.next_profile_id,
        }
      );
      if (response.status == 200) {
        const userResponse = await axios.get("user");
        this.$store.dispatch("user", userResponse.data);
        this.$router.push({
          path: "/accounts",
          query: { success: "success" },
        });
        this.$emit("updated");
        this.loading = false;
      }
      this.loading = false;
    },
    async fetchProfiles() {
      const response = await axios.get(
        "/profiles?type=subscribtion&rows=1000000&itr=true"
      );
      if (response.status == 200) this.profiles = response.data.data;
    },
    validate() {
      this.validated = true;
      if (
        this.user.data.money - this.temp_profile.price * this.renew_amount <
          0 &&
        this.user.data.type == "reseller"
      ) {
        this.validated = false;
      }
    },
  },
};
</script>
