<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <!-- <profile-details
      :visible="showDetails"
      :selectedProfile="selectedData"
      @hide-details="showDetails = false"
    /> -->
    <loading-overlay :loading="loading_overlay" />
    <delete-confirm
      :visible="showDelete"
      @hide="showDelete = false"
      @deleteconfirmed="deleteData"
    />
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.nases.delete_all')"
        :visible="showDeleteAll"
        @confirmed="groupDelete()"
        @hide="showDeleteAll = false"
      />
    </transition>
    <columns-select
      :visible="showColumnsSelect"
      :columns="columns"
      :name="'nases_columns'"
      @hide="showColumnsSelect = false"
      @selectconfirmed="updateColumns(currentColumns)"
    />
    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul
          :class="{
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <li
            @click="editData(selectedData)"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-blue-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
            {{ $t("actions.edit") }}
          </li>
          <li
            @click="exportSQL(selectedData.id)"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block rounded-lg bg-yellow-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            {{ $t("actions.export_nas") }}
          </li>
          <li
            @click="showDelete = true"
            class="m-2 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            {{ $t("actions.delete") }}
          </li>
        </ul>
      </tables-context>
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="
              rounded-full
              bg-ssr-side_bar-settings
              text-xs
              lg:text-base
              text-white
              py-1
              lg:py-2
              px-4
              font-bold
              mr-4
            "
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            @click.prevent="this.$router.push('/nases/create')"
            class="
              rounded-full
              bg-ssr-side_bar-button_hover
              text-xs
              lg:text-base
              text-white
              lg:p-2
              px-2
            "
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("nases.add_device") }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.name.enabled">
              <div>{{ $t("nases.name") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.name"
              />
            </th>
            <th v-if="columns.ip.enabled">
              <div>{{ $t("nases.ip") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.ip"
              />
            </th>
            <th v-if="columns.secret.enabled">
              <div>{{ $t("nases.secret") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.secret"
              />
            </th>
            <th v-if="columns.username.enabled">
              <div>{{ $t("nases.username") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.username"
              />
            </th>
            <th v-if="columns.password.enabled">
              <div>{{ $t("nases.password") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.password"
              />
            </th>
            <th v-if="columns.api_port.enabled">
              <div>{{ $t("nases.api_port") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.api_port"
              />
            </th>
            <th v-if="columns.community.enabled">
              <div>Community</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.community"
              />
            </th>
            <th v-if="columns.legacy.enabled">
              <div>{{ $t("nases.version") }}</div>
            </th>
            <th v-if="columns.allowed_admins.enabled">
              <div>{{ $t("nases.allowed_admins") }}</div>
            </th>
            <th v-if="columns.allowed_profiles.enabled">
              <div>{{ $t("nases.allowed_profiles") }}</div>
            </th>
            <th v-if="columns.online_sessions.enabled">
              <div>{{ $t("nases.online_sessions") }}</div>
            </th>

            <th v-if="columns.online.enabled">
              <div>{{ $t("nases.status") }}</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.online"
              />
            </th>
            <th v-if="columns.api_connected.enabled">
              <div>Api</div>
              <input
                type="text"
                class="
                  bg-white
                  rounded-full
                  w-full
                  shadow-lg
                  p-1
                  m-1
                  focus:outline-none
                  text-center
                "
                :placeholder="$t('control.filter')"
                v-model="filters.api_connected"
              />
            </th>
            <th v-if="columns.settings.enabled">
              {{ $t("nases.settings") }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(nas, index) in dataList"
              v-bind:key="nas.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': nas.selected,
              }"
              @contextmenu.prevent="openContextMenu($event, nas)"
            >
              <td class="font-bold">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="nas.selected"
                  @click="selectData(nas)"
                />
              </td>
              <td
                v-if="columns.name.enabled"
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
              >
                <a
                  class="cursor-pointer underline text-blue-800"
                  @click.prevent="
                    selectedData = nas;
                    editData(selectedData);
                  "
                >
                  {{ nas.name }}
                </a>
              </td>

              <td v-if="columns.ip.enabled">{{ nas.ip }}</td>
              <td v-if="columns.secret.enabled">
                {{ nas.radius_secret }}
              </td>
              <td v-if="columns.username.enabled">
                {{ nas.api_username }}
              </td>
              <td v-if="columns.password.enabled">
                {{ nas.api_password }}
              </td>
              <td v-if="columns.api_port.enabled">
                {{ nas.api_port }}
              </td>
              <td v-if="columns.community.enabled">
                {{ nas.snmp_community }}
              </td>
              <td v-if="columns.legacy.enabled">
                <div
                  class="
                    px-3
                    p-1
                    bg-blue-400
                    text-white text-center text-xs
                    rounded-full
                    inline-block
                  "
                >
                  {{ !nas.legacy ? "v3.36 or up" : "v3.34 or down" }}
                </div>
              </td>
              <td v-if="columns.allowed_admins.enabled">
                <div v-if="hasPrivilege('admins_assign_nases')">
                  <div v-for="admin in nas.allowed_users" v-bind:key="admin.id">
                    <span
                      class="text-white px-2 text-sm bg-green-400 rounded-lg"
                      >{{ admin.email }}</span
                    >
                  </div>
                </div>
              </td>
              <td v-if="columns.allowed_profiles.enabled">
                <div
                  v-if="hasPrivilege('profiles_view_profiles_nases')"
                  class="h-20 overflow-y-auto"
                >
                  <div
                    v-for="profile in nas.allowed_profiles"
                    v-bind:key="profile.id"
                  >
                    <span
                      class="text-white px-2 text-sm bg-green-400 rounded-lg"
                      >{{ profile.name }}</span
                    >
                  </div>
                </div>
              </td>
              <td v-if="columns.online_sessions.enabled">
                <div>
                  <span
                    class="text-white px-2 text-sm bg-green-400 rounded-lg"
                    >{{ nas.online_sessions }}</span
                  >
                </div>
              </td>
              <td v-if="columns.online.enabled">
                <div
                  v-if="nas.online == 'loading'"
                  class="
                    flex justify-center
                  "
                >
                  <img
                    class="inline-block h-12 w-12"
                    src="@/assets/loading.gif"
                    alt="Loading"
                  />
                </div>
                <div
                  v-if="nas.online == 'up'"
                  class="
                    inline-block
                    p-1
                    bg-green-600
                    text-white text-center text-xs
                    rounded-full
                    px-3
                    font-bold
                  "
                >
                  {{ $t("control.online") }}
                </div>
                <div
                  v-if="nas.online == 'down'"
                  class="
                    inline-block
                    p-1
                    bg-gray-800
                    text-white text-center text-xs
                    rounded-full
                    px-3
                    font-bold
                  "
                >
                  {{ $t("control.offline") }}
                </div>
              </td>
              <td v-if="columns.api_connected.enabled">
                <div
                  v-if="nas.api_connected == 'loading'"
                  class="
                    flex justify-center
                  "
                >
                  <img
                    class="inline-block h-12 w-12"
                    src="@/assets/loading.gif"
                    alt="Loading"
                  />
                </div>
                <div
                  v-if="nas.api_connected == 'up'"
                  class="
                    inline-block
                    p-1
                    bg-green-600
                    text-white text-center text-xs
                    rounded-full
                    px-3
                    font-bold
                  "
                >
                  {{ $t("control.online") }}
                </div>
                <div
                  v-if="nas.api_connected == 'down'"
                  class="
                    inline-block
                    p-1
                    bg-gray-800
                    text-white text-center text-xs
                    rounded-full
                    px-3
                    font-bold
                  "
                >
                  {{ $t("control.offline") }}
                </div>
              </td>
              <td v-if="columns.settings.enabled">
                <div
                  v-if="nas.api_connected == 'up' && !nas.commited"
                  class="
                    inline-block
                    p-1
                    bg-blue-600
                    text-white text-center text-xs
                    rounded-full
                    px-3
                    font-bold
                    cursor-pointer
                  "
                  @click="commitSettings(nas)"
                >
                  {{ $t("control.commit") }}
                </div>
                <div
                  v-if="nas.commited"
                  class="
                    inline-block
                    p-1
                    text-green-600 text-center text-xs
                    px-3
                    font-bold
                  "
                >
                  {{ $t("control.commited") }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class="inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start gap-2"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div @click="editData(selection_array[0])" class="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </div>
        <div @click="exportSQL(selectedData.id)" class="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-yellow-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
        </div>
        <div
          class="cursor-pointer"
          @click="
            selectedData = selection_array[0];
            showDelete = true;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div class="ml-2 cursor-pointer" @click="showDeleteAll = true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import { hasPrivilege } from "../global_constants.js";
import { mapGetters } from "vuex";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    TableCheckBox,
    LoadingOverlay,
    WarnningConfirm,
  },
  name: "Cards",
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showDetails: false,
      showDelete: false,
      showDeleteAll: false,
      showContext: false,
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        name: "",
        ip: "",
        secret: "",
        username: "",
        password: "",
        api_port: "",
        community: "",
        legacy: "",
        online: "",
        api_connected: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        name: { name: this.$t("nases.name"), enabled: true },
        ip: { name: this.$t("nases.ip"), enabled: true },
        secret: { name: this.$t("nases.secret"), enabled: false },
        username: { name: this.$t("nases.username"), enabled: false },
        password: { name: this.$t("nases.password"), enabled: false },
        api_port: { name: this.$t("nases.api_port"), enabled: false },
        community: { name: this.$t("nases.community"), enabled: false },
        legacy: { name: this.$t("nases.version"), enabled: false },
        allowed_admins: {
          name: this.$t("nases.allowed_admins"),
          enabled: true,
        },
        allowed_profiles: {
          name: this.$t("nases.allowed_profiles"),
          enabled: false,
        },
        online_sessions: {
          name: this.$t("nases.online_sessions"),
          enabled: true,
        },
        online: { name: this.$t("nases.status"), enabled: true },
        api_connected: { name: this.$t("nases.api"), enabled: true },
        settings: { name: this.$t("nases.settings"), enabled: true },
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      loading_overlay: false,
    };
  },
  async created() {
    this.prepareFilters();
    this.applyFilters();
    this.prepareColumns();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      this.applyFilters();
    },
    prepareFilters() {
      var filters = localStorage.getItem("nases_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("nases_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          name: { name: this.$t("nases.name"), enabled: cols[0].enabled },
          ip: { name: this.$t("nases.ip"), enabled: cols[1].enabled },
          secret: { name: this.$t("nases.secret"), enabled: cols[2].enabled },
          username: {
            name: this.$t("nases.username"),
            enabled: cols[3].enabled,
          },
          password: {
            name: this.$t("nases.password"),
            enabled: cols[4].enabled,
          },
          api_port: {
            name: this.$t("nases.api_port"),
            enabled: cols[5].enabled,
          },
          community: {
            name: this.$t("nases.community"),
            enabled: cols[6].enabled,
          },
          legacy: { name: this.$t("nases.version"), enabled: cols[7].enabled },
          allowed_admins: {
            name: this.$t("nases.allowed_admins"),
            enabled: cols[8].enabled,
          },
          allowed_profiles: {
            name: this.$t("nases.allowed_profiles"),
            enabled: cols[9].enabled,
          },
          online_sessions: {
            name: this.$t("nases.online_sessions"),
            enabled: cols[10] != null ? cols[10].enabled : true,
          },
          online: { name: this.$t("nases.status"), enabled: cols[11].enabled },
          api_connected: {
            name: this.$t("nases.api"),
            enabled: cols[12] != null ? cols[12].enabled : true,
          },
          settings: {
            name: this.$t("nases.settings"),
            enabled: cols[13] != null ? cols[10].enabled : true,
          },
        };
      }
    },
    async applyFilters() {
      this.loading = true;
      var requestString = `nases?filters=1`;
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.name != "")
        requestString += `&name=${this.filters.name}`;
      if (this.filters.ip != "") requestString += `&ip=${this.filters.ip}`;
      if (this.filters.secret != "")
        requestString += `&secret=${this.filters.secret}`;
      if (this.filters.username != "")
        requestString += `&username=${this.filters.username}`;
      if (this.filters.password != "")
        requestString += `&password=${this.filters.password}`;
      if (this.filters.api_port != "")
        requestString += `&api_port=${this.filters.api_port}`;
      if (this.filters.community != "")
        requestString += `&community=${this.filters.community}`;
      if (this.filters.legacy != "")
        requestString += `&legacy=${this.filters.legacy}`;
      if (this.filters.online != "")
        requestString += `&online=${this.filters.online}`;
      if (this.filters.api_connected != "")
        requestString += `&api_connected=${this.filters.api_connected}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem("nases_filters", JSON.stringify(this.filters));
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        this.meta = response.data.meta;
        this.loading = false;
        this.dataList.forEach(async (nas) => {
          await this.pingHost(nas);
          await this.apiHost(nas);
        });
      }, 1000);

      // if (this.sort_col) {
      //   if (this.sort_dir == "ASC") {
      //     this.dataList.sort((a, b) =>
      //       a[this.sort_col] > b[this.sort_col]
      //         ? 1
      //         : b[this.sort_col] > a[this.sort_col]
      //         ? -1
      //         : 0
      //     );
      //   } else {
      //     this.dataList.sort((a, b) =>
      //       a[this.sort_col] < b[this.sort_col]
      //         ? 1
      //         : b[this.sort_col] < a[this.sort_col]
      //         ? -1
      //         : 0
      //     );
      //   }
      // }
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.loading = true;
      var requestString = `nases?rows=${rows}&page=${currentPage}`;
      const response = await axios.get(requestString);
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    editData(selectedData) {
      this.$router.push(`/nases/${selectedData.id}/edit`);
    },
    exportSQL(id) {
      window.location.href =
        localStorage.getItem("server_api") + `nases/${id}/export`;
    },
    async deleteData() {
      const response = await axios.delete(`nases/${this.selectedData.id}`);
      if (response.status == 200) {
        this.fetchData();
        this.showDelete = false;
        this.$router.push({ query: { success: "success" } });
      }
    },
    async groupDelete() {
      this.showDeleteAll = false;
      this.loading_overlay = true;
      const response = await axios.post(`nases/group`, {
        nases: this.selection_array,
      });
      if (response.status == 200) {
        this.selection_array = [];
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    openContextMenu(e, data) {
      this.selectedData = data;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    async pingHost(nas) {
      try {
        const response = await axios.get(`nases/${nas.id}/ping`);
        if (response.status == 200) {
          nas.online = response.data.status;
          return response.data.status == "up";
        }
      } catch (error) {
        return false;
      }
    },
    async apiHost(nas) {
      try {
        const response = await axios.get(`nases/${nas.id}/api`);
        if (response.status == 200) {
          nas.api_connected = response.data.status;
          return response.data.status == "up";
        }
      } catch (error) {
        return false;
      }
    },
    async commitSettings(nas) {
      this.loading = true;
      try {
        const response = await axios.post(`nases/${nas.id}/commit`);
        if (response.status == 200) {
          nas.commited = true;
          this.$router.push({ query: { success: "success" } });
        }
      } catch (error) {}
      this.loading = false;
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("nases_filters", JSON.stringify(this.filters));
    },
  },
};
</script>
