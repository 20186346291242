<template>
  <div class="main-content flex-1 relative h-screen bg-gray-100">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        v-if="hasPrivilege('system_ownership')"
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 md:px-20"
      >
        <div class="text-3xl mt-4 mb-2 text-center">
          {{ $t("bandwidth.title") }}
        </div>
        <div
          class="text-2xl text-center flex flex-col gap-2 justify-center items-center"
        >
          <div>{{ $t("bandwidth.total_peak") }}:</div>
          <div class="text-3xl">
            {{ formatSpeed(total_peak) }}
          </div>
        </div>
        <div
          class="text-2xl text-center flex flex-col gap-2 justify-center items-center"
        >
          <div>{{ $t("bandwidth.total_average") }}:</div>
          <div class="text-3xl">
            {{ formatSpeed(total_peak / 2) }}
          </div>
        </div>
        <div
          class="bg-indigo-400 rounded-2xl p-2 my-2 border-white border shadow-lg"
        >
          <div class="text-2xl mt-4 mb-2 text-center">
            {{ $t("bandwidth.total_use") }}
          </div>

          <div class="flex items-center">
            <p>10%</p>
            <input
              type="range"
              min="0"
              max="200"
              class="appearance-none rounded-full h-4 bg-gray-100 w-full"
              v-model="total_use_percent"
              @input="
                updateAll();
                validate();
              "
            />
            <p>200%</p>
          </div>
          <div class="text-center font-bold text-xl">
            {{ total_use_percent }}%
          </div>
          <div class="flex gap-2 justify-center">
            <div
              @click.prevent="
                total_use_percent = 100;
                updateAll();
              "
              class="rounded-lg bg-white px-4 py-2 cursor-pointer"
            >
              {{ $t("bandwidth.reset") }}
            </div>
            <div
              @click.prevent="store()"
              class="rounded-lg bg-green-400 px-4 py-2 cursor-pointer"
            >
              {{ $t("bandwidth.update") }}
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-2"
        >
          <div
            v-for="(profile, index) in profiles"
            :key="profile.id"
            class=" bg-blue-300 rounded-2xl p-2 my-2 border-white border shadow-lg"
          >
            <div class="text-2xl mt-4 mb-2 text-center">{{ profile.name }}</div>
            <div class="flex items-center">
              <p>10%</p>
              <input
                type="range"
                min="0"
                max="200"
                class="appearance-none rounded-full h-4 bg-gray-100 w-full"
                v-model="profile.use_percent"
                @input="
                  total_use_percent = 100;
                  validate();
                "
              />
              <p>200%</p>
            </div>
            <div class="text-center font-bold text-xl">
              <div
                v-if="!profile.edit"
                class="text-blue-500 border-b border-blue-500 inline-block cursor-pointer"
                @click="profile.edit = 1"
              >
                {{ profile.use_percent }}%
              </div>
              <input
                v-if="profile.edit"
                type="number"
                v-model="profile.use_percent"
                class=" focus:outline-none rounded-xl text-center"
                min="1"
              />
              <div>
                {{
                  formatSpeed(
                    profile.download_rate_kb * (profile.use_percent / 100)
                  )
                }}
              </div>
              <div class="flex flex-col justify-center font-normal">
                <div>{{ $t("bandwidth.online_accounts") }}</div>
                <div>{{ profile.online_accounts }}</div>
              </div>
              <div class="flex flex-col justify-center font-normal">
                <div>{{ $t("bandwidth.online_peak_band") }}</div>
                <div>
                  {{
                    formatSpeed(
                      profile.online_accounts *
                        profile.download_rate_kb *
                        (profile.use_percent / 100)
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import { hasPrivilege } from "../global_constants.js";
import { mapGetters } from "vuex";
export default {
  name: "Bandwidth",
  components: { LoadingOverlay },
  data() {
    return {
      loading: false,
      profiles: [],
      total_use_percent: 100,
    };
  },
  computed: {
    ...mapGetters(["settings", "user"]),
    total_peak() {
      var sum = 0;
      this.profiles.forEach((profile) => {
        sum +=
          profile.online_accounts *
          profile.download_rate_kb *
          (profile.use_percent / 100);
      });
      return sum;
    },
  },
  async created() {
    await this.fetchProfiles();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.loading = true;
      const response = await axios.post("profiles/bandwidth", {
        profiles: this.profiles,
      });
      if (response.status == 200) {
        this.loading = false;
        this.$router.push({
          path: "/bandwidth",
          query: { success: "success" },
        });
      }
      this.loading = false;
    },
    async fetchProfiles() {
      this.loading = true;
      const response = await axios.get("profiles?rows=10000");
      if (response.status == 200) {
        this.profiles = response.data.data;
      }
      var flag = true;
      var val1 = this.profiles[0].use_percent;
      for (let index = 0; index < this.profiles.length; index++) {
        const profile = this.profiles[index];
        if (profile.use_percent != val1) {
          flag = false;
          break;
        }
      }
      if (flag) this.total_use_percent = val1;
      this.loading = false;
    },
    formatSpeed(speed) {
      if (speed < 0) return "∞";
      if (speed > 1023 && speed < 1000000)
        return parseFloat(speed / 1024).toFixed(2) + " Mb/s";
      else if (speed >= 1000000)
        return parseFloat(speed / 1000000).toFixed(2) + " Gb/s";
      else return parseFloat(speed).toFixed(2) + " Kb/s";
    },
    updateAll() {
      this.profiles.forEach((profile) => {
        profile.use_percent = this.total_use_percent;
      });
    },
    validate() {
      if (this.total_use_percent < 10) this.total_use_percent = 10;
      this.profiles.forEach((profile) => {
        if (profile.use_percent < 10) profile.use_percent = 10;
      });
    },
  },
};
</script>
<style scoped>
input[type="range"]::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 15px;
  background: #0d4f86;
  cursor: pointer;
  -webkit-appearance: none;
}
</style>
