<template>
  <div class="ticker-wrap">
    <div
      class="ticker"
      :style="
        `-webkit-animation-duration: ${len}s; animation-duration: ${len}s;`
      "
    >
      <!-- <div class="ticker__item">
        Vice mlkshk crucifix beard chillwave meditation hoodie asymmetrical
        Helvetica.
      </div>
      <div class="ticker__item">Ugh PBR&B kale chips Echo Park.</div>
      <div class="ticker__item">
        Gluten-free mumblecore chambray mixtape food truck.
      </div>
      <div class="ticker__item">
        Authentic bitters seitan pug single-origin coffee whatever.
      </div> -->
      <div v-for="news_item in news" :key="news_item.id" class="ticker__item">
        <a
          v-if="news_item.has_url == 1"
          @click="openInNewTab(news_item.url)"
          class="border-b border-white cursor-pointer"
          >{{ news_item.content }}</a
        >
        <div v-else>{{ news_item.content }}</div>
      </div>
      <!-- <div class="ticker__item">
        Vice mlkshk crucifix beard chillwave meditation hoodie asymmetrical
        Helvetica.
      </div>
      <div class="ticker__item">Ugh PBR&B kale chips Echo Park.</div>
      <div class="ticker__item">
        Gluten-free mumblecore chambray mixtape food truck.
      </div>
      <div class="ticker__item">
        Authentic bitters seitan pug single-origin coffee whatever.
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewsBar",
  data() {
    return {
      news: null,
      len: 0,
    };
  },
  async created() {
    await this.fetchNews();
  },
  methods: {
    async fetchNews() {
      const response = await axios.get("settings/news");
      if (response.status == 200) {
        this.news = response.data.data;
      }
      this.news.forEach((element) => {
        this.len += element.content.length;
      });
      this.len /= 2;
    },
    openInNewTab(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
$duration: 30s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0);
  }
}

.ticker-wrap {
  //   position: fixed;
  //   bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 3rem;
  background-color: #282835;
  padding-right: 100%;
  box-sizing: content-box;

  .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-left: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;

    &__item {
      display: inline-block;

      padding: 0 1.2rem;
      font-size: 1.2rem;
      color: white;
    }
  }
  .ticker:hover {
    animation-play-state: paused;
  }
}

body {
  padding-bottom: 5rem;
}
h1,
h2,
p {
  padding: 0 5%;
}
</style>
