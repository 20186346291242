<template>
  <div class="flex flex-col">
    <label class="font-bold">{{ name }}</label>
    <input
      type="text"
      v-model="value"
      class="bg-white rounded-full shadow-lg p-1"
    />
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: ["name", "value"],
};
</script>
