<template>
  <div
    class="flex flex-col rounded-3xl border-2 text-high-dashboard-blue-users shadow-dashboard justify-center items-center"
    :style="this.style"
  >
    <div
      class="flex w-full justify-start items-center bg-white rounded-t-3xl p-2"
      :style="this.style"
    >
      <div
        class="mx-2 flex justify-center items-center w-full text-white text-2xl font-bold"
      >
        {{ this.title }}
      </div>
    </div>
    <div class="bg-white w-full rounded-b-3xl flex justify-center items-center">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardCardBig",
  props: ["title", "color"],
  computed: {
    style() {
      return `border-color: ${this.color}; background-color:${this.color}`;
    },
  },
};
</script>
