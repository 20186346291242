<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 max-w-5xl w-full"
      >
        <div class="flex flex-col">
          <div class="bg-ssr-side_bar-background flex justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("accounts.details") }}
            </div>

            <div
              @click.prevent="hide"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 mt-4 px-10 gap-10 mb-4">
            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div
                class="bg-ssr-side_bar-settings text-white p-3 text-xl font-bold"
              >
                {{ $t("accounts.general") }}
              </div>
              <div
                class="grid grid-cols-2 gap-y-6 my-7 text-center text-sm font-bold"
              >
                <div>{{ $t("accounts.full_name") }}:</div>
                <div class="text-left">
                  {{
                    selectedAccount.first_name + " " + selectedAccount.last_name
                  }}
                </div>
                <div>{{ $t("accounts.email") }}:</div>
                <div class="text-left">{{ selectedAccount.email }}</div>
                <div>{{ $t("accounts.phone") }}:</div>
                <div v-if="selectedAccount.whatsapp_url" class="text-left">
                  <a
                    class="underline text-blue-500"
                    @click="
                      openTab(`https://wa.me/${selectedAccount.whatsapp_url}`)
                    "
                    :href="`https://wa.me/${selectedAccount.whatsapp_url}`"
                    target="_blank"
                    >{{ selectedAccount.phone }}</a
                  >
                </div>
                <div class="text-left" v-else>{{ selectedAccount.phone }}</div>
                <div>{{ $t("accounts.username") }}:</div>
                <div class="text-left">{{ selectedAccount.username }}</div>
                <div>{{ $t("accounts.password") }}:</div>
                <div class="text-left">{{ selectedAccount.password }}</div>
                <div>{{ $t("accounts.sessions") }}:</div>
                <div class="text-left">
                  {{ selectedAccount.allowed_sessions }}
                </div>
                <div>{{ $t("accounts.money.money") }}:</div>
                <div class="text-left">
                  {{ selectedAccount.money
                  }}<span class="font-sans font-bold">{{
                    this.$store.getters.settings.currency
                  }}</span>
                </div>
                <div>{{ $t("accounts.debts") }}:</div>
                <div class="text-left">
                  {{ selectedAccount.debt
                  }}<span class="font-sans font-bold">{{
                    this.$store.getters.settings.currency
                  }}</span>
                </div>
                <div v-if="selectedAccount.ip_address">
                  {{ $t("accounts.ip_address") }}:
                </div>
                <div v-if="selectedAccount.ip_address" class="text-left">
                  {{ selectedAccount.ip_address }}
                </div>
              </div>
            </div>

            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div
                class="bg-ssr-side_bar-settings text-white p-3 text-xl font-bold"
              >
                {{ $t("control.details") }}
              </div>
              <div
                class="grid grid-cols-2 gap-y-6 my-7 text-center text-sm font-bold"
              >
                <div>{{ $t("accounts.profile") }}:</div>
                <div class="text-left">{{ selectedAccount.profile.name }}</div>
                <div>{{ $t("accounts.expires_at") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedAccount.expires_at) }}
                </div>
                <div v-if="selectedAccount.allowed_quota_mb > 0">
                  {{ $t("control.allowed_quota") }}:
                </div>
                <div
                  class="text-left"
                  v-if="selectedAccount.allowed_quota_mb > 0"
                >
                  {{
                    selectedAccount.allowed_quota_mb > 1024
                      ? (selectedAccount.allowed_quota_mb / 1024).toFixed(2) +
                        " Gb"
                      : selectedAccount.allowed_quota_mb + " Mb"
                  }}
                </div>
                <div v-if="selectedAccount.allowed_quota_mb > 0">
                  {{ $t("control.remaining_quota") }}:
                </div>
                <div
                  v-if="selectedAccount.allowed_quota_mb > 0"
                  class="text-left"
                >
                  {{
                    selectedAccount.remaining_quota_mb > 1024
                      ? (selectedAccount.remaining_quota_mb / 1024).toFixed(2) +
                        " Gb"
                      : selectedAccount.remaining_quota_mb + " Mb"
                  }}
                </div>
                <div>{{ $t("control.created_at") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedAccount.created_at) }}
                </div>
                <div>{{ $t("control.updated_at") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedAccount.updated_at) }}
                </div>
                <div>{{ $t("control.active_sessions") }}:</div>
                <div class="text-left">
                  {{ selectedAccount.active_sessions.length }}
                </div>
                <div v-if="selectedAccount.active_sessions.length > 0">
                  {{ $t("online.ip") }}
                </div>
                <div
                  v-if="selectedAccount.active_sessions.length > 0"
                  class="text-left"
                >
                  <span
                    v-for="session in selectedAccount.active_sessions"
                    :key="session.radacctid"
                    >{{ session.framedipaddress }},,</span
                  >
                </div>
                <div>{{ $t("accounts.last_seen") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedAccount.last_seen) }}
                </div>
                <div v-if="selectedAccount.is_mac">
                  {{ $t("accounts.mac_address") }}:
                </div>
                <div v-if="selectedAccount.is_mac" class="text-left">
                  {{ selectedAccount.mac_address }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!compacted"
            class="m-2 grid grid-cols-3 md:grid-cols-6 gap-3 text-xs"
          >
            <div
              v-if="hasPrivilege('accounts_edit_accounts')"
              @click="editData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              {{ $t("actions.edit") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_view_index_online')"
              @click="
                this.$store.commit('changeKey');
                this.$router.push(`/sessions/${selectedAccount.username}`);
              "
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              {{ $t("actions.view_sessions") }}
            </div>
            <div
              v-if="hasPrivilege('accounting_view_account_bills')"
              @click="
                this.$store.commit('changeKey');
                this.$router.push(
                  `/bills?username=${selectedAccount.username}`
                );
              "
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              {{ $t("actions.view_bills") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_renew_48')"
              @click="renewTemp(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-6 h-6 rounded-lg inline-block"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              {{ $t("actions.renew_48") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_edit_accounts')"
              @click="renewData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 rounded-lg inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.renew") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_edit_accounts_money')"
              @click="moneyData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 rounded-lg inline-block"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              {{ $t("actions.edit_money") }}
            </div>
            <div
              v-if="
                hasPrivilege('accounts_clear_sessions') &&
                user.data.type != 'reseller'
              "
              @click="clearData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.clear") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_disconnect_accounts')"
              @click="disconnectData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.disconnect") }}
            </div>
            <div
              v-if="
                hasPrivilege('accounts_disable_accounts') &&
                !selectedAccount.enabled
              "
              @click="disableData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.enable") }}
            </div>
            <div
              v-if="
                hasPrivilege('accounts_disable_accounts') &&
                selectedAccount.enabled
              "
              @click="disableData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                viewBox="0 0 24 24"
                fill="#FFFFFF"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
                />
              </svg>
              {{ $t("actions.disable") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_edit_accounts')"
              @click="deleteData(selectedAccount)"
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              {{ $t("actions.delete") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import ExpiredWindow from "../ExpiredWindow.vue";
export default {
  name: "AccountDetails",
  props: ["visible", "selectedAccount", "compacted"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("yyyy-MM-DD, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    editData(account) {
      this.$router.push(`/accounts/${account.id}/edit`);
      this.$store.commit("changeKey");
    },
    renewData(account) {
      this.$emit("renew");
    },
    moneyData(account) {
      this.$emit("money");
    },
    async renewTemp(account) {
      this.$emit("temp_renew");
    },
    async clearData() {
      const response = await axios.put(
        `/accounts/${this.selectedAccount.id}/clear`
      );
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      } else {
        this.$emit("failed");
      }
    },
    async deleteData() {
      this.$emit("delete");
      // const response = await axios.delete(
      //   `accounts/${this.selectedAccount.id}`
      // );
      // if (response.status == 200) {
      //   this.$emit("updated");
      // }
    },
    async disableData() {
      const response = await axios.put(
        `accounts/${this.selectedAccount.id}/disable`
      );
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      } else {
        this.$emit("failed");
      }
    },
    async disconnectData() {
      const response = await axios.put(
        `accounts/${this.selectedAccount.id}/disconnect`
      );
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      } else if (response.status == 210) {
        this.$emit("offline");
      } else {
        this.$emit("failed");
      }
    },
    openTab(url) {
      window.open(url, "_blank");
    },
  },
  components: { ExpiredWindow },
};
</script>
