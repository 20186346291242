<template>
  <div
    v-if="visible"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full
        "
      >
        <div class="flex flex-col">
          <div class="bg-ssr-side_bar-background flex justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("cards.details") }}
            </div>
            <div
              @click.prevent="hide"
              class="
                rounded-full
                cursor-pointer
                bg-ssr-side_bar-button_hover
                text-white text-xs
                my-2
                mr-6
                w-28
                flex
                justify-center
                items-center
                font-bold
              "
            >
              {{ $t("batches.cancel") }}
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 py-12 px-10 gap-10">
            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div
                class="
                  bg-ssr-side_bar-settings
                  text-white
                  p-3
                  text-xl
                  font-bold
                "
              >
                {{ $t("accounts.general") }}
              </div>
              <div
                class="
                  grid grid-cols-2
                  gap-y-7
                  my-7
                  text-center text-sm
                  font-bold
                "
              >
                <div>{{ $t("cards.batch") }}:</div>
                <div class="text-left" v-if="selectedCard.batch">
                  {{ selectedCard.batch.name }}
                </div>
                <div>{{ $t("cards.username") }}:</div>
                <div class="text-left">{{ selectedCard.username }}</div>
                <div>{{ $t("cards.password") }}:</div>
                <div class="text-left">{{ selectedCard.password }}</div>
                <div>{{ $t("cards.used") }}:</div>
                <div class="text-left">
                  <div
                    v-if="!selectedCard.used"
                    class="
                      inline-block
                      px-4
                      p-1
                      bg-green-600
                      text-green-200 text-center text-xs
                      rounded-full
                    "
                  >
                    {{ $t("control.no") }}
                  </div>
                  <div
                    v-if="selectedCard.used"
                    class="
                      inline-block
                      px-4
                      p-1
                      bg-red-600
                      text-red-200 text-center text-xs
                      rounded-full
                    "
                  >
                    {{ $t("control.yes") }}
                  </div>
                </div>
                <div>{{ $t("batches.sessions") }}:</div>
                <div class="text-left">
                  {{ selectedCard.allowed_sessions }}
                </div>
                <div v-if="selectedCard.batch.is_mac">
                  {{ $t("accounts.mac_address") }}:
                </div>
                <div v-if="selectedCard.batch.is_mac" class="text-left">
                  {{ selectedCard.mac_address }}
                </div>
              </div>
            </div>

            <div class="rounded-lg overflow-hidden bg-gray-200 text-center">
              <div
                class="
                  bg-ssr-side_bar-settings
                  text-white
                  p-3
                  text-xl
                  font-bold
                "
              >
                {{ $t("control.details") }}
              </div>
              <div
                class="
                  grid grid-cols-2
                  gap-y-7
                  my-7
                  text-center text-sm
                  font-bold
                "
              >
                <div>{{ $t("batches.profile") }}:</div>
                <div class="text-left">{{ selectedCard.profile.name }}</div>
                <div>{{ $t("cards.expires_at") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedCard.expires_at) }}
                </div>
                <div v-if="selectedCard.profile.traffic_limited">
                  {{ $t("control.allowed_quota") }}:
                </div>
                <div
                  v-if="selectedCard.profile.traffic_limited"
                  class="text-left"
                >
                  {{
                    selectedCard.allowed_quota_mb > 1024
                      ? selectedCard.allowed_quota_mb / 1024 + " Gb"
                      : selectedCard.allowed_quota_mb + " Mb"
                  }}
                </div>
                <div v-if="selectedCard.profile.traffic_limited">
                  {{ $t("control.remaining_quota") }}:
                </div>
                <div
                  v-if="selectedCard.profile.traffic_limited"
                  class="text-left"
                >
                  {{
                    selectedCard.remaining_quota_mb > 1024
                      ? selectedCard.remaining_quota_mb / 1024 + " Gb"
                      : selectedCard.remaining_quota_mb + " Mb"
                  }}
                </div>
                <div>{{ $t("control.created_at") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedCard.created_at) }}
                </div>
                <div>{{ $t("control.updated_at") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedCard.updated_at) }}
                </div>
                <div>{{ $t("control.active_sessions") }}:</div>
                <div class="text-left">
                  {{ selectedCard.active_sessions.length }}
                </div>
                <div>{{ $t("accounts.last_seen") }}:</div>
                <div class="text-left">
                  {{ formatDate(selectedCard.last_seen) }}
                </div>
              </div>
            </div>
          </div>
          <div class="m-2 grid grid-cols-3 md:grid-cols-6 gap-3 text-xs">
            <div
              v-if="hasPrivilege('cards_edit_card')"
              @click="editData(selectedCard)"
              class="
                flex-1
                text-center
                bg-ssr-side_bar-button
                text-white
                group
                cursor-pointer
                hover:bg-ssr-side_bar-button_hover
                rounded-xl
                p-1
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
              {{ $t("actions.edit") }}
            </div>
            <div
              v-if="hasPrivilege('accounts_view_index_online')"
              @click="
                this.$store.commit('changeKey');
                this.$router.push(`/sessions/${selectedCard.username}`);
              "
              class="flex-1 text-center bg-ssr-side_bar-button text-white group cursor-pointer hover:bg-ssr-side_bar-button_hover rounded-xl p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              {{ $t("actions.view_sessions") }}
            </div>
            <div
              v-if="
                hasPrivilege('cards_clear_sessions') &&
                  user.data.type != 'reseller'
              "
              @click="clearData(selectedCard)"
              class="
                flex-1
                text-center
                bg-ssr-side_bar-button
                text-white
                group
                cursor-pointer
                hover:bg-ssr-side_bar-button_hover
                rounded-xl
                p-1
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.clear") }}
            </div>
            <div
              v-if="hasPrivilege('cards_disconnect_cards')"
              @click="disconnectData(selectedCard)"
              class="
                flex-1
                text-center
                bg-ssr-side_bar-button
                text-white
                group
                cursor-pointer
                hover:bg-ssr-side_bar-button_hover
                rounded-xl
                p-1
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.disconnect") }}
            </div>
            <div
              v-if="
                hasPrivilege('cards_disable_cards') && !selectedCard.enabled
              "
              @click="disableData(selectedCard)"
              class="
                flex-1
                text-center
                bg-ssr-side_bar-button
                text-white
                group
                cursor-pointer
                hover:bg-ssr-side_bar-button_hover
                rounded-xl
                p-1
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {{ $t("actions.enable") }}
            </div>
            <div
              v-if="hasPrivilege('cards_disable_cards') && selectedCard.enabled"
              @click="disableData(selectedCard)"
              class="
                flex-1
                text-center
                bg-ssr-side_bar-button
                text-white
                group
                cursor-pointer
                hover:bg-ssr-side_bar-button_hover
                rounded-xl
                p-1
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                viewBox="0 0 24 24"
                fill="#FFFFFF"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
                />
              </svg>
              {{ $t("actions.disable") }}
            </div>
            <div
              v-if="hasPrivilege('cards_delete_cards')"
              @click="deleteData(selectedCard)"
              class="
                flex-1
                text-center
                bg-ssr-side_bar-button
                text-white
                group
                cursor-pointer
                hover:bg-ssr-side_bar-button_hover
                rounded-xl
                p-1
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block rounded-lg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              {{ $t("actions.delete") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import ExpiredWindow from "../ExpiredWindow.vue";
export default {
  name: "CardDetails",
  props: ["visible", "selectedCard"],
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("yyyy-MM-DD, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    editData(card) {
      this.$router.push(`/cards/edit/${card.id}`);
    },
    async clearData() {
      const response = await axios.put(`/cards/${this.selectedCard.id}/clear`);
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      }
    },
    async deleteData() {
      const response = await axios.delete(`cards/${this.selectedCard.id}/card`);
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      }
    },
    async disableData() {
      const response = await axios.put(`cards/${this.selectedCard.id}/disable`);
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      }
    },
    async disconnectData() {
      const response = await axios.put(
        `cards/${this.selectedCard.id}/disconnect`
      );
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      }
    },
  },
  components: { ExpiredWindow },
};
</script>
