import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./axios";
import { createI18n } from "vue-i18n";
import VueClickAway from "vue3-click-away";
import { messages } from "./lang/app";
import print from "vue3-print-nb";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import resumable from "resumablejs";
import { SERVER_URL } from "./env";
import ColorInput from "vue-color-input";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});
const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(VueClickAway);
app.use(print);
app.use(Vue3SimpleHtml2pdf);
app.use(resumable);
app.use(ColorInput);
app.provide("server_url", localStorage.getItem("server"));
app.mount("#app");
