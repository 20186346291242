<template>
  <svg viewBox="0 0 64 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.548 2.92502L0.814299 45.8854C0.283729 46.8042 0.00299465 47.8459 2.38261e-05 48.9069C-0.00294699 49.9679 0.27195 51.0112 0.797365 51.933C1.32278 52.8548 2.0804 53.6229 2.99485 54.161C3.9093 54.6991 4.9487 54.9883 6.00964 55H57.477C58.5379 54.9883 59.5773 54.6991 60.4918 54.161C61.4062 53.6229 62.1638 52.8548 62.6893 51.933C63.2147 51.0112 63.4896 49.9679 63.4866 48.9069C63.4836 47.8459 63.2029 46.8042 62.6723 45.8854L36.9387 2.92502C36.397 2.03211 35.6344 1.29386 34.7244 0.781512C33.8144 0.269161 32.7877 0 31.7433 0C30.699 0 29.6723 0.269161 28.7622 0.781512C27.8522 1.29386 27.0896 2.03211 26.548 2.92502Z"
      fill="currentColor"
    />
    <path
      d="M31.7432 18.541V30.6939"
      stroke="white"
      stroke-width="8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.7432 42.8467H31.7735"
      stroke="white"
      stroke-width="8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningIcon",
};
</script>
