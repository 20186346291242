<template>
  <div>
    <div
      class="flex w-full justify-start md:justify-center items-center relative z-0"
    >
      <div class="font-bold cursor-pointer" @click="prevPage">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="bg-blue-200 rounded-lg p-1">
        <select
          @change="selectPage"
          v-model="meta.current_page"
          class="bg-blue-200"
        >
          <option v-for="(item, index) in range()" :key="index">{{
            item
          }}</option>
        </select>
      </div>
      <div class="mx-2 font-bold">{{ $t("control.of") }}</div>
      <div class="bg-blue-200 rounded-lg p-1">{{ meta.last_page }}</div>
      <div class="font-bold cursor-pointer" @click="nextPage">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="absolute right-0 top-0" v-if="!small">
        <select
          class="bg-white rounded-full w-full shadow-lg py-1 px-9 focus:outline-none"
          v-model="rows"
          @change="updateRows"
        >
          <option
            v-for="value in [
              { id: 10, name: $t('control.rows_10') },
              { id: 50, name: $t('control.rows_50') },
              { id: 100, name: $t('control.rows_100') },
              { id: 500, name: $t('control.rows_500') },
              { id: 10000000, name: $t('control.rows_all') },
            ]"
            v-bind:key="value.id"
            :value="value.id"
            >{{ value.name }}</option
          >
        </select>
      </div>
    </div>
    <div
      class="flex flex-col justify-center items-center font-bold"
      v-if="!small"
    >
      <div>{{ $t("control.total") }}</div>
      <div>{{ meta.total }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaginationRow",
  data() {
    return {
      currentPage: 1,
      rows: 10,
    };
  },
  props: ["meta", "small", "row"],
  created() {
    if (this.row) {
      this.rows = this.row;
    }
  },
  methods: {
    prevPage() {
      if (this.meta.current_page > 1) {
        var cur = this.meta.current_page - 1;
        this.$emit("triggerupdate", this.rows, cur);
      }
    },
    nextPage() {
      if (this.meta.current_page < this.meta.last_page) {
        var cur = this.meta.current_page + 1;
        this.$emit("triggerupdate", this.rows, cur);
      }
    },
    selectPage(page) {
      var cur = this.meta.current_page;
      this.$emit("triggerupdate", this.rows, cur);
    },
    updateRows() {
      this.meta.current_page = 1;
      this.$emit("triggerupdate", this.rows, this.meta.current_page);
    },
    range() {
      var start = 1;
      var end = this.meta.last_page;
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
  },
};
</script>
