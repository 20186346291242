<template>
  <!-- <nav class="navBar">
    <ul class="navBar_link">
      <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
      <li><router-link :to="{ name: 'Account' }">Account</router-link></li>
    </ul>
  </nav> -->
  <nav
    class=" bg-ssr-navbar-background pt-2 md:pt-1 pb-1 px-1 mt-0 h-auto w-full shadow-lg"
  >
    <div
      class="flex flex-wrap items-center"
      :class="{ 'flex-row-reverse': $i18n.locale == 'ar' }"
    >
      <div
        class="flex flex-shrink w-1/3 justify-end items-center text-white"
        :class="{
          'md:justify-start flex-row-reverse': $i18n.locale == 'en',
          'md:justify-end pr-3': $i18n.locale == 'ar',
        }"
      >
        <div
          class="rounded-full bg-white shadow-md flex flex-grow"
          :class="{
            'flex-row-reverse': $i18n.locale == 'en',
            disabled: !this.$store.getters.settings.license.active,
          }"
        >
          <input
            type="text"
            class="w-full rounded-full focus:outline-none text-black px-1"
            v-model="search_query"
            :placeholder="$t('navbar.search')"
            :class="{
              'text-left': $i18n.locale == 'en',
              'text-right': $i18n.locale == 'ar',
            }"
            @change="goto_search"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-9 w-9 text-gray-500 m-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <img
          @click.prevent="this.$emit('collapse')"
          src="@/assets/logo_full2.svg"
          class="ml-6 h-16 m-3 hidden md:block cursor-pointer"
          alt=""
        />
        <!-- <img
          @click.prevent="this.$emit('collapse')"
          src="@/assets/logo_full2.svg"
          class="h-12 m-3 block md:hidden cursor-pointer"
          alt=""
        /> -->
      </div>

      <!-- SearchBox -->
      <!-- <div
        class="flex flex-1 md:w-1/3 justify-center md:justify-start text-white px-2"
      >
        <span class="relative w-full">
          <input
            type="search"
            placeholder="Search"
            class="w-full bg-gray-900 text-white transition border border-transparent focus:outline-none focus:border-gray-400 rounded py-3 px-2 pl-10 appearance-none leading-normal"
          />
          <div class="absolute search-icon" style="top: 1rem; left: .8rem;">
            <svg
              class="fill-current pointer-events-none text-white w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
              ></path>
            </svg>
          </div>
        </span>
      </div> -->

      <div
        class="flex pt-2 items-center content-center justify-between w-2/3 text-xs md:text-lg"
        :class="{ 'flex-row-reverse': $i18n.locale == 'ar' }"
      >
        <div class="flex-1 flex items-center justify-center font-bold gap-4">
          <img
            v-if="settings.network_logo"
            :src="server_url + settings.network_logo"
            class="hidden md:block md:h-16 h-16 cursor-pointer"
            alt="Network LOGO"
          />
          <div class="flex flex-col items-center">
            <div>{{ settings.network_name }}</div>
            <div>{{ settings.license.realm }}</div>
          </div>
          <div class="flex flex-col">
            <Clock />
          </div>
        </div>
        <ul
          class="list-reset flex justify-between flex-1 md:flex-none items-center"
          :class="{ 'flex-row-reverse': $i18n.locale == 'ar' }"
        >
          <li
            class="flex-1 md:flex-none md:mr-3 flex justify-end items-center"
            :class="{ 'flex-row-reverse': $i18n.locale == 'ar' }"
            @click="this.$emit('notifications')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9 hidden md:block cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            <div class="relative inline-block mx-8">
              <button
                @click.prevent="languageVisible = !languageVisible"
                v-click-away="closeLanguage"
                class="drop-button text-black focus:outline-none relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-9 w-9"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                  />
                </svg>
                <transition name="expandy">
                  <ul
                    v-if="languageVisible"
                    class=" absolute top-0 z-50 right-0 mt-9 bg-white text-black rounded-md"
                  >
                    <li
                      @click="changeLanguage('en')"
                      class="py-1 px-4 hover:bg-ssr-side_bar-button_hover"
                    >
                      English
                    </li>
                    <li
                      @click="changeLanguage('ar')"
                      class="py-1 px-4 hover:bg-ssr-side_bar-button_hover"
                    >
                      العربية
                    </li>
                    <li
                      @click="changeLanguage('tr')"
                      class="py-1 px-4 hover:bg-ssr-side_bar-button_hover"
                    >
                      Turkce
                    </li>
                  </ul>
                </transition>
              </button>
            </div>
            <div
              class="flex flex-col justify-between items-start text-black gap-2"
            >
              <div class="font-bold">{{ user.data.first_name }}</div>
              <div class="font-light">{{ user.data.last_name }}</div>
            </div>
            <img
              v-if="user.data.profile_image"
              :src="server_url + user.data.profile_image"
              class="rounded-full md:h-16 md:w-16 mx-2 w-16 h-16 cursor-pointer"
              alt="Profile Image"
              @click.prevent="this.$router.push(`/admins/${user.data.id}/edit`)"
            />
            <img
              v-if="!user.data.profile_image"
              src="@/assets/person.png"
              class="rounded-full md:h-16 md:w-16 mx-2 w-12 h-12 cursor-pointer"
              alt="Profile Image"
              @click.prevent="this.$router.push(`/admins/${user.data.id}/edit`)"
            />
          </li>

          <!-- NOTIFICATIONS AND MESSAGES -->
          <!-- <li
            v-click-away="closeNotifications"
            class="flex-1 md:flex-none flex rounded-full justify-center bg-white md:px-7 py-3 text-sm relative"
          >
            <button
              class="flex"
              @click.prevent="notificationsVisible = !notificationsVisible"
            >
              <span
                class="rounded-full flex items-center justify-center bg-gray-800 text-white h-6 w-6 text-xs font-extralight"
                >00</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              <div
                class="mr-4 hidden md:block"
                :class="{ 'px-4': $i18n.locale == 'ar' }"
              >
                {{ $t("navbar.messages") }}
              </div>
            </button>

            <button
              class="flex"
              @click.prevent="notificationsVisible = !notificationsVisible"
            >
              <span
                class="rounded-full flex items-center justify-center bg-gray-800 text-white h-6 w-6 text-xs font-extralight"
                >00</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
              <div
                class="hidden md:block"
                :class="{ 'px-4': $i18n.locale == 'ar' }"
              >
                {{ $t("navbar.notifications") }}
              </div>
            </button>
            <transition name="expandy">
              <div
                v-if="notificationsVisible"
                class="absolute bg-white mt-12 rounded-lg w-full p-2 text-center"
              >
                {{ $t("navbar.empty_messages") }}
              </div>
            </transition>
          </li> -->
        </ul>
      </div>
      <!-- <div v-if="settings.type == 'local'" class="flex justify-center w-full">
        <div
          class="rounded-l-full px-1 text-white"
          :class="{
            'bg-green-600': server.cpu <= 30,
            'bg-yellow-600': server.cpu > 30 && server.cpu <= 76,
            'bg-red-600': server.cpu > 76,
          }"
        >
          CPU
        </div>
        <div class="w-1/4 bg-gray-400 rounded-r-full dark:bg-gray-700">
          <div
            class="rounded-r-full text-center text-white"
            :style="`width: ${server.cpu}%`"
            :class="{
              'bg-green-600': server.cpu <= 30,
              'bg-yellow-600': server.cpu > 30 && server.cpu <= 76,
              'bg-red-600': server.cpu > 76,
            }"
          >
            %{{ server.cpu }}
          </div>
        </div>
        <div class="w-12"></div>
        <div
          class="rounded-l-full px-1 text-white"
          :class="{
            'bg-green-600': server.ram <= 30,
            'bg-yellow-600': server.ram > 30 && server.ram <= 76,
            'bg-red-600': server.ram > 76,
          }"
        >
          RAM
        </div>
        <div class="w-1/4 bg-gray-400 rounded-r-full dark:bg-gray-700">
          <div
            class="rounded-r-full text-center text-white"
            :class="{
              'bg-green-600': server.ram <= 30,
              'bg-yellow-600': server.ram > 30 && server.ram <= 76,
              'bg-red-600': server.ram > 76,
            }"
            :style="`width: ${server.ram}%`"
          >
            %{{ server.ram }}
          </div>
        </div>
      </div> -->
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Clock from "./sub_elements/Clock.vue";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  inject: {
    server_url: {
      from: "server_url",
    },
  },
  created() {
    this.fetchServer();
    if (this.settings.type == "local")
      setInterval(() => {
        this.fetchServer();
      }, 30000);
  },
  data() {
    return {
      isHidden: true,
      languageVisible: false,
      notificationsVisible: false,
      search_query: "",
      timer: null,
      server: {
        cpu: 0,
        ram: 0,
      },
    };
  },
  props: ["collapsed"],
  methods: {
    closeLanguage(event) {
      this.languageVisible = false;
    },
    closeNotifications(event) {
      this.notificationsVisible = false;
    },
    handleLogout() {
      localStorage.removeItem("token");
      this.$store.dispatch("user", null);
      this.$router.push("/");
    },
    async changeLanguage(lang) {
      this.$i18n.locale = lang;
      const response = await axios.post("settings/changelang", { lang: lang });
      if (response.status == 200) console.log("Language Changed");
    },
    toggleDD() {
      this.isHidden = !this.isHidden;
    },
    goto_search() {
      this.$router.push(`/search?query=${this.search_query}`);
    },
    async fetchServer() {
      const response = await axios("dash/server");
      if (response.status == 200) {
        this.server = response.data.data;
      }
    },
  },
  components: { Clock },
};
</script>
