<template>
  <div
    v-if="notification.visible"
    class="flex justify-center absolute top-0 right-0 z-30 my-4 mx-4 md:mx-60"
    :class="{ ar: $i18n.locale == 'ar', en: $i18n.locale != 'ar' }"
  >
    <div class="flex space-x-2 justify-center">
      <div
        class="
          bg-white
          shadow-lg
          mx-auto
          w-96
          max-w-full
          text-sm
          pointer-events-auto
          bg-clip-padding
          rounded-lg
          block
        "
        id="static-example"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-mdb-autohide="false"
      >
        <div
          class="p-3 rounded-lg break-words text-white text-2xl"
          :style="`background-color: ${notification.color}`"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-9 w-9 inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          {{ notification.message }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ToastNotification",
  props: ["notification"],
};
</script>
<style scoped>
.ar {
  direction: rtl;
}
.en {
  direction: ltr;
}
</style>
