<template>
  <div class="main-content flex-1 bg-gray-100">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        class="bg-gray-200 rounded-xl w-full m-6 flex flex-col pb-12 px-5 items-center"
      >
        <div class="w-full md:w-1/3">
          <label class="font-bold mt-6">{{ $t("batches.designer") }}</label>
          <select
            v-model="elements.designer"
            class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
          >
            <option
              v-for="service in [
                { name: $t('batches.basic_designer'), id: 'basic' },
                { name: $t('batches.advanced_designer'), id: 'advanced' },
              ]"
              v-bind:key="service.id"
              :value="service.id"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
        <div class="w-full md:w-1/3 my-4">
          <label class="font-bold mt-6">{{
            $t("batches.saved_designs")
          }}</label>
          <select
            v-model="design_id"
            @change="selectDesign"
            class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
          >
            <option value="-1">
              {{ $t("batches.new_design") }}
            </option>
            <option
              v-for="design in designs"
              v-bind:key="design.id"
              :value="design.id"
            >
              {{ design.name }}
            </option>
          </select>
        </div>
        <div
          id="advanced_card_preview"
          v-if="cards_chunks.length > 0 && elements.designer == 'advanced'"
          class="flex flex-col text-sm relative font-sans mt-4 bg-white"
          :class="{
            times1: cards_count == 1,
            times2: cards_count == 2,
            times3: cards_count == 3,
          }"
          :style="`background-image: url(${this.background});background-repeat: no-repeat;background-position: center;background-size: cover;`"
        >
          <div
            v-if="elements.username.visible"
            class="absolute"
            :style="`top: ${elements.username.y}%;left: ${elements.username.x}%; font-size: ${elements.username.size}px; color: ${elements.username.color}`"
          >
            {{ cards_chunks[0][0].username }}
          </div>
          <div
            v-if="elements.password.visible"
            class="absolute"
            :style="`top: ${elements.password.y}%;left: ${elements.password.x}%; font-size: ${elements.password.size}px; color: ${elements.password.color}`"
          >
            {{ cards_chunks[0][0].password }}
          </div>
          <div
            v-if="elements.network.visible"
            class="absolute"
            :style="`top: ${elements.network.y}%;left: ${elements.network.x}%; font-size: ${elements.network.size}px; color: ${elements.network.color}`"
          >
            {{ settings.network_name }}
          </div>
          <img
            v-if="elements.logo.visible"
            :style="`top: ${elements.logo.y}%;left: ${elements.logo.x}%; height:${elements.logo.size}mm`"
            :src="server_url + settings.network_logo"
            alt=""
            class="absolute mb-1"
          />
          <div
            v-if="elements.price.visible"
            class="absolute"
            :style="`top: ${elements.price.y}%;left: ${elements.price.x}%; font-size: ${elements.price.size}px; color: ${elements.price.color}`"
          >
            {{ cards_chunks[0][0].batch.price + " " + settings.currency }}
          </div>
          <div
            v-if="elements.speed.visible"
            class="absolute"
            :style="`top: ${elements.speed.y}%;left: ${elements.speed.x}%; font-size: ${elements.speed.size}px; color: ${elements.speed.color}`"
          >
            {{ calculateSpeed(cards_chunks[0][0].profile.download_rate_kb) }}
          </div>
          <div
            v-if="elements.expiration.visible"
            class="absolute"
            :style="`top: ${elements.expiration.y}%;left: ${elements.expiration.x}%; font-size: ${elements.expiration.size}px; color: ${elements.expiration.color}`"
          >
            {{
              cards_chunks[0][0].profile.expiration_value +
              calculateUnit(cards_chunks[0][0].profile.expiration_unit)
            }}
          </div>
          <div
            v-if="elements.quota.visible"
            class="absolute"
            :style="`top: ${elements.quota.y}%;left: ${elements.quota.x}%; font-size: ${elements.quota.size}px; color: ${elements.quota.color}`"
          >
            {{
              calculateQuota(cards_chunks[0][0].profile.traffic_limit_value_mb)
            }}
          </div>
          <div
            v-if="elements.profile.visible"
            class="absolute"
            :style="`top: ${elements.profile.y}%;left: ${elements.profile.x}%; font-size: ${elements.profile.size}px; color: ${elements.profile.color}`"
          >
            {{ cards_chunks[0][0].profile.name }}
          </div>
          <div
            v-if="elements.sessions.visible"
            class="absolute"
            :style="`top: ${elements.sessions.y}%;left: ${elements.sessions.x}%; font-size: ${elements.sessions.size}px; color: ${elements.sessions.color}`"
          >
            {{ cards_chunks[0][0].allowed_sessions }}
          </div>
          <div
            v-if="elements.phone_number.visible"
            class="absolute"
            :style="`top: ${elements.phone_number.y}%;left: ${elements.phone_number.x}%; font-size: ${elements.phone_number.size}px; color: ${elements.phone_number.color}`"
          >
            {{ settings.phone_number }}
          </div>
          <div
            v-if="elements.status_url.visible"
            class="absolute"
            :style="`top: ${elements.status_url.y}%;left: ${elements.status_url.x}%; font-size: ${elements.status_url.size}px; color: ${elements.status_url.color}`"
          >
            {{ settings.status_url }}
          </div>
          <div
            v-if="elements.qr_code.visible && elements.qr_code"
            class="absolute"
            :style="`top: ${elements.qr_code.y}%;left: ${elements.qr_code.x}%; font-size: ${elements.qr_code.size}px;`"
          >
            <qrcode-vue
              :value="`http://${elements.qr_code.url}/login?username=${cards_chunks[0][0].username}&password=${cards_chunks[0][0].password}`"
              :size="elements.qr_code.size"
              :render-as="'svg'"
              level="L"
            />
          </div>
        </div>
        <div
          id="basic_card_preview"
          v-if="cards_chunks.length > 0 && elements.designer == 'basic'"
          class="flex flex-col justify-center items-center border border-t-4 border-b-4 rounded-3xl text-sm relative"
          :class="{
            times1: cards_count == 1,
            times2: cards_count == 2,
            times3: cards_count == 3,
          }"
          style="height: 3.85cm; font-size: 9pt"
          :style="`border-color: ${elements.colors.border}; background-color: ${elements.colors.background};background-image: url(${this.background});background-repeat: no-repeat;background-position: center;background-size: cover;`"
        >
          <div
            class="flex w-full justify-between mt-2"
            :style="`color: ${elements.colors.text_big}`"
          >
            <div
              v-if="elements.qr_code.visible && elements.qr_code"
              class="flex flex-col w-full justify-center items-center"
            >
              <div>
                <qrcode-vue
                  :value="`http://${elements.qr_code.url}/login?username=${cards_chunks[0][0].username}&password=${cards_chunks[0][0].password}`"
                  :size="60"
                  :render-as="'svg'"
                  level="L"
                />
              </div>
            </div>
            <div class="flex flex-col w-full justify-center items-center">
              <div>
                {{ $t("cards.username") }}
              </div>
              <div class="mt-2">{{ $t("cards.password") }}</div>
            </div>

            <div class="flex flex-col w-full justify-center items-center">
              <div
                class="w-full text-center rounded-full border"
                :style="`background-color: ${elements.colors.input}; border-color: ${elements.colors.border}`"
              >
                {{ cards_chunks[0][0].username }}
              </div>
              <div
                class="w-full text-center rounded-full border mt-2"
                :style="`background-color: ${elements.colors.input}; border-color: ${elements.colors.border}`"
              >
                {{ cards_chunks[0][0].password }}
              </div>
            </div>
            <div
              v-if="elements.logo.visible || elements.network.visible"
              class="flex flex-col w-full justify-center items-center"
            >
              <div v-if="elements.logo.visible">
                <img
                  :src="server_url + settings.network_logo"
                  alt=""
                  class="h-14 mb-1"
                />
              </div>
              <div v-if="elements.network.visible">
                {{ settings.network_name }}
              </div>
            </div>
          </div>
          <div
            class="grid w-full gap-x-1 px-2"
            style="font-size: 6pt"
            :class="{
              'grid-cols-1': elements.count == 1,
              'grid-cols-2': elements.count == 2,
              'grid-cols-3': elements.count == 3,
              'grid-cols-4': elements.count >= 4,
            }"
            :style="`color: ${elements.colors.text_small}`"
          >
            <div
              v-if="elements.price.visible"
              class="rounded-full flex items-center justify-center w-full m-1"
              :style="`background-color: ${elements.colors.boxes}`"
            >
              <!-- <div>{{ $t("batches.speed") }}</div> -->
              <div>
                {{ cards_chunks[0][0].batch.price + " " + settings.currency }}
              </div>
            </div>
            <div
              v-if="elements.speed.visible"
              class="rounded-full flex items-center justify-center w-full m-1"
              :style="`background-color: ${elements.colors.boxes}`"
            >
              <!-- <div>{{ $t("batches.speed") }}</div> -->
              <div>
                {{
                  calculateSpeed(cards_chunks[0][0].profile.download_rate_kb)
                }}
              </div>
            </div>
            <div
              v-if="elements.expiration.visible"
              class="rounded-full flex items-center justify-center w-full m-1"
              :style="`background-color: ${elements.colors.boxes}`"
            >
              <!-- <div>{{ $t("batches.duration") }}</div> -->
              <div>
                {{
                  cards_chunks[0][0].profile.expiration_value +
                  calculateUnit(cards_chunks[0][0].profile.expiration_unit)
                }}
              </div>
            </div>
            <div
              v-if="elements.quota.visible"
              class="rounded-full flex items-center justify-center w-full m-1"
              :style="`background-color: ${elements.colors.boxes}`"
            >
              <!-- <div>{{ $t("batches.quota") }}</div> -->
              <div>
                {{
                  calculateQuota(
                    cards_chunks[0][0].profile.traffic_limit_value_mb
                  )
                }}
              </div>
            </div>
            <div
              v-if="elements.profile.visible"
              class="rounded-full flex items-center justify-center w-full m-1"
              :style="`background-color: ${elements.colors.boxes}`"
            >
              <!-- <div>{{ $t("batches.quota") }}</div> -->
              <div>
                {{ cards_chunks[0][0].profile.name }}
              </div>
            </div>
            <div
              v-if="elements.sessions.visible"
              class="rounded-full flex items-center justify-center w-full m-1"
              :style="`background-color: ${elements.colors.boxes}`"
            >
              <!-- <div>{{ $t("batches.quota") }}</div> -->
              <div>
                {{
                  cards_chunks[0][0].allowed_sessions +
                  " " +
                  $t("control.device")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="elements.phone_number.visible"
            class="w-full flex justify-center gap-2"
          >
            <div class="font-bold">{{ $t("settings.phone_number") }}:</div>
            <div>{{ settings.phone_number }}</div>
          </div>
          <div
            v-if="elements.status_url.visible"
            class="w-full flex justify-center gap-2"
          >
            <div class="font-bold">{{ $t("settings.status_url") }}:</div>
            <div>{{ settings.status_url }}</div>
          </div>
        </div>
        <div
          class="grid grid-cols-3 md:grid-cols-5 w-full bg-blue-200 rounded-3xl p-4 mt-2"
          style="resize: vertical; overflow: scroll"
        >
          <PrintCardsDesigner
            :element="elements.username"
            :label="$t('cards.username')"
            :designer="elements.designer"
            @toggle="elements.username.visible = !elements.username.visible"
          />
          <PrintCardsDesigner
            :element="elements.password"
            :label="$t('cards.password')"
            :designer="elements.designer"
            @toggle="elements.password.visible = !elements.password.visible"
          />

          <PrintCardsDesigner
            :element="elements.network"
            :label="$t('batches.network')"
            :designer="elements.designer"
            @toggle="elements.network.visible = !elements.network.visible"
          />
          <PrintCardsDesigner
            :element="elements.price"
            :label="$t('batches.price')"
            :designer="elements.designer"
            @toggle="
              elements.price.visible = !elements.price.visible;
              elements.price.visible ? elements.count++ : elements.count--;
            "
          />
          <PrintCardsDesigner
            :element="elements.speed"
            :label="$t('batches.speed')"
            :designer="elements.designer"
            @toggle="
              elements.speed.visible = !elements.speed.visible;
              elements.speed.visible ? elements.count++ : elements.count--;
            "
          />
          <PrintCardsDesigner
            :element="elements.expiration"
            :label="$t('batches.duration')"
            :designer="elements.designer"
            @toggle="
              elements.expiration.visible = !elements.expiration.visible;
              elements.expiration.visible ? elements.count++ : elements.count--;
            "
          />
          <PrintCardsDesigner
            :element="elements.quota"
            :label="$t('batches.quota')"
            :designer="elements.designer"
            @toggle="
              elements.quota.visible = !elements.quota.visible;
              elements.quota.visible ? elements.count++ : elements.count--;
            "
          />
          <PrintCardsDesigner
            :element="elements.profile"
            :label="$t('batches.profile')"
            :designer="elements.designer"
            @toggle="
              elements.profile.visible = !elements.profile.visible;
              elements.profile.visible ? elements.count++ : elements.count--;
            "
          />
          <PrintCardsDesigner
            :element="elements.sessions"
            :label="$t('batches.sessions')"
            :designer="elements.designer"
            @toggle="
              elements.sessions.visible = !elements.sessions.visible;
              elements.sessions.visible ? elements.count++ : elements.count--;
            "
          />
          <PrintCardsDesigner
            :element="elements.logo"
            :label="$t('batches.logo')"
            :designer="elements.designer"
            @toggle="elements.logo.visible = !elements.logo.visible"
          />
          <PrintCardsDesigner
            :element="elements.phone_number"
            :label="$t('settings.phone_number')"
            :designer="elements.designer"
            @toggle="
              elements.phone_number.visible = !elements.phone_number.visible
            "
          />
          <PrintCardsDesigner
            :element="elements.status_url"
            :label="$t('settings.status_url')"
            :designer="elements.designer"
            @toggle="elements.status_url.visible = !elements.status_url.visible"
          />
          <PrintCardsDesignerQR
            v-if="elements.qr_code"
            :element="elements.qr_code"
            :label="'QR Code'"
            :designer="elements.designer"
            @toggle="elements.qr_code.visible = !elements.qr_code.visible"
          />

          <div class="col-span-3 md:col-span-5">
            <div class="flex flex-col items-center">
              <label class="font-bold">{{
                $t("batches.cards_count_row")
              }}</label>
              <numerical-spinner
                :value="cards_count"
                @increase="
                  cards_count < 3
                    ? (cards_count = cards_count + 1)
                    : (cards_count = 3);
                  fetchCard();
                "
                @decrease="
                  cards_count =
                    cards_count > 1 ? cards_count - 1 : (cards_count = 1);
                  fetchCard();
                "
              />
            </div>
            <div
              v-if="elements.designer == 'advanced'"
              class="flex flex-col justify-center items-center font-bold mt-4"
            >
              <label
                for="image"
                class="cursor-pointer bg-ssr-side_bar-button text-white rounded-xl flex items-center px-4 py-1"
              >
                <div class="mx-1" v-if="cards_count == 1">1000px X 291px</div>
                <div class="mx-1" v-if="cards_count == 2">750px X 291px</div>
                <div class="mx-1" v-if="cards_count == 3">500px X 291px</div>
                <div>{{ $t("batches.add_background") }}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-9 w-9"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </label>
              <input
                id="image"
                type="file"
                accept="image/*"
                ref="imageFile"
                @change="selectBackground"
                class="hidden"
              />
            </div>
          </div>
          <div class="flex justify-end col-span-3 md:col-span-5">
            {{ $t("batches.resize") }}
          </div>
        </div>
        <div
          v-if="elements.designer == 'basic'"
          class="flex justify-center items-center w-full bg-blue-200 rounded-3xl p-4 mt-2 space-x-4"
        >
          <div class="flex flex-col justify-center items-center font-bold">
            <color-input v-model="elements.colors.border" />
            <div>{{ $t("batches.color_border") }}</div>
          </div>
          <div class="flex flex-col justify-center items-center font-bold">
            <color-input v-model="elements.colors.background" />
            <div>{{ $t("batches.color_background") }}</div>
          </div>

          <div class="flex flex-col justify-center items-center font-bold">
            <color-input v-model="elements.colors.text_big" />
            <div>{{ $t("batches.color_text_big") }}</div>
          </div>
          <div class="flex flex-col justify-center items-center font-bold">
            <color-input v-model="elements.colors.input" />
            <div>{{ $t("batches.color_input") }}</div>
          </div>
          <div class="flex flex-col justify-center items-center font-bold">
            <color-input v-model="elements.colors.boxes" />
            <div>{{ $t("batches.color_boxes") }}</div>
          </div>
          <div class="flex flex-col justify-center items-center font-bold">
            <color-input v-model="elements.colors.text_small" />
            <div>{{ $t("batches.color_text_small") }}</div>
          </div>
        </div>

        <div class="flex flex-col items-center my-4">
          <!-- <div class="flex w-full mt-2">
            <div
              class="flex justify-center items-center w-1/2 bg-blue-200 rounded-3xl p-4 mr-1"
            >
              <div>colors</div>
            </div>
            <div
              class="flex justify-center items-center w-1/2 bg-blue-200 rounded-3xl p-4 ml-1"
            >
              <div>style</div>
            </div>
          </div> -->

          <!-- @click.prevent="this.$refs.vue3SimpleHtml2pdf.download()" -->

          <button
            @click="print_element"
            class="bg-ssr-side_bar-button text-white rounded-full px-4 py-2 mt-4 flex items-center"
          >
            {{ $t("batches.print") }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
          </button>
          <button
            v-if="!saving"
            class="bg-ssr-side_bar-button text-white rounded-full px-4 py-2 mt-4 flex items-center"
            @click="saving = true"
          >
            {{ $t("batches.save") }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
              />
            </svg>
          </button>
          <div v-if="saving" class="w-full">
            <label class="font-bold mt-6">{{
              $t("batches.design_name")
            }}</label>
            <input
              v-model="design_name"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              type="text"
            />
          </div>

          <button
            v-if="saving"
            class="bg-ssr-side_bar-settings text-white rounded-full w-full px-4 py-1 mt-4 flex items-center justify-center"
            @click="store"
            :class="{ disabled: design_name.length < 1 }"
          >
            {{ $t("batches.save") }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-9 w-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
              />
            </svg>
          </button>

          <div class="flex items-center mt-4">
            <p class="font-bold">
              {{ $t("batches.cards_count") + "= " + cards_length }}
            </p>
          </div>
        </div>

        <div id="print_page">
          <div class="noScreen">
            <div>
              <div v-for="cards in cards_chunks" v-bind:key="cards[0]">
                <div
                  class="grid gap-y-2 gap-x-1 text-center"
                  style="page-break-after: always"
                  :class="{
                    'grid-cols-1': cards_count == 1,
                    'grid-cols-2': cards_count == 2,
                    'grid-cols-3': cards_count == 3,
                    'grid-cols-4': cards_count == 4,
                  }"
                >
                  <div
                    v-if="elements.designer == 'advanced'"
                    v-for="card in cards"
                    v-bind:key="card.id"
                    class="flex flex-col text-sm relative font-sans bg-white"
                    style="height: 3.85cm; font-size: 9pt"
                    :style="`background-image: url(${this.background});background-repeat: no-repeat;background-position: center;background-size: cover;`"
                  >
                    <div
                      v-if="elements.username.visible"
                      class="absolute"
                      :style="`top: ${elements.username.y}%;left: ${elements.username.x}%; font-size: ${elements.username.size}px; color: ${elements.username.color}`"
                    >
                      {{ card.username }}
                    </div>
                    <div
                      v-if="elements.password.visible"
                      class="absolute"
                      :style="`top: ${elements.password.y}%;left: ${elements.password.x}%; font-size: ${elements.password.size}px; color: ${elements.password.color}`"
                    >
                      {{ card.password }}
                    </div>
                    <div
                      v-if="elements.network.visible"
                      class="absolute"
                      :style="`top: ${elements.network.y}%;left: ${elements.network.x}%; font-size: ${elements.network.size}px; color: ${elements.network.color}`"
                    >
                      {{ settings.network_name }}
                    </div>
                    <img
                      v-if="elements.logo.visible"
                      :style="`top: ${elements.logo.y}%;left: ${elements.logo.x}%; height:${elements.logo.size}mm`"
                      :src="server_url + settings.network_logo"
                      alt=""
                      class="absolute mb-1"
                    />
                    <div
                      v-if="elements.price.visible"
                      class="absolute"
                      :style="`top: ${elements.price.y}%;left: ${elements.price.x}%; font-size: ${elements.price.size}px; color: ${elements.price.color}`"
                    >
                      {{ card.batch.price + " " + settings.currency }}
                    </div>
                    <div
                      v-if="elements.speed.visible"
                      class="absolute"
                      :style="`top: ${elements.speed.y}%;left: ${elements.speed.x}%; font-size: ${elements.speed.size}px; color: ${elements.speed.color}`"
                    >
                      {{ calculateSpeed(card.profile.download_rate_kb) }}
                    </div>
                    <div
                      v-if="elements.expiration.visible"
                      class="absolute"
                      :style="`top: ${elements.expiration.y}%;left: ${elements.expiration.x}%; font-size: ${elements.expiration.size}px; color: ${elements.expiration.color}`"
                    >
                      {{
                        card.profile.expiration_value +
                        calculateUnit(card.profile.expiration_unit)
                      }}
                    </div>
                    <div
                      v-if="elements.quota.visible"
                      class="absolute"
                      :style="`top: ${elements.quota.y}%;left: ${elements.quota.x}%; font-size: ${elements.quota.size}px; color: ${elements.quota.color}`"
                    >
                      {{ calculateQuota(card.profile.traffic_limit_value_mb) }}
                    </div>
                    <div
                      v-if="elements.profile.visible"
                      class="absolute"
                      :style="`top: ${elements.profile.y}%;left: ${elements.profile.x}%; font-size: ${elements.profile.size}px; color: ${elements.profile.color}`"
                    >
                      {{ card.profile.name }}
                    </div>
                    <div
                      v-if="elements.sessions.visible"
                      class="absolute"
                      :style="`top: ${elements.sessions.y}%;left: ${elements.sessions.x}%; font-size: ${elements.sessions.size}px; color: ${elements.sessions.color}`"
                    >
                      {{ card.allowed_sessions }}
                    </div>
                    <div
                      v-if="elements.phone_number.visible"
                      class="absolute"
                      :style="`top: ${elements.phone_number.y}%;left: ${elements.phone_number.x}%; font-size: ${elements.phone_number.size}px; color: ${elements.phone_number.color}`"
                    >
                      {{ settings.phone_number }}
                    </div>
                    <div
                      v-if="elements.status_url.visible"
                      class="absolute"
                      :style="`top: ${elements.status_url.y}%;left: ${elements.status_url.x}%; font-size: ${elements.status_url.size}px; color: ${elements.status_url.color}`"
                    >
                      {{ settings.status_url }}
                    </div>
                    <div
                      v-if="elements.qr_code.visible && elements.qr_code"
                      class="absolute"
                      :style="`top: ${elements.qr_code.y}%;left: ${elements.qr_code.x}%; font-size: ${elements.qr_code.size}px;`"
                    >
                      <qrcode-vue
                        :value="`http://${elements.qr_code.url}/login?username=${card.username}&password=${card.password}`"
                        :size="elements.qr_code.size"
                        :render-as="'svg'"
                        level="L"
                      />
                    </div>
                  </div>
                  <div
                    v-if="elements.designer == 'basic'"
                    v-for="card in cards"
                    v-bind:key="card.id"
                    class="flex flex-col justify-center items-center border border-t-4 border-b-4 rounded-3xl text-sm relative"
                    style="height: 3.85cm; font-size: 9pt"
                    :style="`border-color: ${elements.colors.border}; background-color: ${elements.colors.background};background-image: url(${this.background});background-repeat: no-repeat;background-position: center;background-size: cover;`"
                  >
                    <div
                      class="flex w-full justify-between mt-2"
                      :style="`color: ${elements.colors.text_big}`"
                    >
                      <div
                        v-if="elements.qr_code.visible && elements.qr_code"
                        class="flex flex-col w-full justify-center items-center"
                      >
                        <div>
                          <qrcode-vue
                            :value="`http://${elements.qr_code.url}/login?username=${card.username}&password=${card.password}`"
                            :size="60"
                            :render-as="'svg'"
                            level="L"
                          />
                        </div>
                      </div>
                      <div
                        class="flex flex-col w-full justify-center items-center"
                      >
                        <div>
                          {{ $t("cards.username") }}
                        </div>
                        <div class="mt-2">{{ $t("cards.password") }}</div>
                      </div>
                      <div
                        class="flex flex-col w-full justify-center items-center"
                      >
                        <div
                          class="w-full text-center rounded-full border"
                          :style="`background-color: ${elements.colors.input}; border-color: ${elements.colors.border}`"
                        >
                          {{ card.username }}
                        </div>
                        <div
                          class="w-full text-center rounded-full border mt-2"
                          :style="`background-color: ${elements.colors.input}; border-color: ${elements.colors.border}`"
                        >
                          {{ card.password }}
                        </div>
                      </div>
                      <div
                        v-if="elements.logo.visible || elements.network.visible"
                        class="flex flex-col w-full justify-center items-center"
                      >
                        <div v-if="elements.logo.visible">
                          <img
                            :src="server_url + settings.network_logo"
                            alt=""
                            class="h-14 mb-1"
                          />
                        </div>
                        <div v-if="elements.network.visible">
                          {{ settings.network_name }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid w-full gap-x-1 px-2"
                      style="font-size: 6pt"
                      :class="{
                        'grid-cols-1': elements.count == 1,
                        'grid-cols-2': elements.count == 2,
                        'grid-cols-3': elements.count == 3,
                        'grid-cols-4': elements.count >= 4,
                      }"
                      :style="`color: ${elements.colors.text_small}`"
                    >
                      <div
                        v-if="elements.price.visible"
                        class="rounded-full flex items-center justify-center w-full m-1"
                        :style="`background-color: ${elements.colors.boxes}`"
                      >
                        <!-- <div>{{ $t("batches.speed") }}</div> -->
                        <div>
                          {{ card.batch.price + " " + settings.currency }}
                        </div>
                      </div>
                      <div
                        v-if="elements.speed.visible"
                        class="rounded-full flex items-center justify-center w-full m-1"
                        :style="`background-color: ${elements.colors.boxes}`"
                      >
                        <!-- <div>{{ $t("batches.speed") }}</div> -->
                        <div>
                          {{ calculateSpeed(card.profile.download_rate_kb) }}
                        </div>
                      </div>
                      <div
                        v-if="elements.expiration.visible"
                        class="rounded-full flex items-center justify-center w-full m-1"
                        :style="`background-color: ${elements.colors.boxes}`"
                      >
                        <!-- <div>{{ $t("batches.duration") }}</div> -->
                        <div>
                          {{
                            card.profile.expiration_value +
                            calculateUnit(card.profile.expiration_unit)
                          }}
                        </div>
                      </div>
                      <div
                        v-if="elements.quota.visible"
                        class="rounded-full flex items-center justify-center w-full m-1"
                        :style="`background-color: ${elements.colors.boxes}`"
                      >
                        <!-- <div>{{ $t("batches.quota") }}</div> -->
                        <div>
                          {{
                            calculateQuota(card.profile.traffic_limit_value_mb)
                          }}
                        </div>
                      </div>
                      <div
                        v-if="elements.profile.visible"
                        class="rounded-full flex items-center justify-center w-full m-1"
                        :style="`background-color: ${elements.colors.boxes}`"
                      >
                        <!-- <div>{{ $t("batches.quota") }}</div> -->
                        <div>
                          {{ card.profile.name }}
                        </div>
                      </div>
                      <div
                        v-if="elements.sessions.visible"
                        class="rounded-full flex items-center justify-center w-full m-1"
                        :style="`background-color: ${elements.colors.boxes}`"
                      >
                        <!-- <div>{{ $t("batches.quota") }}</div> -->
                        <div>
                          {{
                            card.allowed_sessions + " " + $t("control.device")
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="elements.phone_number.visible"
                      class="w-full flex justify-center gap-2"
                    >
                      <div class="font-bold">
                        {{ $t("settings.phone_number") }}:
                      </div>
                      <div>{{ settings.phone_number }}</div>
                    </div>
                    <div
                      v-if="elements.status_url.visible"
                      class="w-full flex justify-center gap-2"
                    >
                      <div class="font-bold">
                        {{ $t("settings.status_url") }}:
                      </div>
                      <div>{{ settings.status_url }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import moment from "moment";
import ExpiredWindow from "../components/ExpiredWindow.vue";
import PrintCardsDesigner from "../components/sub_elements/PrintCardsDesigner.vue";
import PrintCardsDesignerQR from "../components/sub_elements/PrintCardsDesignerQR.vue";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    ExpiredWindow,
    PrintCardsDesigner,
    PrintCardsDesignerQR,
    QrcodeVue,
  },
  name: "PrintCards",
  data() {
    return {
      error: "",
      message: "",
      cards: null,
      cards_chunks: [],
      loading: false,
      profiles: null,
      errors: {},
      validated: true,
      cards_count: 2,
      cards_length: 0,
      horizontial: false,
      speed: false,
      duration: false,
      quota: false,
      price: false,
      profile: false,
      username_password: true,
      sessions: false,
      style: "",
      printing: false,
      default_elements: null,
      elements: {
        designer: "basic",
        count: 0,
        username: {
          visible: true,
          x: 50,
          y: 20,
          size: 12,
          color: "#000000",
        },
        password: {
          visible: true,
          x: 50,
          y: 40,
          size: 12,
          color: "#000000",
        },
        network: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        logo: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
        },
        price: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        speed: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        expiration: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        quota: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        profile: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        sessions: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        qr_code: {
          visible: false,
          url: "",
          x: 50,
          y: 50,
          size: 100,
          color: "#000000",
        },
        phone_number: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        status_url: {
          visible: false,
          x: 50,
          y: 50,
          size: 12,
          color: "#000000",
        },
        colors: {
          background: "#FFFFFF",
          boxes: "#66cfff",
          input: "#FFFFFF",
          border: "#0000FF",
          text_small: "#000000",
          text_big: "#000000",
        },
      },
      background: null,
      background_file: null,
      designs: null,
      design_id: -1,
      design_name: "",
      saving: false,
      pdfOptions: {
        margin: 5,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 1, dpi: 96, letterRendering: true },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "my-custom-file.pdf",
    };
  },
  computed: {
    ...mapGetters(["settings"]),
  },
  inject: {
    server_url: {
      from: "server_url",
    },
  },
  async created() {
    this.loading = true;
    this.default_elements = this.elements;
    await this.fetchCard();
    this.loading = false;
    this.exportFilename = moment().format("YYYY-MM-DD, h-mma") + ".pdf";
    this.style = "top: 50%;";
    await this.fetchSavedValues();
  },
  inject: {
    server_url: {
      from: "server_url",
    },
  },
  methods: {
    async fetchCard() {
      this.loading = true;
      this.cards = null;
      const response = await axios.get(
        `/cards/${this.$route.params.id}/cards?print=true`
      );
      if (response.status == 200) {
        this.cards = response.data.data;
        this.cards_length = this.cards.length;
        this.cards_chunks = [];
        for (
          let index = 0;
          index < this.cards_length;
          index += 6 * this.cards_count
        ) {
          this.cards_chunks.push(
            this.cards.slice(index, index + 6 * this.cards_count)
          );
        }
        this.loading = false;
      }
    },
    async store() {
      this.loading = true;
      var data = new FormData();
      data.append("id", this.design_id);
      data.append("name", this.design_name);
      data.append("elements", JSON.stringify(this.elements));
      data.append("card_image", this.background_file);
      const response = await axios.post("cards/design", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status == 200) {
        this.$router.push({
          query: { success: "success" },
        });
        this.saving = false;
        this.fetchSavedValues();
      }
      this.loading = false;
    },
    calculateSpeed(speed) {
      return speed > 1023 ? speed / 1024 + " Mb/s" : speed + " Kb/s";
    },
    calculateQuota(quota) {
      return quota > 1023 ? quota / 1024 + " GB" : quota + " MB";
    },
    calculateUnit(unit) {
      var result = "";
      switch (unit) {
        case "months":
          result = this.$t("profiles.unit.months");
          break;
        case "days":
          result = this.$t("profiles.unit.days");
          break;
        case "hours":
          result = this.$t("profiles.unit.hours");
          break;

        default:
          break;
      }
      return result;
    },
    validate() {},
    selectBackground() {
      this.background_file = this.$refs.imageFile.files.item(0);
      this.background = URL.createObjectURL(this.background_file);
    },
    async fetchSavedValues() {
      this.loading = true;
      const response = await axios.get("cards/designs");
      if (response.status == 200) {
        this.designs = response.data.data;
      }

      this.loading = false;
    },
    async selectDesign() {
      this.background_file = null;
      if (this.design_id < 1 || this.design_id == null) {
        this.elements = this.default_elements;
        this.design_name = "";
        this.background = null;
        return;
      }
      this.loading = true;
      var design;
      this.designs.forEach((element) => {
        if (element.id == this.design_id) design = element;
      });
      if (design.image_url)
        this.background = this.server_url + design.image_url;
      else this.background = null;
      var temp_elements = JSON.parse(design.elements);
      if (temp_elements.qr_code == null)
        temp_elements.qr_code = this.elements.qr_code;
      if (temp_elements.phone_number == null)
        temp_elements.phone_number = this.elements.phone_number;
      if (temp_elements.status_url == null)
        temp_elements.status_url = this.elements.status_url;
      this.elements = temp_elements;
      this.design_name = design.name;
      this.loading = false;
    },
    dragElement() {
      var elmnt = document.getElementById("user_element");
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }
      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }
      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
    print_element(){
      // Get HTML to print from element
      const prtHtml = document.getElementById('print_page').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    }
  },
};
</script>
<style scoped>
.times3 {
  height: 145.5px;
  width: 249.4px;
  font-size: 9pt;
}
.times2 {
  height: 145.5px;
  width: 374.17px;
  font-size: 9pt;
}
.times1 {
  height: 145.5px;
  width: 748.2px;
  font-size: 9pt;
}
@media screen {
  .noPrint {
  }
  .noScreen {
    display: none;
  }
}

@media print {
  .noPrint {
    display: none;
  }
  .noScreen {
  }
}
</style>
