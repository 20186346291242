import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    settings: null,
    currentTime: null,
    routeKey: 0,
    expiration_message: false,
    warning_message: {
      visible: false,
      message: "",
    },
    expired: false,
    connected: true,
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    settings(state, settings) {
      state.settings = settings;
    },
    tempAccount(state, tempAccount) {
      state.tempAccount = tempAccount;
    },
    currentTime(state, currentTime) {
      state.currentTime = currentTime;
    },
    changeKey(state) {
      state.routeKey++;
    },
    show_expiration_message(state, value) {
      state.expiration_message = value;
    },
    show_warning_message(state, value) {
      state.warning_message.visible = value;
    },
    set_warning_message(state, value) {
      state.warning_message.message = value;
    },
    set_expired(state, value) {
      state.expired = value;
    },
  },
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    settings(context, settings) {
      context.commit("settings", settings);
    },
    currentTime(context, currentTime) {
      context.commit("currentTime", currentTime);
    },
    changeKey(context) {
      context.commit("routeKey");
    },
    show_expiration_message(context, value) {
      context.commit("show_expiration_message", value);
    },
    show_warning_message(context, value) {
      context.commit("show_warning_message", value);
    },
    set_warning_message(context, value) {
      context.commit("set_warning_message", value);
    },
    set_expired(context, value) {
      context.commit("set_expired", value);
    },
  },
  modules: {},
  getters: {
    user: (state) => {
      return state.user;
    },
    settings: (state) => {
      return state.settings;
    },
    tempAccount: (state) => {
      return state.tempAccount;
    },
    currentTime: (state) => {
      return state.currentTime;
    },
    routeKey: (state) => {
      return state.routeKey;
    },
    expiration_message: (state) => {
      return state.expiration_message;
    },
    warning_message: (state) => {
      return state.warning_message;
    },
    expired: (state) => {
      return state.expired;
    },
  },
});
