<template>
  <button
    class="text-center rounded-full w-full text-sm"
    @click.prevent="this.$emit('toggle')"
  >
    <div
      class="rounded-full bg-gray-400 flex justify-between items-center text-white p-1 cursor-pointer"
      :class="{
        'bg-high-green': value,
        'bg-gray-300': !value,
      }"
    >
      <transition name="movex">
        <div
          class="rounded-full bg-white h-5 w-5 transform "
          style="transition: all 0.5s ease;"
          :class="{ 'translate-x-12 opacity-0': value }"
        ></div>
      </transition>
      <label class="inline-block ml-1 cursor-pointer">{{
        value ? enabled_name : disabled_name
      }}</label>
      <transition name="movex">
        <div
          class="rounded-full bg-white h-4 w-4 transform opacity-0 ml-1"
          style="transition: all 0.25s ease;"
          :class="{ 'transition-opacity opacity-100': value }"
        ></div>
      </transition>
    </div>
  </button>
</template>

<script>
export default {
  name: "ToggleButton",
  props: ["enabled_name", "disabled_name", "value"],
};
</script>
