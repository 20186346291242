<template>
  <div
    class="flex flex-col rounded-3xl border-2 text-high-dashboard-blue-users shadow-dashboard justify-center items-center"
    :style="this.style"
  >
    <div
      class="flex w-full justify-start items-center bg-white rounded-t-3xl pt-6 md:pt-2"
    >
      <div class="mx-2 hidden md:block">
        {{ this.title }}
      </div>
    </div>
    <div class="flex w-full justify-center items-center bg-white">
      <div class="flex flex-col flex-1 justify-center items-center">
        <div class=" text-3xl font-bold">
          {{ this.value == -1 ? $t("control.fetch_failed") : this.value }}
        </div>
        <div class="text-xs">
          {{ this.details }}
        </div>
      </div>
      <div class="flex flex-col flex-1 justify-center items-center">
        <slot></slot>
      </div>
    </div>
    <div
      class="text-center text-white w-full rounded-b-3xl py-2"
      :class="{ 'bg-white mb-1': only_values }"
    >
      {{ $t("dashboard.statistics.more") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardCard",
  props: ["title", "details", "color", "value", "only_values"],
  computed: {
    style() {
      return `border-color: ${this.color}; background-color:${this.color}`;
    },
  },
};
</script>
