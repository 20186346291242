<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <LoadingOverlay :visible="loading" :message="message" />
    <WarnningConfirm
      :visible="showResetDB"
      :message="$t('settings.reset.reset_db_confirm')"
      @hide="showResetDB = false"
      @confirmed="dbReset()"
    />
    <WarnningMessage
      :visible="showMessage"
      :message="message"
      @hide="showMessage = false"
    />
    <div
      v-if="hasPrivilege('settings_reset')"
      class="flex justify-center w-full"
    >
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col items-center mt-12 justify-start">
          <div class="flex justify-center">
            <div class="flex-col items-center">
              <div class="w-full text-center text-2xl">
                {{ $t("settings.reset.reset_db_info") }}
              </div>
              <button
                class="
              mt-6
              rounded-full
              bg-ssr-side_bar-button_hover
              text-base
              text-white
              p-2
              w-full
              px-12
            "
                @click.prevent="showResetDB = true"
              >
                {{ $t("settings.reset.reset") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import WarnningMessage from "../components/modals/WarnningMessage.vue";
import axios from "axios";
export default {
  name: "Reset",
  components: { LoadingOverlay, WarnningConfirm, WarnningMessage },
  data() {
    return {
      loading: false,
      message: "",
      showResetDB: false,
      showMessage: false,
    };
  },
  computed: {
    ...mapGetters(["user", "settings", "expired"]),
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async dbReset() {
      this.showResetDB = false;
      this.loading = true;
      this.message = this.$t("settings.reset.resetting_db");
      try {
        const response = await axios.post("settings/database/reset");
        if (response.status == 200) {
          this.loading = false;
          window.location.reload();
        } else if (response.status == 210) {
          this.loading = false;
          this.message = this.$t("errors.license.privileges");
          this.showMessage = true;
        }
      } catch (error) {
        this.message = error;
      }
    },
  },
};
</script>
