<template>
  <div
    class="bg-gradient-to-b border-b-4 rounded-lg shadow-xl p-5"
    :class="[fromColor, toColor, borderColor]"
  >
    <!-- 200 600 border -->
    <div class="flex flex-row items-center">
      <div class="flex-shrink pr-4">
        <div class="rounded-full p-5 bg-pink-600">
          <i class="fas fa-users fa-2x fa-inverse"></i>
        </div>
      </div>
      <div class="flex-1 text-right md:text-center">
        <h5 class="font-bold uppercase text-gray-600">
          {{ name }}
        </h5>
        <h3 class="font-bold text-3xl">
          {{ value }}
          <span class="text-pink-500"
            ><i class="fas " :class="[fasIcon]"></i
          ></span>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PillDefault",
  props: ["name", "value", "fromColor", "toColor", "borderColor", "fasIcon"],
};
</script>
