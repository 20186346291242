<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-if="!loading"
        class="
          inline-block
          bg-gray-300
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          align-middle max-w-5xl w-full
        "
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              تحديث انتهاء الحسابات
            </div>

            <div
              @click.prevent="hide"
              class="
                rounded-full
                cursor-pointer
                bg-ssr-side_bar-button_hover
                text-white text-xs
                my-2
                mr-6
                w-28
                flex
                justify-center
                items-center
                font-bold
              "
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="
              flex flex-col
              w-full
              md:w-1/3
              items-center
              justify-center
              bg-gray-300
            "
          >
            <Datepicker
              v-if="this.user.data.type != 'reseller'"
              class="mt-6 w-full"
              v-model="date"
              :format="format"
              :enableTimePicker="false"
              autoApply
              :disabled="this.user.data.type == 'reseller'"
              :locale="$i18n.locale"
            ></Datepicker>
            <Datepicker
              class="mt-6 w-full"
              v-model="time"
              :startTime="startTime"
              :timePicker="true"
              autoApply
              :disabled="this.user.data.type == 'reseller'"
              :locale="$i18n.locale"
            ></Datepicker>
            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              class="bg-green-500 text-white rounded-full w-full py-1 px-3 my-6"
              :class="{ 'bg-opacity-50 cursor-default': !validated }"
              :disabled="!validated"
            >
              {{ $t("profiles.renew.renew") }}
            </button>
          </div>
          <div
            v-if="confirmation"
            class="
              grid grid-cols-2
              my-2
              gap-x-2
              w-full
              md:w-1/3
              items-center
              justify-center
              bg-gray-300
              text-white
            "
          >
            <div class="col-span-2 flex justify-center text-black text-center">
              هل انت متأكد من تحديث انتهاء جميع الحسابات المحددة للتاريخ الجديد
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="
          inline-block
          align-bottom
          bg-gray-300
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          sm:my-8 sm:align-middle
        "
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: {
    NumericalSpinner,
    Datepicker,
    ExpiredWindow,
    ToggleButton,
  },
  name: "RenewProfile",
  props: ["visible", "selectedAccount"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      renew_amount: 1,
      profiles: null,
      date: {
        day: 0,
        month: 0,
        year: 0,
        hour: 0,
        minute: 0,
      },
      time: {
        hours: 0,
        minutes: 0,
      },
      startTime: {
        hours: 0,
        minutes: 0,
      },
      in_debt: false,
      paid: 0,
      discount: 0,
      profile_price: 0,
      previous_quota: false,
      confirmation: false,
      loading: true,
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year}`;
      },
      validated: true,
      profile: null,
    };
  },
  async created() {
    this.loading = false;
    this.date = moment().add(1, "month");
    this.validate();
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    updateExpiration() {
      var profile = this.profiles.find(
        (e) => e.id == this.selectedAccount.profile.id
      );
      if (moment(this.selectedAccount.expires_at) > moment()) {
        this.date = moment(this.selectedAccount.expires_at).add(
          profile.expiration_value * this.renew_amount,
          profile.expiration_unit
        );
        if (this.profile.expiration_unit != "hours") {
          this.date.hours(this.settings.default_expiration_hour ?? 12);
        } else {
          // this.date.hours(
          //   this.date.hour + profile.expiration_value * this.renew_amount
          // );
        }
        this.date.minutes(0);
      } else {
        this.date = moment().add(
          profile.expiration_value * this.renew_amount,
          profile.expiration_unit
        );
        if (this.profile.expiration_unit != "hours") {
          this.date.hours(this.settings.default_expiration_hour ?? 12);
        } else {
          // this.date.hours(
          //   this.date.hour + profile.expiration_value * this.renew_amount
          // );
        }
        this.date.minutes(0);
      }
      this.paid = this.hasPrivilege("accounting_change_price")
        ? profile.price
        : profile.price * this.renew_amount;
      this.profile = profile;
      this.profile_price = this.profile.price * this.renew_amount;
    },
    updateProfile() {
      this.temp_profile = this.profiles.find(
        (e) => e.id == this.selectedAccount.profile.id
      );
    },
    async store() {
      this.loading = true;
      this.date = moment(this.date);
      this.date.hours(this.time.hours);
      this.date.minutes(this.time.minutes);
      // console.log(moment(this.date).format("yyyy-MM-DD HH:mm"));
      // return;
      const response = await axios.post(`accounts/group/expiration`, {
        expires_at: moment(this.date).format("yyyy-MM-DD HH:mm"),
        accounts: this.selectedAccount,
      });
      if (response.status == 200) {
        const userResponse = await axios.get("user");
        this.$store.dispatch("user", userResponse.data);
        this.$router.push({
          path: "/accounts",
          query: { success: "success" },
        });
        this.$emit("updated");
        this.loading = false;
      }
      this.loading = false;
    },
    async fetchProfiles() {
      const response = await axios.get(
        "/profiles?type=subscribtion&rows=1000000&itr=true"
      );
      if (response.status == 200) this.profiles = response.data.data;
    },
    validate() {
      this.validated = true;
      if (this.date == null) {
        this.validated = false;
      }
    },
  },
};
</script>
