<template>
  <div id="chart-container">
    <canvas id="login_chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "LoginsChart",
  props: ["x_data", "y_data", "title"],
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // const ctx = this.$refs[1];
    const ctx = document.getElementById("login_chart");
    const context = ctx.getContext("2d");
    const labels = this.x_data;
    var gradient = context.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0.2, "rgb(255, 10, 86,0.8)");
    gradient.addColorStop(1, "rgb(255, 10, 86,0.2)");
    const data = {
      labels: labels,
      datasets: [
        {
          label: this.title,
          backgroundColor: gradient,
          borderColor: "rgb(255, 99, 132)",
          fill: true,
          data: this.y_data,
          borderRadius: Number.MAX_VALUE,
          borderWidth: 0,
          barPercentage: 0.6,
        },
      ],
    };
    const config = {
      type: "line",
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#e74b75",
              backgroundColor: "#e74b75",
              font: {
                size: 14,
                weight: "bold",
              },
            },
          },
          y: {
            title: "login",
            grid: {
              display: true,
            },
            ticks: {
              stepSize: 25,
              color: "#e74b75",
              backgroundColor: "#e74b75",
              font: {
                size: 14,
                weight: "bold",
              },
            },
          },
        },
      },
    };
    if (this.chart != null) {
      this.chart.destroy();
    }
    try {
      this.chart = new Chart(ctx, config);
    } catch (error) {}
  },
};
</script>
