<template>
  <div
    class="p-5 text-white flex justify-around rounded-3xl"
    style="background-color: #384167"
  >
    <div
      class="felx flex-col justify-center text-center border-r border-white flex-1"
    >
      <svg
        class="h-9 inline-block"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.4167 43.75V39.5833C35.4167 37.3732 34.5387 35.2536 32.9759 33.6908C31.4131 32.128 29.2935 31.25 27.0833 31.25H10.4167C8.20652 31.25 6.08691 32.128 4.52411 33.6908C2.9613 35.2536 2.08333 37.3732 2.08333 39.5833V43.75"
          fill="white"
        />
        <path
          d="M18.75 22.9167C23.3524 22.9167 27.0833 19.1857 27.0833 14.5833C27.0833 9.98096 23.3524 6.25 18.75 6.25C14.1476 6.25 10.4167 9.98096 10.4167 14.5833C10.4167 19.1857 14.1476 22.9167 18.75 22.9167Z"
          fill="white"
        />
        <path
          d="M44.7304 43.75V39.5833C44.729 37.7369 44.1145 35.9433 42.9832 34.484C41.852 33.0247 40.2681 31.9824 38.4804 31.5208"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.1471 6.52083C31.9396 6.97979 33.5284 8.02229 34.663 9.48398C35.7976 10.9457 36.4134 12.7434 36.4134 14.5938C36.4134 16.4441 35.7976 18.2418 34.663 19.7035C33.5284 21.1652 31.9396 22.2077 30.1471 22.6667"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="text-lg font-bold text-center">{{ total }}</div>
      <div class="text-sm font-light text-center">Users</div>
    </div>
    <div class="flex flex-col justify-between flex-1 px-9 text-black">
      <div
        class="text-lg font-bold text-center bg-green-400 rounded-full py-1 px-3"
      >
        <p class="inline-block mr-1">{{ active }}</p>
        <p class="text-xs font-light inline-block">Active</p>
      </div>
      <div
        class="text-lg font-bold text-center bg-red-400 rounded-full py-1 px-3"
      >
        <p class="inline-block mr-1">{{ expired }}</p>
        <p class="text-xs font-light inline-block">Expired</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersPill",
  props: ["active", "expired", "total"],
};
</script>
