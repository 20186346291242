<template>
  <div class="flex flex-col md:flex-row">
    <div class="flex flex-col flex-1 items-center p-12 md:p-0 md:pl-12">
      <div class="flex flex-col w-full md:w-1/2">
        <label class="font-bold">First Name</label>
        <input
          v-model="this.$store.state.tempAccount.first_name"
          type="text"
          class="bg-white rounded-full shadow-lg p-1"
        />
      </div>
      <div class="flex flex-col w-full md:w-1/2">
        <label class="font-bold">Last Name</label>
        <input
          v-model="this.$store.state.tempAccount.last_name"
          type="text"
          class="bg-white rounded-full shadow-lg p-1"
        />
      </div>
      <div class="flex flex-col w-full md:w-1/2">
        <label class="font-bold">Email</label>
        <input
          v-model="this.$store.state.tempAccount.email"
          type="text"
          class="bg-white rounded-full shadow-lg p-1"
        />
      </div>
      <div class="flex flex-col w-full md:w-1/2">
        <label class="font-bold">Phone</label>
        <input
          v-model="this.$store.state.tempAccount.phone"
          type="text"
          class="bg-white rounded-full shadow-lg p-1"
        />
      </div>
    </div>
    <div class="flex flex-col flex-1 items-center md:items-start p-12 md:p-0">
      <div class="flex flex-col w-full md:w-1/2">
        <label class="font-bold">Username</label>
        <input
          v-model="this.$store.state.tempAccount.username"
          type="text"
          class="bg-white rounded-full shadow-lg p-1"
        />
      </div>
      <div class="flex flex-col w-full md:w-1/2">
        <label class="font-bold">Password</label>
        <input
          v-model="this.$store.state.tempAccount.password"
          type="text"
          class="bg-white rounded-full shadow-lg p-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import InputField from "../formElements/InputField.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: { InputField, ToggleButton },
  data() {
    return {};
  },
  name: "Page1",
};
</script>
