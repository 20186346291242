<template>
  <div class="donut-chart transform rotate-90">
    <svg viewBox="0 0 32 32" class="shadow-lg">
      <circle r="16" cx="16" cy="16" :style="rotate" class="shadow-lg" />
    </svg>
    <!-- <div class="donut-center"></div> -->
  </div>
  <!-- <div class="pie h-12 w-12"></div> -->
</template>

<script>
export default {
  name: "PieChart",
  props: ["value", "color"],
  computed: {
    rotate() {
      return `stroke-dasharray: ${this.value} 100; stroke: #EE4545`;
    },
  },
};
</script>

<style lang="scss" scoped>
$base-color: linear-gradient(231.1deg, #009bff 34.34%, #333b60 83.66%);
.pie {
  background-image: conic-gradient(rgb(0, 255, 0) 180deg, white 180deg);
  border-radius: 50%;
}
.donut-chart {
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    background: rgb(255, 255, 255);
    border-radius: 100%;
  }
  circle {
    fill: #00ff75;
    stroke-width: 32;

    border-radius: 20px;
    stroke-dasharray: 0 100;
    transition: stroke-dasharray 0.5s ease;
  }
}
.donut-center {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  height: 75%;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-value {
  position: absolute;
  height: 80%;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
