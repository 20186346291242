<template>
  <div id="clock" class="font-normal">
    <p class="date">{{ date }}</p>
    <p class="time">{{ time }}</p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Clock",
  data() {
    return {
      date: "",
      time: "",
      week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      cd: new Date(),
    };
  },
  async created() {
    try {
      const response = await axios.get("clock");
      if (response.status == 200) {
        this.cd = new Date(response.data);
      }
    } catch (error) {
      this.cd = new Date();
    }
    var timerID = setInterval(this.updateTime, 1000);
    this.updateTime();
  },
  methods: {
    updateTime() {
      this.cd.setSeconds(this.cd.getSeconds() + 1);
      this.time =
        this.zeroPadding(this.cd.getHours(), 2) +
        ":" +
        this.zeroPadding(this.cd.getMinutes(), 2) +
        ":" +
        this.zeroPadding(this.cd.getSeconds(), 2);
      this.date =
        this.zeroPadding(this.cd.getFullYear(), 4) +
        "-" +
        this.zeroPadding(this.cd.getMonth() + 1, 2) +
        "-" +
        this.zeroPadding(this.cd.getDate(), 2) +
        " " +
        this.week[this.cd.getDay()];
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
  },
};
</script>
