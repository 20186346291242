<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <loading-overlay :loading="loading_overlay" />
    <!-- <transition name="fade">
      <profile-details
        v-if="selectedData"
        :visible="showProfileDetails"
        :selectedProfile="
          selectedData.account
            ? selectedData.account.profile
            : selectedData.card.profile
        "
        @hide-details="showProfileDetails = false"
      />
    </transition> -->
    <transition name="fade">
      <card-details
        v-if="showCardDetails"
        :visible="showCardDetails"
        :selectedCard="selectedData"
        @hide-details="showCardDetails = false"
        @updated="
          showDetails = false;
          applyFilters();
        "
      />
    </transition>
    <transition name="fade">
      <account-details
        v-if="showAccountDetails"
        :visible="showAccountDetails"
        :selectedAccount="selectedData"
        @updated="
          showAccountDetails = false;
          applyFilters();
        "
        @renew="
          showAccountDetails = false;
          showRenew = true;
        "
        @delete="
          showAccountDetails = false;
          showDelete = true;
        "
        @offline="
          showAccountDetails = false;
          this.$router.push({
            path: '/sessions/online',
            query: { status: 'already_offline' },
          });
        "
        @failed="
          showAccountDetails = false;
          this.$router.push({
            path: '/sessions/online',
            query: { status: 'fail' },
          });
        "
        @hide-details="showAccountDetails = false"
      />
    </transition>
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'online_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>

    <transition name="expandy">
      <tables-context :display="showContext" ref="menu">
        <ul>
          <!-- <li
            @click="showDetails = true"
            class="m-2 pr-8 border-b border-black hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            View
          </li> -->
          <li
            v-if="hasPrivilege('accounts_disconnect_online')"
            class="m-2 border-b border-black hover:bg-gray-200"
            @click="disconnectData(this.selectedData)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ $t("actions.disconnect") }}
          </li>
        </ul>
      </tables-context>
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
        </div>
        <div class="flex">
          <button
            class="rounded-full bg-ssr-side_bar-settings text-xs lg:text-base text-white py-1 lg:py-2 px-4 font-bold mr-4"
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center  overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.name.enabled">
              <div>{{ $t("online.name") }}</div>
              <input
                v-if="hasPrivilege('accounts_view_search_online')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.name"
              />
            </th>
            <th v-if="columns.username.enabled">
              <div class="inline-block">{{ $t("online.username") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.username.sort }"
                @click="sort('username')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('accounts_view_search_online')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.username"
              />
            </th>
            <th v-if="columns.profile.enabled">
              <div>{{ $t("online.profile") }}</div>
              <input
                v-if="hasPrivilege('accounts_view_search_online')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.profile"
              />
            </th>
            <th v-if="columns.mac.enabled">
              <div class="inline-block">{{ $t("online.mac_address") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.mac.sort }"
                @click="sort('callingstationid')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('accounts_view_search_online')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.mac"
              />
            </th>
            <th v-if="columns.ip.enabled">
              <div class="inline-block">{{ $t("online.ip") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.ip.sort }"
                @click="sort('framedipaddress')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('accounts_view_search_online')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.ip"
              />
            </th>
            <th v-if="columns.nas_name.enabled">
              <div class="inline-block">{{ $t("online.nas_name") }}</div>
              <input
                v-if="hasPrivilege('accounts_view_search_online')"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.nas_name"
              />
            </th>
            <th v-if="columns.framed_protocol.enabled">
              <div>{{ $t("profiles.service") }}</div>
              <select
                v-if="hasPrivilege('accounts_view_search_accounts')"
                @change="applyFilters"
                v-model="filters.framed_protocol"
                class="
                  bg-white
                  rounded-full
                  shadow-lg
                  p-1
                  pl-3
                  focus:outline-none
                  text-center
                  w-full
                "
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.any') },
                    { id: 'ppp', name: 'PPP' },
                    { id: 'hotspot', name: 'HotSpot' },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                >
                  {{ ft.name }}
                </option>
              </select>
            </th>

            <th v-if="columns.type.enabled">
              <div>{{ $t("online.type") }}</div>
            </th>
            <th v-if="columns.traffic.enabled">
              <div class="inline-block">{{ $t("online.traffic_used") }}</div>
            </th>
            <th v-if="columns.uptime_seconds.enabled">
              <div class="inline-block">{{ $t("online.uptime") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.uptime_seconds.sort }"
                @click="sort('acctsessiontime')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </th>
          </thead>
          <tbody>
            <!-- @contextmenu.prevent="openContextMenu($event, session)" -->
            <tr
              v-for="(session, index) in dataList"
              v-bind:key="session.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300': session.selected,
              }"
            >
              <td>
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="session.selected"
                  @click="selectData(session)"
                />
              </td>
              <td v-if="columns.name.enabled">
                <div v-if="session.account">
                  {{
                    session.account.first_name + " " + session.account.last_name
                  }}
                </div>
              </td>
              <td
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
                v-if="columns.username.enabled"
              >
                <div
                  v-if="session.account"
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold inline-block"
                  @click.prevent="
                    this.$store.commit('changeKey');
                    this.$router.push(
                      `/accounts?useranme=${session.account.username}`
                    );
                  "
                >
                  {{ session.username }}
                </div>
                <div
                  v-if="session.card"
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold inline-block"
                  @click.prevent="
                    this.$store.commit('changeKey');
                    this.$router.push(
                      `/cards/cards?useranme=${session.card.username}`
                    );
                  "
                >
                  {{ session.username }}
                </div>
              </td>
              <td
                v-if="columns.profile.enabled"
                :class="{
                  disabled: !this.$store.getters.settings.license.active,
                }"
              >
                <div
                  v-if="session.card"
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold inline-block"
                  @click.prevent="showProfileDetails = true"
                >
                  <a :href="`/profiles/${session.card.profile.id}/edit`">
                    {{ session.card.profile.name }}
                  </a>
                </div>
                <div
                  v-if="session.account"
                  class="text-blue-700 cursor-pointer border-b border-blue-700 font-bold inline-block"
                  @click.prevent="showProfileDetails = true"
                >
                  <a :href="`/profiles/${session.account.profile.id}/edit`">
                    {{ session.account.profile.name }}
                  </a>
                </div>
              </td>
              <td v-if="columns.mac.enabled">
                {{ session.mac }}
              </td>
              <td v-if="columns.ip.enabled">
                <a
                  @click="openTab(`http://${session.ip}`)"
                  :href="`http://${session.ip}`"
                  target="_blank"
                  class="underline text-blue-700"
                  >{{ session.ip }}</a
                >
              </td>
              <td v-if="columns.nas_name.enabled">
                {{ session.nas_name }}
              </td>
              <td v-if="columns.framed_protocol.enabled">
                {{ session.framed_protocol }}
              </td>
              <td v-if="columns.type.enabled">
                <div v-if="session.account">{{ $t("accounts.account") }}</div>
                <div v-if="session.card">{{ $t("cards.card") }}</div>
              </td>
              <td v-if="columns.traffic.enabled">
                <div
                  class="inline-block bg-blue-400 text-white rounded-xl px-1"
                >
                  {{
                    session.download_mb > 1023
                      ? parseFloat(session.download_mb / 1024).toFixed(1) +
                        " GB"
                      : parseFloat(session.download_mb).toFixed(1) + " MB"
                  }}
                </div>
                <div class="inline-block bg-red-400 text-white rounded-xl px-1">
                  {{
                    session.upload_mb > 1023
                      ? parseFloat(session.upload_mb / 1024).toFixed(1) + " GB"
                      : parseFloat(session.upload_mb).toFixed(1) + " MB"
                  }}
                </div>
              </td>
              <td v-if="columns.uptime_seconds.enabled">
                <div
                  class="inline-block bg-yellow-300 text-black rounded-xl px-1"
                >
                  {{ calculateTime(session.uptime_seconds) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class=" inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          @click="
            selectedData = selection_array[0];
            showDetails = true;
          "
          class="ml-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('accounts_disconnect_online')"
          class="cursor-pointer group relative"
          @click="
            selectedData = selection_array[0];
            disconnectData(selectedData);
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.disconnect") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="hasPrivilege('accounts_disconnect_online')"
          class="cursor-pointer group relative"
          @click="groupDisconnect"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-400 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.disconnect") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PillDefault from "../components/PillDefault.vue";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import TablesContext from "../components/contexts/TablesContext.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import ProfileDetails from "../components/modals/ProfileDetails.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import AccountDetails from "../components/modals/AccountDetails.vue";
import CardDetails from "../components/modals/CardDetails.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
export default {
  components: {
    PillDefault,
    DeleteConfirm,
    PaginationRow,
    TablesContext,
    ColumnsSelect,
    TableCheckBox,
    ProfileDetails,
    AccountDetails,
    CardDetails,
    LoadingOverlay,
  },
  name: "OnlineSessions",
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showCardDetails: false,
      showAccountDetails: false,
      showProfileDetails: false,
      showDelete: false,
      showContext: false,
      loading: true,
      showFilters: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        username: "",
        name: "",
        profile: "",
        ip: "",
        mac: "",
        nas_name: "",
        type: "",
        traffic: "",
        uptime_seconds: "",
        framed_protocol: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        name: {
          name: this.$t("online.name"),
          enabled: true,
          sort: false,
        },
        username: {
          name: this.$t("online.username"),
          enabled: true,
          sort: false,
        },
        profile: {
          name: this.$t("online.profile"),
          enabled: true,
          sort: false,
        },
        ip: { name: this.$t("online.ip"), enabled: true, sort: false },
        mac: {
          name: this.$t("online.mac_address"),
          enabled: true,
          sort: false,
        },
        nas_name: {
          name: this.$t("online.nas_name"),
          enabled: true,
          sort: false,
        },
        framed_protocol: {
          name: this.$t("profiles.service"),
          enabled: true,
          sort: false,
        },
        type: { name: this.$t("online.type"), enabled: false, sort: false },
        traffic: {
          name: this.$t("online.traffic_used"),
          enabled: true,
          sort: false,
        },
        uptime_seconds: {
          name: this.$t("online.uptime"),
          enabled: true,
          sort: false,
        },
      },
      autoUpdate: false,
      selection_array: [],
      debounce: null,
      loading_overlay: false,
      sort_col: null,
      sort_dir: null,
    };
  },
  async created() {
    this.prepareFilters();
    this.applyFilters();
    this.prepareColumns();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async fetchData() {
      if (this.filters.rows > 0 || this.filters.page > 0) {
        this.fetchDataPortion(this.filters.rows, this.filters.page);
        this.selection_array = [];
        return;
      }
      const response = await axios.get("online");
      this.dataList = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    prepareFilters() {
      var filters = localStorage.getItem("online_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("online_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          name: {
            name: this.$t("online.name"),
            enabled: cols[0].enabled,
            sort: false,
          },
          username: {
            name: this.$t("online.username"),
            enabled: cols[1].enabled,
            sort: false,
          },
          profile: {
            name: this.$t("online.profile"),
            enabled: cols[2].enabled,
            sort: false,
          },
          ip: {
            name: this.$t("online.ip"),
            enabled: cols[3].enabled,
            sort: false,
          },
          mac: {
            name: this.$t("online.mac_address"),
            enabled: cols[4].enabled,
            sort: false,
          },
          nas_name: {
            name: this.$t("online.nas_name"),
            enabled: cols[5].enabled,
            sort: false,
          },
          framed_protocol: {
            name: this.$t("profiles.service"),
            enabled: cols[6].enabled,
            sort: false,
          },
          type: {
            name: this.$t("online.type"),
            enabled: cols[7].enabled,
            sort: false,
          },
          traffic: {
            name: this.$t("online.traffic_used"),
            enabled: cols[8].enabled,
            sort: false,
          },
          uptime_seconds: {
            name: this.$t("online.uptime"),
            enabled: cols[9].enabled,
            sort: false,
          },
        };
      }
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "online?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.username != "")
        requestString += `&username=${this.filters.username}`;
      if (this.filters.name != "")
        requestString += `&name=${this.filters.name}`;
      if (this.filters.profile != "")
        requestString += `&profile=${this.filters.profile}`;
      if (this.filters.mac != "") requestString += `&mac=${this.filters.mac}`;
      if (this.filters.ip != "") requestString += `&ip=${this.filters.ip}`;
      if (this.filters.nas_name != "")
        requestString += `&nas_name=${this.filters.nas_name}`;
      if (this.filters.framed_protocol != "")
        requestString += `&service=${this.filters.framed_protocol}`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = response.data.data;
        if (this.filters.save)
          localStorage.setItem("online_filters", JSON.stringify(this.filters));
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async disconnectData(data) {
      const response = await axios.post(`online/disconnect/${data.id}`);
      if (response.status == 200) {
        this.selection_array = [];
        this.selectedData = null;
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
    },
    async groupDisconnect() {
      this.loading_overlay = true;
      const response = await axios.post(`online/disconnect/group`, {
        sessions: this.selection_array,
      });
      if (response.status == 200) {
        this.selection_array = [];
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    openContextMenu(e, data) {
      this.selectedData = data;
      this.$refs.menu.open(e);
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    calculateTime(seconds) {
      if (seconds < 59) {
        return seconds + " " + this.$t("control.seconds");
      } else if (seconds >= 60 && seconds < 3599) {
        return (
          parseFloat(seconds / 60).toFixed(0) + " " + this.$t("control.minutes")
        );
      } else if (seconds >= 3600) {
        let hours = parseInt(seconds / 3600);
        let hours_seconds = hours * 3600;
        let minutes = parseFloat((seconds - hours_seconds) / 60).toFixed(0);
        if (minutes > 1)
          return (
            this.$t("control.hours") +
            hours +
            " " +
            this.$t("control.minutes") +
            minutes
          );
        else return hours + " " + this.$t("control.hours");
      }
    },
    sort(param) {
      this.sort_col = param;
      if (param == "callingstationid") param = "mac";
      if (param == "acctsessiontime") param = "uptime_seconds";
      if (param == "framedipaddress") param = "ip";
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    sort_traffic() {
      this.sort_col = "download_mb";
      if (!this.columns.traffic.sort) {
        this.sort_dir = "ASC";
        this.dataList.sort((a, b) =>
          a.download_mb + a.upload_mb > b.download_mb + b.upload_mb
            ? 1
            : b.download_mb + b.upload_mb > a.download_mb + a.upload_mb
            ? -1
            : 0
        );
        this.columns.traffic.sort = true;
      } else {
        this.sort_dir = "DESC";
        this.dataList.sort((a, b) =>
          a.download_mb + a.upload_mb < b.download_mb + b.upload_mb
            ? 1
            : b.download_mb + b.upload_mb < a.download_mb + a.upload_mb
            ? -1
            : 0
        );
        this.columns.traffic.sort = false;
      }
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem("online_filters", JSON.stringify(this.filters));
    },
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
