<template>
  <div class="flex flex-col justify-center items-center shadow-dashboard">
    <div
      class=" bg-ssr-side_bar-button_hover text-white font-bold text-center text-xl w-full rounded-t-xl"
    >
      {{ nas.name + " " + nas.board }}
    </div>
    <div
      class="flex rounded-xl justify-around items-center p-3 text-blue-900 w-full"
    >
      <!-- Memory Gauge -->
      <div class="flex flex-col justify-center items-center">
        <div class=" w-24 h-24 relative flex justify-center items-center">
          <pie-chart :value="nas.memory_load" :color="'#EC255A'" />
          <div class="absolute font-bold text-lg">
            {{ parseFloat(nas.memory_load).toFixed(1) }}%
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-9 w-9"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
          />
        </svg>
        <div>{{ $t("dashboard.gauges.memory") }}</div>
      </div>
      <!-- Network Gauge -->
      <div class="flex flex-col justify-center items-center">
        <div class=" w-24 h-24 relative flex justify-center items-center">
          <pie-chart :value="nas.cpu_load" :color="'#EC255A'" />
          <div class="absolute font-bold text-lg">
            {{ parseFloat(nas.cpu_load).toFixed(1) }}%
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-9 w-9"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
          />
        </svg>
        <div>{{ $t("dashboard.gauges.cpu") }}</div>
      </div>
      <!-- Disk Gauge -->
      <div class="flex flex-col justify-center items-center">
        <div class=" w-24 h-24 relative flex justify-center items-center">
          <pie-chart :value="nas.hdd_load" :color="'#EC255A'" />
          <div class="absolute font-bold text-lg">
            {{ parseFloat(nas.hdd_load).toFixed(1) }}%
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-9 w-9"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
          />
        </svg>
        <div>{{ $t("dashboard.gauges.disk") }}</div>
      </div>
    </div>
    <div
      class="flex flex-col justify-center items-center bg-ssr-side_bar-button text-white w-full rounded-br-xl rounded-bl-xl"
    >
      <div class="w-full py-4 overflow-y-auto max-h-48 justify-around flex">
        <div class="flex-col">
          <div class="font-bold">{{ $t("dashboard.gauges.uptime") }}</div>
          <div class="text-center text-2xl text-blue-200">
            {{ nas.sysuptime }}
          </div>
        </div>
        <div class="flex-col">
          <div class="font-bold">{{ $t("dashboard.gauges.voltage") }}</div>
          <div class="text-center text-2xl text-green-200">
            {{ nas.voltage }} <span>v</span>
          </div>
        </div>
        <div class="flex-col">
          <div class="font-bold">{{ $t("dashboard.gauges.cpu_temp") }}</div>
          <div class="text-center text-2xl text-red-200">
            {{ nas.cpu_temp }} <span>c</span>
          </div>
        </div>
        <!-- <div
          class="grid grid-cols-6 text-center justify-around w-full mt-4"
          v-for="link in nas.links"
          v-bind:key="link.name"
        >
          <div class="flex justify-center items-center">
            <img
              src="@/assets/dashboard/ether.png"
              class="w-7 h-7 inline-block"
              alt="Total Users"
            />
          </div>
          <div
            :class="{
              'text-red-500': link.status == 'false',
            }"
          >
            {{ link.name }}
          </div>
          <div v-if="link.status == 'false'" class="col-span-4 text-red-500">
            DOWN
          </div>
          <div v-if="link.status == 'true'" class="col-span-2">
            {{ "Up: " + link.upload + "Mb/s" }}
          </div>
          <div v-if="link.status == 'true'" class="col-span-2">
            {{ "Down: " + link.download + "Mb/s" }}
          </div>
        </div> -->
      </div>
    </div>
    <!-- <div
      class="flex justify-start items-start bg-ssr-side_bar-button_hover text-xl text-white w-full rounded-b-xl"
    >
      <div class="w-1/4 text-center py-1">TOTAL</div>
      <div
        class="w-3/4 text-center py-1 text-white bg-ssr-side_bar-background rounded-br-xl"
      >
        {{
          "Up: " +
            nas.total_upload.toFixed(2) +
            "Mb/s | " +
            "Down: " +
            nas.total_download.toFixed(2) +
            "Mb/s"
        }}
      </div>
    </div> -->
  </div>
</template>

<script>
import PieChart from "./DonutChart.vue";
export default {
  components: { PieChart },
  name: "NasPill",
  props: ["nas"],
};
</script>
