<template>
  <div class="flex flex-col  px-2 py-4 rounded-2xl bg-blue-300 shadow-md m-1">
    <label>{{ label }}</label>
    <toggle-button
      class="bg-red-500"
      :value="element.visible"
      :name="''"
      @toggle="this.$emit('toggle')"
      :disabled_name="$t('control.disabled')"
      :enabled_name="$t('control.enabled')"
    />
    <div class="flex items-center" v-if="designer == 'advanced'">
      <p>U</p>
      <input
        type="range"
        min="0"
        max="90"
        class="appearance-none rounded-full h-2 bg-gray-100 w-full"
        v-model="element.y"
      />
      <p>D</p>
    </div>
    <div class="flex items-center" v-if="designer == 'advanced'">
      <p>L</p>
      <input
        type="range"
        min="0"
        max="90"
        class="appearance-none rounded-full h-2 bg-gray-100 w-full"
        v-model="element.x"
      />
      <p>R</p>
    </div>
    <div class="flex items-center" v-if="designer == 'advanced'">
      <p>S</p>
      <input
        type="range"
        min="6"
        max="46"
        class="appearance-none rounded-full h-2 bg-gray-100 w-full"
        v-model="element.size"
      />
      <p>L</p>
    </div>
    <input
      v-if="element.color"
      type="color"
      class="w-full"
      v-model="element.color"
    />
  </div>
</template>
<script>
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  name: "PrintCardsDesigner",
  props: ["element", "label", "designer"],
  components: { ToggleButton },
};
</script>
