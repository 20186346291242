<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="this.$emit('hide')"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        v-if="!loading"
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
      >
        <div class="flex flex-col justify-center items-center">
          <div class=" bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("notifications.new_notification") }}
            </div>

            <div
              @click.prevent="this.$emit('hide')"
              class="rounded-full cursor-pointer bg-ssr-side_bar-button_hover text-white text-xs my-2 mr-6 w-28 flex justify-center items-center font-bold"
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="flex flex-col w-full md:w-1/3 items-center justify-center bg-gray-300"
            :style="text_direction"
          >
            <label class="font-bold mt-2">{{
              $t("notifications.type.account")
            }}</label>
            <input
              disabled
              v-model="account.username"
              type="text"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none bg-opacity-50 cursor-default text-center"
            />

            <label class="font-bold mt-2">{{
              $t("notifications.title")
            }}</label>
            <input
              v-model="notification.title"
              @input="validate"
              type="text"
              class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              :class="{ 'shadow-error': errors.title }"
            />
            <label class="font-bold mt-2">{{ $t("notifications.body") }}</label>
            <textarea
              class="bg-white rounded-3xl w-full h-24 shadow-lg p-1 pl-3 focus:outline-none"
              @input="validate"
              v-model="notification.body"
              :class="{
                'shadow-error': errors.body,
              }"
              cols="30"
              rows="10"
            ></textarea>
            <div v-if="!validated" class="text-sm text-red-600 my-2">
              * {{ $t("notifications.error") }}
            </div>
            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              :disabled="!validated"
              class="bg-green-500 text-white  rounded-full w-full py-1 px-3 my-6"
              :class="{ 'opacity-50 cursor-default': !validated }"
            >
              {{ $t("notifications.push") }}
            </button>
          </div>
          <div
            v-if="confirmation"
            class="grid grid-cols-2 my-4 gap-x-2 w-full md:w-1/3 items-center justify-center bg-gray-300 text-white"
          >
            <div class="col-span-2 mb-2 flex justify-center text-black">
              {{ $t("notifications.confirm") }}
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="inline-block align-bottom bg-gray-300 rounded-3xl text-left overflow-hidden shadow-xl transform transition-all my-24 sm:my-8 sm:align-middle"
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import MultiSelect from "@vueform/multiselect";
import ExpiredWindow from "../ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    MultiSelect,
    ExpiredWindow,
  },
  name: "PushNotification",
  props: ["visible", "account"],
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      confirmation: false,
      loading: true,
      notification: {
        title: "",
        body: "",
        type: "account",
        account_id: null,
        profile_id: null,
        nas_id: null,
      },
      errors: {
        title: null,
        body: null,
        id: null,
      },
      validated: true,
      text_direction:
        this.$i18n.locale == "ar" ? "direction:rtl" : "direction:ltr",
      int_loading: false,
      debounce: null,
    };
  },
  async created() {
    await this.fetchProfiles();
    this.loading = false;
    this.validate();
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.notification.account_id = this.account.id;
      const response = await axios.post(`/notifications`, this.notification);
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.$emit("updated");
      }
    },
    async fetchProfiles(query) {
      this.int_loading = true;
      const response = await axios.get(`/profiles?name=${query}`);
      if (response.status == 200) {
        if (!this.notification.profile_id) this.profiles = [];
        const profiles = response.data.data;
        profiles.forEach((element) => {
          this.profiles.push({ value: element.id, label: element.name });
        });
      }
      this.int_loading = false;
    },
    async fetchAccounts(query) {
      this.int_loading = true;
      const response = await axios.get(`/accounts?username=${query}`);
      if (response.status == 200) {
        if (!this.notification.account_id) this.accounts = [];
        const accounts = response.data.data;
        accounts.forEach((element) => {
          this.accounts.push({ value: element.id, label: element.username });
        });
      }
      this.int_loading = false;
    },
    async fetchNases(query) {
      this.int_loading = true;
      const response = await axios.get(`/nases?itr=true&shortname=${query}`);
      if (response.status == 200) {
        if (!this.notification.nas_id) this.nases = [];
        const nases = response.data.data;
        nases.forEach((element) => {
          this.nases.push({ value: element.id, label: element.shortname });
        });
      }
      this.int_loading = false;
    },
    changeType() {},
    validate() {
      this.validated = true;
      this.errors.title = null;
      this.errors.body = null;
      if (this.notification.title == "") {
        this.errors.title = true;
        this.validated = false;
      }
      if (this.notification.body == "") {
        this.errors.body = true;
        this.validated = false;
      }
    },
  },
};
</script>
