<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20">
        <div class="flex flex-col justify-center items-center">
          <div class="grid grid-cols-2 gap-6 mt-6 lg:w-1/2">
            <div class="col-span-2">
              <div class="font-bold">{{ $t("nases.name") }}</div>
              <input
                v-model="nas.name"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("nases.connection") }}
            </div>
            <div>
              <div class="font-bold">{{ $t("nases.ip") }}</div>
              <input
                v-model="nas.ip"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>

            <div>
              <div class="font-bold">{{ $t("nases.radius_secret") }}</div>
              <input
                v-model="nas.radius_secret"
                type="text"
                disabled
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none opacity-50"
              />
            </div>
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("nases.login") }}
            </div>
            <div>
              <div class="font-bold">{{ $t("nases.api_username") }}</div>
              <input
                v-model="nas.api_username"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div>
              <div class="font-bold">{{ $t("nases.api_password") }}</div>
              <input
                v-model="nas.api_password"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div>
              <div class="font-bold">{{ $t("nases.api_port") }}</div>
              <input
                v-model="nas.api_port"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("nases.other") }}
            </div>
            <div>
              <div class="font-bold">{{ $t("nases.version") }}</div>
              <select
                v-model="nas.legacy"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="privilege in [
                    { name: 'v3.36 and up', id: '0' },
                    { name: 'v3.34 and down', id: '1' },
                  ]"
                  v-bind:key="privilege.id"
                  :value="privilege.id"
                  >{{ privilege.name }}</option
                >
              </select>
            </div>
            <div>
              <div class="font-bold">{{ $t("nases.snmp_community") }}</div>
              <input
                v-model="nas.snmp_community"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="flex justify-center border-b border-black col-span-2">
              {{ $t("nases.privileges") }}
            </div>
            <div class="col-span-2">
              <div class="font-bold">
                {{ $t("nases.allowed_profiles") }}
              </div>
              <MultiSelect
                v-model="this.nas.allowed_profiles"
                :mode="'tags'"
                :placeholder="'NONE'"
                :options="profiles"
                :groupSelect="true"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2">
              <div class="font-bold">{{ $t("nases.allowed_admins") }}</div>
              <MultiSelect
                v-model="this.nas.allowed_users"
                :mode="'tags'"
                :placeholder="'NONE'"
                :options="users"
                :classes="{
                  container:
                    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white rounded-3xl text-base leading-snug outline-none',
                  tag:
                    'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded rounded-full mr-1 mb-1 flex items-center whitespace-nowrap',
                }"
              />
            </div>
            <div class="col-span-2 text-red-500">{{ error_message }}</div>
            <div class="col-span-2">
              <button
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full hover:bg-white hover:text-green-500 py-1 px-3"
              >
                {{ this.nas.id ? $t("nases.save") : $t("nases.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import axios from "axios";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import MultiSelect from "@vueform/multiselect";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    MultiSelect,
    ExpiredWindow,
  },
  name: "AddNas",
  data() {
    return {
      error: "",
      message: "",
      error_message: "",
      nas: {
        ip: "",
        name: "",
        radius_secret: "ssradius1234",
        api_username: "",
        api_password: "",
        api_port: "8728",
        snmp_community: "public",
        legacy: "0",
        description: "",
        allowed_profiles: [],
        allowed_users: [],
      },
      profiles: [],
      users: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.fetchProfiles();
    await this.fetchUsers();
    if (this.$route.params.id) {
      await this.fetchNas(this.$route.params.id);
    } else {
      var temp = [];
      this.profiles.forEach((element) => {
        temp.push(element.value);
      });
      this.nas.allowed_profiles = temp;
      temp = [];
      this.users.forEach((element) => {
        temp.push(element.value);
      });
      this.nas.allowed_users = temp;
    }
    this.loading = false;
  },
  // watch: {
  //   $route(to, from) {
  //     if (this.$route.params.id == null) {
  //       this.nas = {
  //         ip: "",
  //         name: "",
  //         radius_secret: "",
  //         api_username: "",
  //         api_password: "",
  //         api_port: "8728",
  //         snmp_community: "public",
  //         legacy: "0",
  //         description: "",
  //         allowed_profiles: [],
  //         allowed_users: [],
  //       };
  //       var temp = [];
  //       this.profiles.forEach((element) => {
  //         temp.push(element.value);
  //       });
  //       this.nas.allowed_profiles = temp;
  //       temp = [];
  //       this.users.forEach((element) => {
  //         temp.push(element.value);
  //       });
  //       this.nas.allowed_users = temp;
  //       this.admin.allowed_nases = temp;
  //     }
  //   },
  // },
  methods: {
    async fetchNas(id) {
      const response = await axios.get(`nases/${id}`);
      if (response.status == 200) {
        this.nas = response.data.data;
        var temp = [];
        this.nas.allowed_profiles.forEach((element) => {
          temp.push(element.id);
        });
        this.nas.allowed_profiles = temp;
        temp = [];
        this.nas.allowed_users.forEach((element) => {
          temp.push(element.id);
        });
        this.nas.allowed_users = temp;
      }
    },
    async fetchProfiles() {
      const response = await axios.get("profiles?rows=10000000");
      response.data.data.forEach((element) => {
        this.profiles.push({ value: element.id, label: element.name });
      });
    },
    async fetchUsers() {
      const response = await axios.get("admins?rows=10000000");
      response.data.data.forEach((element) => {
        this.users.push({ value: element.id, label: element.email });
      });
    },
    async store() {
      if (this.nas.id) {
        const response = await axios.put(`/nases/${this.nas.id}`, this.nas);
        if (response.status == 200) {
          this.$router.push({ path: "/nases", query: { success: "success" } });
          this.$store.commit("changeKey");
        }
      } else {
        const response = await axios.post("nases", this.nas);
        if (response.status == 200) {
          this.$router.push({ path: "/nases", query: { success: "success" } });
          this.$store.commit("changeKey");
        } else if (response.status == 210) {
          this.validated = false;
          this.error_message = this.$t("errors.license.license_nases_exceeded");
        }
      }
    },
  },
};
</script>
