<template>
  <div
    class="p-4 text-black flex justify-around rounded-3xl shadow-lg"
    style="background-color: #d8ebf9"
  >
    <div
      class="flex-col justify-center items-center text-center border-r border-gray-600 flex-1 hidden md:flex"
    >
      <div class="text-3xl font-bold text-center w-2/3">System Cpu Usage</div>
    </div>
    <div
      class="flex flex-row justify-center items-center text-center text-black flex-1"
    >
      <div class="box gauge--3 relative z-0">
        <div class="mask">
          <div class="semi-circle"></div>
          <div class="semi-circle--mask" :style="rotate"></div>
        </div>
        <div class="font-bold text-4xl absolute right-1/3 top-1/2  z-10">
          {{ value }}%
        </div>
        <div class="block md:hidden">Cpu</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadPill",
  props: ["value"],
  data() {
    return {
      rotationVal: 90,
    };
  },
  created() {},
  computed: {
    rotate() {
      return "transform: rotate(" + (this.value * 180) / 100 + "deg)";
    },
  },
};
</script>
