<template>
  <div>
    <table class="table-auto w-full">
      <thead class="bg-ssr-side_bar-settings text-white font-bold">
        <tr>
          <th>#</th>
          <th>{{ $t("accounts.username") }}</th>
          <th>{{ $t("profiles.profile") }}</th>
          <th>{{ $t("accounts.expires_at") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(account, index) in accounts"
          :key="account.id"
          class="text-base text-center hover:bg-blue-200 cursor-pointer"
          :class="{
            'bg-gray-200': index % 2 == 0,
          }"
          @click="
            this.$store.commit('changeKey');
            this.$router.push(`/accounts?useranme=${account.username}`);
          "
        >
          <td class="font-bold">
            {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
          </td>
          <td>{{ account.username }}</td>
          <td>{{ account.profile.name }}</td>
          <div
            class="px-3 p-1 my-1 bg-yellow-400 text-black text-sm text-center rounded-full inline-block cursor-pointer"
          >
            {{ formatDate(account.expires_at) }}
          </div>
        </tr>
      </tbody>
    </table>
    <div v-if="loading" class="w-full flex justify-center">
      <img
        class=" inline-block h-12 w-12"
        src="@/assets/loading.gif"
        alt="Loading"
      />
    </div>
    <pagination-row
      class="mt-2"
      @triggerupdate="fetchDataPortion"
      :meta="meta"
      :small="true"
    />
  </div>
</template>
<script>
import axios from "axios";
import PaginationRow from "../PaginationRow.vue";
import moment from "moment";
export default {
  components: { PaginationRow },
  name: "EndingAccountsTable",
  data() {
    return {
      accounts: null,
      meta: { last_page: 0, current_page: 1 },
      filters: {
        rows: 5,
        page: 1,
      },
      loading: false,
    };
  },
  async created() {
    await this.fetchAccounts();
  },
  methods: {
    async fetchAccounts() {
      this.loading = true;
      const response = await axios.get(
        `accounts?rows=${this.filters.rows}&page=${this.filters.page}&active=expiring`
      );
      this.accounts = response.data.data;
      this.meta = response.data.meta;
      this.loading = false;
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = 5;
      this.filters.page = currentPage;
      this.fetchAccounts();
    },
    formatDate(date) {
      return moment(date).format("yyyy-MM-DD hh:mm a");
    },
  },
};
</script>
