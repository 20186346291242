<template>
  <div
    @click.prevent="if (this.$refs.menu != null) this.$refs.menu.close();"
    class="main-content flex-1 bg-gray-100"
  >
    <loading-overlay :loading="loading_overlay" />
    <transition name="fade">
      <columns-select
        :visible="showColumnsSelect"
        :columns="columns"
        :name="'notifications_columns'"
        @hide="showColumnsSelect = false"
        @selectconfirmed="updateColumns(currentColumns)"
      />
    </transition>
    <transition name="fade">
      <push-notification
        :visible="showPushNotification"
        @hide="showPushNotification = false"
        @updated="
          showPushNotification = false;
          applyFilters();
        "
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.notifications.archiveAll')"
        :visible="showArchiveAll"
        @confirmed="groupArchive()"
        @hide="showArchiveAll = false"
      />
    </transition>
    <transition name="fade">
      <warnning-confirm
        :message="$t('messages.notifications.archive')"
        :visible="showArchive"
        @confirmed="archiveData()"
        @hide="showArchive = false"
      />
    </transition>

    <div class="p-2 md:p-6" @click.prevent="showFilters = false">
      <div class="flex justify-between">
        <div class="flex">
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': autoUpdate,
              'bg-gray-700 text-white': !autoUpdate,
            }"
            @click.prevent="toggleAutoUpdate"
          >
            {{
              autoUpdate
                ? "✓ " + $t("accounts.auto_update")
                : $t("accounts.auto_update")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.save,
              'bg-gray-700 text-white': !filters.save,
            }"
            @click.prevent="toggleSaveFilters"
          >
            {{
              autoUpdate
                ? "✓ " + $t("control.save_filters")
                : $t("control.save_filters")
            }}
          </button>
          <button
            class="text-xs lg:text-base rounded-full text-black lg:py-1 px-4 mr-4"
            :class="{
              'bg-blue-300 text-black shadow-blue ': filters.archived,
              'bg-gray-700 text-white': !filters.archived,
            }"
            @click.prevent="
              filters.archived = !filters.archived;
              applyFilters();
            "
          >
            {{
              filters.archived
                ? "✓ " + $t("notifications.show_archived")
                : $t("notifications.show_archived")
            }}
          </button>
        </div>

        <div class="flex">
          <button
            class="rounded-full bg-ssr-side_bar-settings text-xs lg:text-base text-white py-1 lg:py-2 px-4 font-bold"
            @click.prevent="showColumnsSelect = true"
          >
            ...
          </button>
          <button
            v-if="hasPrivilege('notifications_edit_auto_notifications')"
            @click.prevent="this.$router.push('/services/notifications/config')"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white lg:p-2 px-2 mx-4"
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("sidebar.notifications.auto") }}
          </button>
          <button
            v-if="
              hasPrivilege('notifications_edit_manual_notifications') ||
                hasPrivilege('notifications_push_notifications')
            "
            @click.prevent="showPushNotification = true"
            class="rounded-full bg-ssr-side_bar-button_hover text-xs lg:text-base text-white lg:p-2 px-2"
            :class="{
              disabled: !this.$store.getters.settings.license.active,
            }"
          >
            {{ $t("notifications.new_notification") }}
          </button>
        </div>
      </div>
      <div
        class="w-full flex flex-col text-center overflow-auto"
        style="height: 30rem"
      >
        <table>
          <thead class="border-b-2 border-black text-xs md:text-base">
            <th></th>
            <th>
              <table-check-box
                class="w-5 h-5 my-3 mx-4 cursor-pointer"
                :value="allSelected"
                @click="checkAll"
              />
            </th>
            <th v-if="columns.title.enabled">
              <div class="inline-block">{{ $t("notifications.title") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.title.sort }"
                @click="sort('title')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('notifications_view_notifications')"
                type="text"
                class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                v-model="filters.title"
                @input="applyFilters"
              />
            </th>
            <th v-if="columns.body.enabled">
              <div class="inline-block">{{ $t("notifications.body") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.body.sort }"
                @click="sort('body')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('notifications_view_notifications')"
                type="text"
                class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                v-model="filters.body"
                @input="applyFilters"
              />
            </th>
            <th v-if="columns.type.enabled">
              <div>
                <div class="inline-block">
                  {{ $t("notifications.type.type") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                  :class="{ 'rotate-180': columns.type.sort }"
                  @click="sort('type')"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </div>
              <select
                v-if="hasPrivilege('notifications_view_notifications')"
                @change="applyFilters"
                v-model="filters.type"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center"
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.all') },
                    {
                      id: 'account_expiring',
                      name: $t('notifications.type.account_expiring'),
                    },
                    {
                      id: 'account_expired',
                      name: $t('notifications.type.account_expired'),
                    },
                    {
                      id: 'account_renew',
                      name: $t('notifications.type.account_renew'),
                    },
                    { id: 'whatsapp', name: $t('notifications.type.whatsapp') },
                    { id: 'account', name: $t('notifications.type.account') },
                    { id: 'profile', name: $t('notifications.type.profile') },
                    { id: 'nas', name: $t('notifications.type.nas') },
                    { id: 'ssr', name: $t('notifications.type.ssr') },
                    { id: 'global', name: $t('notifications.type.global') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                  >{{ ft.name }}</option
                >
              </select>
            </th>
            <th v-if="columns.receiver.enabled">
              <div class="inline-block">{{ $t("notifications.receiver") }}</div>
              <input
                v-if="hasPrivilege('notifications_view_notifications')"
                type="text"
                class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.receiver"
              />
            </th>
            <th v-if="columns.sender.enabled">
              <div class="inline-block">{{ $t("notifications.sender") }}</div>
              <input
                v-if="hasPrivilege('notifications_view_notifications')"
                type="text"
                class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center"
                :placeholder="$t('control.filter')"
                @input="applyFilters"
                v-model="filters.sender"
              />
            </th>
            <th v-if="columns.image.enabled">
              <div>{{ $t("notifications.image") }}</div>
            </th>
            <th v-if="columns.status.enabled">
              <div class="inline-block">{{ $t("notifications.status") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.status.sort }"
                @click="sort('status')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <select
                v-if="hasPrivilege('notifications_view_notifications')"
                @change="applyFilters"
                v-model="filters.status"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none text-center"
              >
                <option
                  v-for="ft in [
                    { id: '', name: $t('control.all') },
                    { id: 'new', name: $t('notifications.new') },
                    { id: 'archived', name: $t('notifications.archived') },
                  ]"
                  v-bind:key="ft.id"
                  :value="ft.id"
                  >{{ ft.name }}</option
                >
              </select>
            </th>
            <th v-if="columns.created_at.enabled">
              <div class="inline-block">{{ $t("control.created_at") }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline-block transform transition-all cursor-pointer"
                :class="{ 'rotate-180': columns.created_at.sort }"
                @click="sort('created_at')"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <input
                v-if="hasPrivilege('notifications_view_notifications')"
                type="text"
                class="bg-white rounded-full w-3/4 shadow-lg p-1 m-1 focus:outline-none text-center"
                placeholder="Ex: 2021-01-01 01:01"
                @input="applyFilters"
                v-model="filters.created_at"
              />
            </th>
          </thead>
          <tbody class="overflow-y-scroll h-60">
            <tr
              v-for="(notification, index) in dataList"
              v-bind:key="notification.id"
              class="text-xs md:text-base"
              :class="{
                'bg-gray-200': index % 2 == 0,
                'bg-blue-300 hover:bg-blue-300': notification.selected,
                'opacity-50': notification.status == 'archived',
              }"
              @contextmenu.prevent="openContextMenu($event, notification)"
            >
              <td class="font-bold">
                {{ index + 1 + (meta.current_page - 1) * meta.per_page }}
              </td>
              <td>
                <table-check-box
                  class="w-5 h-5 my-3 mx-4 cursor-pointer"
                  :value="notification.selected"
                  @click.prevent="
                    selectData(notification);
                    showDetails = false;
                  "
                />
              </td>
              <td v-if="columns.title.enabled">
                {{ notification.title }}
              </td>
              <td
                v-if="columns.body.enabled"
                style="white-space:pre-wrap; word-wrap:break-word; direction:rtl"
              >
                {{ notification.body }}
              </td>
              <td v-if="columns.type.enabled">
                <div
                  class="rounded-full px-2 py-1"
                  :class="{
                    'bg-gray-600 text-gray-200 ':
                      notification.type == 'account_expired',
                    'bg-yellow-600 text-yellow-200 ':
                      notification.type == 'account_expiring',
                    'bg-green-600 text-green-200 ':
                      notification.type == 'account_renew',
                    ' bg-blue-600 text-blue-200 ':
                      notification.type == 'account' ||
                      notification.type == 'profile' ||
                      notification.type == 'nas',
                    ' bg-indigo-700 text-indigo-200 ':
                      notification.type == 'ssr',
                    ' bg-black text-white ': notification.type == 'global',
                    'bg-yellow-400 text-black ': notification.type.includes(
                      'support'
                    ),
                  }"
                >
                  {{ $t(`notifications.type.${notification.type}`) }}
                </div>
              </td>
              <td v-if="columns.receiver.enabled">
                {{ notification.receiver }}
              </td>
              <td v-if="columns.sender.enabled">
                {{ notification.sender }}
              </td>
              <td v-if="columns.image.enabled">
                <div>todo</div>
              </td>
              <td v-if="columns.status.enabled">
                <div
                  class="rounded-full mx-2"
                  :class="{
                    'bg-green-500 text-white': notification.status == 'new',
                    'bg-gray-500 text-white': notification.status == 'archived',
                  }"
                >
                  {{ $t(`notifications.${notification.status}`) }}
                </div>
              </td>
              <td v-if="columns.created_at.enabled">
                {{ formatDateHours(notification.created_at) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading" class="w-full flex justify-center">
        <img
          class="inline-block h-12 w-12"
          src="@/assets/loading.gif"
          alt="Loading"
        />
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length == 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <!-- <div
          @click="
            selectedData = selection_array[0];
            showDetails = true;
          "
          class="ml-2 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-yellow-300 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.view") }}
          </div>
        </div>
        <div
          v-if="hasPrivilege('notifications_edit_manual_notifications')"
          @click="editData(selection_array[0])"
          class="mx-3 cursor-pointer group relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block rounded-lg bg-blue-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.edit") }}
          </div>
        </div> -->
        <div
          v-if="
            hasPrivilege('notifications_edit_manual_notifications') ||
              hasPrivilege('notifications_push_notifications')
          "
          @click="
            selectedData = selection_array[0];
            showArchive = true;
          "
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.archive") }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-start"
        v-if="selection_array.length > 1"
        :class="{
          disabled: !this.$store.getters.settings.license.active,
        }"
      >
        <div
          v-if="
            hasPrivilege('notifications_edit_manual_notifications') ||
              hasPrivilege('notifications_push_notifications')
          "
          @click="showArchiveAll = true"
          class="group cursor-pointer relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block bg-red-500 text-white rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <div
            class="scale-0 transform transition-all ease-linear group-hover:scale-100 absolute"
          >
            {{ $t("actions.archive") }}
          </div>
        </div>
      </div>
      <pagination-row
        class="mt-2"
        @triggerupdate="fetchDataPortion"
        :meta="meta"
        :row="filters.rows"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DeleteConfirm from "../components/modals/DeleteConfirm.vue";
import PaginationRow from "../components/PaginationRow.vue";
import ColumnsSelect from "../components/modals/ColumnsSelect.vue";
import TableCheckBox from "../components/formElements/TableCheckBox.vue";
import WarnningConfirm from "../components/modals/WarnningConfirm.vue";
import { mapGetters } from "vuex";
import { hasPrivilege } from "../global_constants.js";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import PushNotification from "../components/modals/PushNotification.vue";
export default {
  components: {
    DeleteConfirm,
    PaginationRow,
    ColumnsSelect,
    TableCheckBox,
    WarnningConfirm,
    LoadingOverlay,
    PushNotification,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  name: "Notifications",
  data() {
    return {
      dataList: null,
      meta: { last_page: 0 },
      selectedData: null,
      showArchive: false,
      showArchiveAll: false,
      loading: true,
      loading_overlay: false,
      showFilters: false,
      showPushNotification: false,
      fullList: false,
      filters: {
        rows: 50,
        page: "",
        save: false,
        title: "",
        body: "",
        type: "",
        receiver: "",
        sender: "",
        image_url: "",
        status: "",
        created_at: "",
        archived: "",
      },
      checkedBox: true,
      allSelected: false,
      showColumnsSelect: false,
      columns: {
        title: {
          name: this.$t("notifications.title"),
          enabled: true,
          sort: false,
        },
        body: {
          name: this.$t("notifications.body"),
          enabled: false,
          sort: false,
        },
        type: {
          name: this.$t("notifications.type.type"),
          enabled: true,
          sort: false,
        },
        receiver: {
          name: this.$t("notifications.receiver"),
          enabled: false,
          sort: false,
        },
        sender: {
          name: this.$t("notifications.sender"),
          enabled: false,
          sort: false,
        },
        image: {
          name: this.$t("notifications.image"),
          enabled: false,
          sort: false,
        },
        status: {
          name: this.$t("notifications.status"),
          enabled: true,
          sort: false,
        },
        created_at: {
          name: this.$t("notifications.created_at"),
          enabled: true,
          sort: false,
        },
      },
      autoUpdate: false,
      selection_array: [],
      sort_col: null,
      sort_dir: null,
      debounce: null,
    };
  },
  async created() {
    this.prepareFilters();
    this.prepareColumns();
    this.applyFilters();
  },
  methods: {
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    prepareFilters() {
      var filters = localStorage.getItem("notifications_filters");
      if (filters != null) {
        filters = JSON.parse(filters);
        if (filters.save) this.filters = filters;
        else this.filters.rows = filters.rows;
      }
    },
    prepareColumns() {
      var cols = localStorage.getItem("notifications_columns");
      if (cols != null) {
        cols = JSON.parse(cols);
        this.columns = {
          title: {
            name: this.$t("notifications.title"),
            enabled: cols[0].enabled,
            sort: false,
          },
          body: {
            name: this.$t("notifications.body"),
            enabled: cols[1].enabled,
            sort: false,
          },
          type: {
            name: this.$t("notifications.type.type"),
            enabled: cols[2].enabled,
            sort: false,
          },
          receiver: {
            name: this.$t("notifications.receiver"),
            enabled: cols[3],
            sort: false,
          },
          sender: {
            name: this.$t("notifications.sender"),
            enabled: cols[4].enabled,
            sort: false,
          },
          image: {
            name: this.$t("notifications.image"),
            enabled: cols[5].enabled,
            sort: false,
          },
          status: {
            name: this.$t("notifications.status"),
            enabled: cols[6].enabled,
            sort: false,
          },
          created_at: {
            name: this.$t("notifications.created_at"),
            enabled: cols[7].enabled,
            sort: false,
          },
        };
      }
    },
    sort(param) {
      this.sort_col = param;
      if (!this.columns[param].sort) {
        this.sort_dir = "ASC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = true;
      } else {
        this.sort_dir = "DESC";
        Object.entries(this.columns).forEach(([key, val]) => {
          val.sort = false;
        });
        this.columns[param].sort = false;
      }
      this.applyFilters();
    },
    async applyFilters() {
      this.loading = true;
      var requestString = "notifications?filters=1";
      if (this.filters.page != "")
        requestString += `&page=${this.filters.page}`;
      if (this.filters.rows != "")
        requestString += `&rows=${this.filters.rows}`;
      if (this.filters.tile != "")
        requestString += `&title=${this.filters.title}`;
      if (this.filters.body != "")
        requestString += `&body=${this.filters.body}`;
      if (this.filters.type != "")
        requestString += `&type=${this.filters.type}`;
      if (this.filters.receiver != "")
        requestString += `&receiver=${this.filters.receiver}`;
      if (this.filters.sender != "")
        requestString += `&sender=${this.filters.sender}`;
      if (this.filters.status != "")
        requestString += `&status=${this.filters.status}`;
      if (this.filters.created_at != "")
        requestString += `&created_at=${this.filters.created_at}`;
      if (this.filters.archived) requestString += `&archived=1`;
      if (this.sort_col)
        requestString += `&order_by=${this.sort_col}&order_dir=${this.sort_dir}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const response = await axios.get(requestString);
        this.dataList = Object.values(response.data.data);
        if (this.filters.save)
          localStorage.setItem(
            "notifications_filters",
            JSON.stringify(this.filters)
          );
        if (this.dataList.length < 1 && this.filters.page != 1) {
          this.filters.page = 1;
          this.applyFilters();
        }
        if (this.sort_col) {
          if (this.sort_dir == "ASC") {
            this.dataList.sort((a, b) =>
              a[this.sort_col] > b[this.sort_col]
                ? 1
                : b[this.sort_col] > a[this.sort_col]
                ? -1
                : 0
            );
          } else {
            this.dataList.sort((a, b) =>
              a[this.sort_col] < b[this.sort_col]
                ? 1
                : b[this.sort_col] < a[this.sort_col]
                ? -1
                : 0
            );
          }
        }
        clearTimeout(this.debounce);
        this.meta = response.data.meta;
        this.loading = false;
      }, 1000);
    },
    async fetchDataPortion(rows, currentPage) {
      this.filters.rows = rows;
      this.filters.page = currentPage;
      this.applyFilters();
    },
    async editData(notification) {
      this.$router.push(`/notification/${notification.id}/edit`);
      this.$store.commit("changeKey");
    },
    async archiveData() {
      this.showArchive = false;
      this.loading_overlay = true;
      const response = await axios.delete(
        `notifications/${this.selectedData.id}`
      );
      if (response.status == 200) {
        this.applyFilters();
        this.$router.push({ query: { success: "success" } });
      }
      this.loading_overlay = false;
    },
    async groupArchive() {
      this.showArchiveAll = false;
      this.loading_overlay = true;
      const response = await axios.post(`notifications/group/archive`, {
        notifications: this.selection_array,
      });
      if (response.status == 200) {
        this.$router.push({ query: { success: "success" } });
        this.applyFilters();
      }
      this.loading_overlay = false;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatDateHours(date) {
      //todo fix UTC BUG
      if (date == "never" || date == "" || date == null) return "N/A";
      return moment(date)
        .add(3, "hours")
        .format("DD-MM-YYYY hh:mm");
    },
    checkAll() {
      if (this.allSelected) {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = false;
        });
        this.allSelected = false;
      } else {
        this.selection_array = [];
        this.dataList.forEach((element) => {
          element.selected = true;
          this.selection_array.push(element);
        });
        this.allSelected = true;
      }
    },
    selectData(data) {
      if (data.selected == false) {
        data.selected = true;
        this.selection_array.push(data);
      } else {
        data.selected = false;
        this.selection_array.splice(this.selection_array.indexOf(data), 1);
      }
    },
    updateColumns(updated) {
      this.columns = updated;
      this.showColumnsSelect = false;
    },
    async toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      while (this.autoUpdate) {
        this.applyFilters();
        await new Promise((r) => setTimeout(r, 30000));
      }
    },
    toggleSaveFilters() {
      this.filters.save = !this.filters.save;
      localStorage.setItem(
        "notifications_filters",
        JSON.stringify(this.filters)
      );
    },
  },
};
</script>
