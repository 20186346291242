<template>
  <div class="main-content flex-1 relative bg-gray-100">
    <loading-overlay :visible="loading" />
    <div
      v-if="this.$store.getters.settings.license.active"
      class="flex justify-center w-full"
    >
      <div
        class="bg-gray-300 rounded-xl w-full m-6 flex flex-col pb-12 px-20 justify-center items-center"
      >
        <div class="flex flex-col justify-center items-center">
          <div class="grid grid-cols-2 gap-6 mt-6">
            <div>
              <div class="font-bold">{{ $t("batches.name") }}</div>
              <input
                v-model="card.name"
                @input="validate"
                :class="{ 'shadow-error': errors.name }"
                type="text"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div>
              <div class="font-bold">{{ $t("batches.prefix") }}</div>
              <input
                v-model="card.prefix"
                type="text"
                placeholder="Ex: 200M_"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              />
            </div>
            <div class="col-span-2">
              <div class="font-bold">{{ $t("batches.profile") }}</div>
              <select
                v-model="card.profile_id"
                @change="
                  validate();
                  fix_date();
                  update_price();
                "
                :class="{ 'shadow-error': errors.profile }"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option
                  v-for="profile in profiles"
                  v-bind:key="profile.id"
                  :value="profile.id"
                  >{{ profile.name }}</option
                >
              </select>
            </div>
            <!-- TEMPORARY DISABLE -->
            <!-- <div class="col-span-2">
              <label class="font-bold">{{
                $t("accounts.has_fixed_expiration")
              }}</label>
              <Datepicker
                class="w-full"
                v-model="card.fixed_expiration"
                :format="date_format"
                autoApply
              ></Datepicker>
            </div> -->
            <div class="col-span-2">
              <div class="font-bold">{{ $t("batches.chars") }}</div>
              <select
                @change="validate()"
                v-model="card.chars"
                class="bg-white rounded-full w-full shadow-lg p-1 pl-3 focus:outline-none"
              >
                <option value="alpha">{{ $t("batches.alpha") }}</option>
                <option value="numeric">{{ $t("batches.numeric") }}</option>
              </select>
            </div>
            <div>
              <div class="font-bold">
                {{ $t("batches.username_length") }}
              </div>
              <div
                class="bg-white rounded-full inline-flex shadow-lg w-full"
                :class="{ 'shadow-error': errors.username_length }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      card.username_length = card.username_length + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      card.username_length =
                        card.username_length > 3
                          ? card.username_length - 1
                          : (card.username_length = 3);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  @input="validate()"
                  v-model="card.username_length"
                  class=" rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div>
              <div class="font-bold">
                {{ $t("batches.password_length") }}
              </div>
              <div
                class="bg-white rounded-full inline-flex w-full shadow-lg"
                :class="{ 'shadow-error': errors.password_length }"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      card.password_length = card.password_length + 1;
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      card.password_length =
                        card.password_length > 0
                          ? card.password_length - 1
                          : (card.password_length = 3);
                      validate();
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="card.password_length"
                  @input="validate()"
                  class=" rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div>
              <div class="font-bold">{{ $t("batches.quantity") }}</div>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button @click.prevent="card.quantity = card.quantity + 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      card.quantity =
                        card.quantity > 0
                          ? card.quantity - 1
                          : (card.quantity = 0)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="card.quantity"
                  class=" rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div>
              <div class="font-bold">{{ $t("batches.price") }}</div>
              <div class="bg-white rounded-full w-full inline-flex shadow-lg">
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button @click.prevent="card.price = card.price + 1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      card.price =
                        card.price > 0 ? card.price - 1 : (card.price = 0)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="card.price"
                  class=" rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div v-if="user.data.type != 'reseller'">
              <div class="font-bold">{{ $t("batches.sessions") }}</div>
              <div
                :class="{ disabled: card.is_mac }"
                class="bg-white rounded-full w-full inline-flex shadow-lg"
              >
                <div
                  class="bg-gray-800 flex items-center justify-center text-indigo-400 rounded-full px-1 m-0"
                >
                  <button
                    @click.prevent="
                      card.allowed_sessions = card.allowed_sessions + 1
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 15L12 9L18 15"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>

                  <button
                    @click.prevent="
                      card.allowed_sessions =
                        card.allowed_sessions > 0
                          ? card.allowed_sessions - 1
                          : (card.allowed_sessions = 1)
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9L12 15L6 9"
                        stroke="#84C2EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  v-model="card.allowed_sessions"
                  class=" rounded-r-full text-center p-1 inline-block w-full"
                />
              </div>
            </div>
            <div>
              <div class="font-bold">{{ $t("messages.batches.is_mac") }}</div>
              <toggle-button
                class="shadow-lg"
                :value="card.is_mac"
                :enabled_name="$t('batches.is_mac')"
                :disabled_name="$t('batches.is_mac')"
                @toggle="
                  card.is_mac = !card.is_mac;
                  validate();
                "
              />
            </div>
            <div class="col-span-2">
              <div
                class="text-gray-300"
                :class="{ 'text-red-600': errors.name }"
              >
                *{{ errors.name }}
              </div>
              <div
                class="text-gray-300"
                :class="{ 'text-red-600': errors.profile }"
              >
                *{{ errors.profile }}
              </div>
              <div
                class="text-gray-300"
                :class="{ 'text-red-600': errors.username_length }"
              >
                *{{ errors.username_length }}
              </div>
              <div
                class="text-gray-300"
                :class="{ 'text-red-600': errors.password_length }"
              >
                *{{ errors.password_length }}
              </div>
              <div
                class="text-gray-300"
                :class="{ 'text-red-600': errors.password_length }"
              >
                *{{ errors.chars }}
              </div>
            </div>
            <div
              v-if="this.user.data.type == 'reseller'"
              class="col-span-2 flex flex-col font-bold text-md"
            >
              <div>
                {{
                  $t("control.cost", {
                    cost: price * card.quantity + " " + settings.currency,
                  })
                }}
              </div>
              <div
                :class="{
                  'text-red-600': user.data.money - price * card.quantity < 0,
                }"
              >
                {{
                  $t("control.money_left", {
                    original: user.data.money,
                    left:
                      user.data.money -
                      price * card.quantity +
                      " " +
                      settings.currency,
                  })
                }}
              </div>
            </div>
            <div class="col-span-2 flex justify-center">
              <button
                :disabled="
                  !validated ||
                    (this.user.data.money - this.price * this.card.quantity <
                      0 &&
                      this.user.data.type == 'reseller')
                "
                @click.prevent="store"
                class="w-full bg-green-500 text-white rounded-full py-1 px-3"
                :class="{
                  ' cursor-not-allowed opacity-50':
                    !validated ||
                    (this.user.data.money - this.price * this.card.quantity <
                      0 &&
                      this.user.data.type == 'reseller'),
                }"
              >
                {{ $t("batches.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <expired-window v-else />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import NumericalSpinner from "../components/formElements/NumericalSpinner.vue";
import ToggleButton from "../components/formElements/ToggleButton.vue";
import Dropdown from "../components/formElements/Dropdown.vue";
import LoadingOverlay from "../components/modals/LoadingOverlay.vue";
import Datepicker from "vue3-date-time-picker";
import moment from "moment";
import ExpiredWindow from "../components/ExpiredWindow.vue";
export default {
  components: {
    NumericalSpinner,
    ToggleButton,
    Dropdown,
    LoadingOverlay,
    Datepicker,
    ExpiredWindow,
  },
  name: "AddCard",
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      error: "",
      message: "",
      card: {
        name: "",
        quantity: 1,
        price: 0,
        prefix: "",
        chars: "numeric",
        profile_id: null,
        username_length: 6,
        password_length: 3,
        allowed_sessions: 1,
        fixed_expiration: null,
        is_mac: false,
      },
      errors: {
        name: null,
        profile: null,
        username_length: null,
        password_length: null,
        chars: null,
      },
      loading: false,
      profiles: null,
      validated: false,
      date_format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minute = date.getMinutes();

        return `${day}-${month}-${year} ${hour}:${minute}`;
      },
      price: 0,
    };
  },
  async created() {
    this.loading = true;
    await this.fetchProfiles();
    this.loading = false;
  },
  methods: {
    async store() {
      this.loading = true;
      const response = await axios.post("/cards", this.card);
      if (response.status == 200) {
        this.loading = false;
        this.$router.push({ path: "/cards", query: { success: "success" } });
      } else if (response.status == 210) {
        this.loading = false;
        if (response.data.error == "cards_name_already_exists") {
          this.errors.name = this.$t("errors.batches.name_taken");
          this.validated = false;
        }
        if (response.data.error == "chars") {
          this.errors.chars = this.$t("errors.batches.chars");
          this.validated = false;
        }
        if (response.data.error == "not_enough_chards") {
          this.errors.chars = this.$t("errors.batches.not_enough_chars");
          this.validated = false;
        }
      }
    },
    async fetchProfiles() {
      const response = await axios.get(
        "/profiles?itr=1&type=prepaid&rows=1000000"
      );
      if (response.status == 200) this.profiles = response.data.data;
    },
    changeProfile(profile_id) {
      this.account.profile_id = profile_id;
    },
    fix_date() {
      let profile = this.profiles.filter((profile) => {
        return profile.id === this.card.profile_id;
      });
      profile = profile[0];
      this.card.fixed_expiration = moment().add(
        profile.expiration_value,
        profile.expiration_unit
      );
      this.card.fixed_expiration.hours(0);
      this.card.fixed_expiration.minutes(0);
    },
    update_price() {
      let profile = this.profiles.filter((profile) => {
        return profile.id == this.card.profile_id;
      });
      profile = profile[0];
      this.price = profile.price;
      this.card.price = profile.price;
    },
    fetchCard(id) {
      if (this.card.expires_at) {
        this.card.fixed_expiration = moment(this.account.expires_at);
      }
    },
    validate() {
      this.validated = true;
      this.errors.name = null;
      this.errors.profile = null;
      this.errors.username_length = null;
      this.errors.password_length = null;
      if (!this.card.profile_id) {
        this.validated = false;
        this.errors.profile = this.$t("errors.accounts.select_profile");
      }
      if (this.card.name == "") {
        this.validated = false;
        this.errors.name = this.$t("errors.batches.name_empty");
      }
      if (this.card.chars == "numeric" && this.card.username_length < 6) {
        this.validated = false;
        this.errors.username_length = this.$t(
          "errors.batches.username_length_6"
        );
      }
      if (this.card.password_length < 3) {
        this.validated = false;
        this.errors.password_length = this.$t(
          "errors.batches.password_length_3"
        );
      }
      if (
        this.user.data.money - this.price * this.card.quantity < 0 &&
        this.user.data.type == "reseller"
      ) {
        this.validated = false;
      }
      if (this.card.is_mac) {
        this.card.allowed_sessions = 1;
      }
    },
  },
};
</script>
