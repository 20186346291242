<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
      v-if="this.$store.getters.settings.license.active"
    >
      <div
        @click.prevent="hide"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        v-if="!loading"
        class="
          inline-block
          align-bottom
          md:align-middle
          bg-gray-300
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          sm:my-8
          max-w-5xl
          w-full
        "
      >
        <div class="flex flex-col justify-center items-center">
          <div class="bg-ssr-side_bar-background flex w-full justify-between">
            <div class="md:text-2xl text-white font-bold py-2 pl-6">
              {{ $t("bills.pay") }}
            </div>
            <div
              @click.prevent="hide"
              class="
                rounded-full
                cursor-pointer
                bg-ssr-side_bar-button_hover
                text-white text-xs
                my-2
                mr-6
                w-28
                flex
                justify-center
                items-center
                font-bold
              "
            >
              {{ $t("accounts.cancel") }}
            </div>
          </div>
          <div
            class="
              flex flex-col
              w-full
              md:w-1/3
              items-center
              justify-center
              bg-gray-300
            "
          >
            <label class="font-bold mt-6">{{ $t("accounts.username") }}</label>
            <input
              v-model="selectedBill.account.username"
              type="text"
              class="
                bg-gray-300
                w-full
                text-2xl
                border-b border-black
                text-center
                p-1
                pl-3
                focus:outline-none
              "
              :disabled="selectedBill.account.id"
            />

            <label class="font-bold mt-2">{{ $t("bills.slug") }}</label>
            <input
              v-model="selectedBill.slug"
              type="text"
              class="
                bg-gray-300
                w-full
                text-2xl
                border-b border-black
                text-center
                p-1
                pl-3
                focus:outline-none
              "
              :disabled="selectedBill.account.id"
            />

            <div class="flex justify-around w-full mt-6">
              <p class="flex-1 text-center">
                {{ $t("transactions.required_amount") + ":" }}
              </p>
              <div class="rounded-full text-center bg-white flex-1 opacity-50">
                {{ selectedBill.price - selectedBill.paid }}
              </div>
            </div>
            <div class="flex justify-around w-full mt-2">
              <label for="paid" class="flex-1 text-center">{{
                $t("transactions.paid_amount") + ":"
              }}</label>
              <input
                v-model="paid"
                name="paid"
                id="paid"
                type="number"
                class="rounded-full text-center flex-1"
              />
            </div>
            <label for="notes">{{ $t("transactions.notes") }}</label>
            <textarea
              name="notes"
              id="notes"
              cols="30"
              rows="3"
              class="w-full rounded-3xl shadow-lg p-2 focus:outline-none"
              v-model="notes"
            ></textarea>
            <button
              v-if="!confirmation"
              @click.prevent="confirmation = true"
              class="bg-green-500 text-white rounded-full w-full py-1 px-3 my-6"
              :class="{ 'bg-opacity-50 cursor-default': !validated }"
              :disabled="!validated"
            >
              {{ $t("transactions.pay") }}
            </button>
          </div>
          <div
            v-if="confirmation"
            class="
              grid grid-cols-2
              my-2
              gap-x-2
              w-full
              md:w-1/3
              items-center
              justify-center
              bg-gray-300
              text-white
            "
          >
            <div class="col-span-2 flex justify-center text-black text-center">
              {{ $t("bills.pay_confirm") }}
            </div>
            <button
              @click.prevent="confirmation = false"
              class="flex justify-center bg-red-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.no") }}
            </button>
            <button
              @click.prevent="store"
              class="flex justify-center bg-green-500 rounded-full py-1 mt-2"
            >
              {{ $t("control.yes") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="
          inline-block
          align-bottom
          bg-gray-300
          rounded-3xl
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          my-24
          sm:my-8 sm:align-middle
        "
        v-if="loading"
      >
        <div class="flex flex-col items-center">
          <img
            class="inline-block h-48 w-48"
            src="@/assets/loading.gif"
            alt="Loading"
          />
        </div>
      </div>
    </div>
    <ExpiredWindow v-else />
  </div>
</template>

<script>
import moment, { months } from "moment";
import { hasPrivilege } from "../../global_constants.js";
import { mapGetters } from "vuex";
import axios from "axios";
import NumericalSpinner from "../formElements/NumericalSpinner.vue";
import Datepicker from "vue3-date-time-picker";
import LoadingOverlay from "../modals/LoadingOverlay.vue";
import ExpiredWindow from "../ExpiredWindow.vue";
import ToggleButton from "../formElements/ToggleButton.vue";
export default {
  components: {
    NumericalSpinner,
    Datepicker,
    ExpiredWindow,
    ToggleButton,
  },
  name: "BillPay",
  props: ["visible", "selectedBill"],
  computed: {
    ...mapGetters(["user", "settings"]),
  },
  data() {
    return {
      confirmation: false,
      loading: true,
      validated: true,
      paid: "0",
      notes: "",
    };
  },
  async created() {
    this.validate();
    this.loading = false;
  },
  methods: {
    hide() {
      this.$emit("hide-details");
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm a");
    },
    hasPrivilege(privilege) {
      return hasPrivilege(this.user, privilege);
    },
    async store() {
      this.loading = true;
      const response = await axios.post(`/bills/${this.selectedBill.id}/pay`, {
        notes: this.notes,
        paid_amount: this.paid,
      });
      if (response.status == 200) {
        const userResponse = await axios.get("user");
        this.$store.dispatch("user", userResponse.data);
        this.$router.push({
          path: "/bills",
          query: { success: "success" },
        });
        this.$emit("updated");
      }
      this.loading = false;
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
