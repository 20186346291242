<template>
  <div
    class="flex justify-center items-center w-full h-full text-center text-5xl font-bold"
  >
    ACCESS DENIED
  </div>
</template>
<script>
export default {
  name: "ExpiredWindow",
};
</script>
